import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import { showWorkshop } from "../config"
import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyTwoTextInput, PootsyCheckboxInput, PootsySelectInput } from "./FunctionalInputs"

import {
	addScheduleTimeRange,
	processAppliedScheduleChange,
	processRemoveScheduleTimeRange,
	processAddBookingTimeRange,
	processEditBookingTimeRange,
	processRemoveBookingTimerange,
	editScheduleTimeRange,
	newTimerangeObject,
	schedulePeriodApplies,
	workshopHourModalStateChange,
	handleAddWorkshopHourToWorkerSchedule,
} from "../actions"

import { OtherActivityModal } from "./OtherActivityModal"
import { WorkshopHourModal } from "./WorkshopHourModal"
import ModalLayout from "./ModalLayout"
import WeekTimeranges from "./WeekTimeranges"
import BookingFromWorkerScheduleModal from "./BookingFromWorkerScheduleModal"
import InputsChangeHandler from "./InputsChangeHandler"

const days = [
	{ tKey: "Monday", number: 0 },
	{ tKey: "Tuesday", number: 1 },
	{ tKey: "Wednesday", number: 2 },
	{ tKey: "Thursday", number: 3 },
	{ tKey: "Friday", number: 4 },
	{ tKey: "Saturday", number: 5 },
]

export class WorkerSchedule extends Component {
	convertDecimalToHours = decimal => {
		if (decimal.toString().includes("u") || decimal.toString().includes("h")) {
			return decimal
		}
		let { t } = this.context
		let minutes = decimal * 60
		let roundedMinutes = Math.round(minutes)
		let hours = Math.floor(roundedMinutes / 60)
		let remainder = roundedMinutes % 60
		return hours + t("hours_shorthand") + (remainder < 10 ? "0" + remainder : remainder)
	}
	convertHoursToDecimal = string => {
		let { t } = this.context
		let parsed = string.split(t("hours_shorthand"))
		let hours = parsed[0] || "0"
		let minutes = parsed[1] || "0"
		return parseInt(hours) + minutes / 60
	}
	UNSAFE_componentWillReceiveProps = params => {
		let schedulePeriod = params.currentContract.schedulePeriods[params.selectedPeriod]
		let theoreticalHours = params.currentContract.theoreticalHours.filter(e =>
			schedulePeriod.managedTheoHours.includes(e.id)
		)
		let convertedHours = this.timerangesCountDisplay(theoreticalHours)
		if (
			this.contractRegimeFixed() &&
			(params.currentEditedKeys.length > 0 || params.currentContract.id === "new-contract") &&
			convertedHours !== schedulePeriod.maxWorkHours &&
			this.convertHoursToDecimal(convertedHours) !== schedulePeriod.maxWorkHours
		) {
			this.props.dispatch(
				processAppliedScheduleChange(schedulePeriod.id, {
					maxWorkHours: this.timerangesCountDisplay(theoreticalHours),
				})
			)
		} else if (!this.contractRegimeFixed()) {
			if (
				this.state.maxWorkHours !== this.convertDecimalToHours(schedulePeriod.maxWorkHours)
			) {
				this.setState({
					maxWorkHours: this.convertDecimalToHours(schedulePeriod.maxWorkHours),
				})
			}
		}
	}
	openNewTimeRangeModal = e => {
		let { type, day } = e.target.dataset
		let { schedulePeriod } = this.props
		let isoday = parseInt(day, 10) + 1 // isoweekday has monday at 1 and sunday at 7.
		// schedulePeriod.startDate should ALWAYS be monday
		let nextDayOccurrence = moment(schedulePeriod.startDate).isoWeekday(isoday)
		this.setState({ newTimeRangeType: type, newTimeRangeStartDate: nextDayOccurrence })
		this.toggleNewTimeRangeModal()
	}
	openNewOtherActivityModal = e => {
		let { day } = e.target.dataset
		let { schedulePeriod } = this.props
		let isoday = Number(day) + 1 // isoweekday has monday at 1 and sunday at 7.
		let nextDayOccurrence = moment(schedulePeriod.startDate).isoWeekday(isoday)
		if (schedulePeriod.startDate.isoWeekday() > isoday) {
			nextDayOccurrence.add(1, "week")
		}
		this.setState({
			otherActivityInit: {
				startDate: nextDayOccurrence,
				dayPeriod: schedulePeriod.dayPeriod,
			},
		})
		this.toggleNewOtherActivityModal()
	}
	contractRegimeFixed = () => {
		return ["fixed", "fixed_flex_week", "fixed_flex"].includes(
			this.props.currentContract.contractVariability
		)
	}
	openNewWorkshopHourModal = e => {
		let { day } = e.target.dataset
		let { dispatch, schedulePeriod } = this.props
		let isoday = Number(day) + 1 // isoweekday has monday at 1 and sunday at 7.
		let nextDayOccurrence = moment(schedulePeriod.startDate).isoWeekday(isoday)
		if (schedulePeriod.startDate.isoWeekday() > isoday) {
			nextDayOccurrence.add(1, "week")
		}
		dispatch(
			workshopHourModalStateChange({
				startDate: nextDayOccurrence,
				dayPeriod: schedulePeriod.dayPeriod,
			})
		)
		this.toggleNewWorkshopHourModal()
	}
	openNewBookingModal = e => {
		let { day } = e.target.dataset
		let { contractStartDate } = this.props
		let isoday = parseInt(day, 10) + 1 // isoweekday has monday at 1 and sunday at 7.
		let nextDayOccurrence = moment(contractStartDate).isoWeekday(isoday)
		if (contractStartDate.isoWeekday() > isoday) {
			nextDayOccurrence.add(1, "week")
		}
		this.setState({ newBookingInitialDate: nextDayOccurrence })
		this.toggleNewBookingModal()
	}
	openBookingEditionModal = id => {
		let booking = this.props.unsavedBookings.find(entry => entry.id === id)
		this.toggleNewBookingModal()
		this.setState({ editedBooking: booking })
	}
	toggleNewTimeRangeModal = () => {
		this.setState(state => ({ showNewTimeRangeModal: !state.showNewTimeRangeModal }))
	}
	closeNewTimeRangeModal = () => {
		this.setState({
			newTimeRangeStartDate: moment(),
			newTimeRangeStart: moment().set({ minutes: 0, second: 0, milliseconds: 0 }),
			newTimeRangeEnd: moment()
				.set({ minutes: 0, second: 0, milliseconds: 0 })
				.add(3, "hours"),
			newTimeRangeType: "",
			newTimeRangeReplaces: "",
			showNewTimeRangeModal: false,
		})
	}
	toggleNewOtherActivityModal = () => {
		this.setState(state => ({ showNewOtherActivityModal: !state.showNewOtherActivityModal }))
	}
	toggleNewWorkshopHourModal = () => {
		this.setState(state => ({ showNewWorkshopHourModal: !state.showNewWorkshopHourModal }))
	}
	toggleNewBookingModal = () => {
		this.setState(state => ({
			showNewBookingModal: !state.showNewBookingModal,
			editedBooking: {},
		}))
	}
	handleAddTimeRange = () => {
		let { dispatch, schedulePeriod } = this.props
		let {
			newTimeRangeStartDate,
			newTimeRangeStart,
			newTimeRangeEnd,
			newTimeRangeType,
			newTimeRangeReplaces,
		} = this.state
		let newTimeRange = newTimerangeObject({
			type: newTimeRangeType,
			startDate: newTimeRangeStartDate,
			start: newTimeRangeStart.set({
				year: 2000,
				month: 0,
				date: 1,
				seconds: 0,
				milliseconds: 0,
			}),
			end: newTimeRangeEnd.set({
				year: 2000,
				month: 0,
				date: 1,
				seconds: 0,
				milliseconds: 0,
			}),
			allowEdition: true,
			allowDeletion: true,
		})
		if (newTimeRangeReplaces) {
			dispatch(editScheduleTimeRange(newTimeRange, newTimeRangeReplaces, schedulePeriod.id))
		} else {
			dispatch(addScheduleTimeRange(newTimeRange, schedulePeriod.id))
		}
		this.closeNewTimeRangeModal()
	}
	handleAddOtherActivity = otherActivity => {
		this.props.dispatch(
			addScheduleTimeRange(
				newTimerangeObject({
					...otherActivity,
					type: "other-act",
					allowEdition: true,
					allowDeletion: true,
				})
			)
		)
	}
	handleAddWorkshopHour = () => {
		this.props.dispatch(handleAddWorkshopHourToWorkerSchedule())
		this.setState({ showNewWorkshopHourModal: false })
	}
	handleNewTimeRangeChange = changes => {
		this.setState(changes)
	}
	handleEditTimeRange = timerange => {
		if (timerange.type === "unsaved-booking") {
			this.openBookingEditionModal(timerange.id)
			return
		}
		this.setState({
			newTimeRangeStartDate: moment(timerange.startDate),
			newTimeRangeStart: moment(timerange.start),
			newTimeRangeEnd: moment(timerange.end),
			newTimeRangeType: timerange.type,
			newTimeRangeReplaces: timerange.id,
		})
		this.toggleNewTimeRangeModal()
	}
	handleRemoveTimeRange = timerange => {
		let { dispatch } = this.props
		if (timerange.type === "unsaved-booking") {
			dispatch(processRemoveBookingTimerange(timerange))
			return
		}
		dispatch(processRemoveScheduleTimeRange(timerange))
	}
	handleAppliedScheduleChange = changes => {
		let { t } = this.context
		let { currentContract, selectedPeriod } = this.props
		let { maxExtraWorkHours, maxWorkHours } = this.state
		if (changes.maxWorkHours) {
			changes.maxWorkHours =
				changes.maxWorkHours +
				t("hours_shorthand") +
				maxWorkHours.split(t("hours_shorthand"))[1]
		}
		if (changes.maxWorkMin) {
			changes.maxWorkHours =
				maxWorkHours.split(t("hours_shorthand"))[0] +
				t("hours_shorthand") +
				changes.maxWorkMin
		}
		if (changes.maxExtraWorkHours) {
			changes.maxExtraWorkHours =
				changes.maxExtraWorkHours +
				t("hours_shorthand") +
				maxExtraWorkHours.split(t("hours_shorthand"))[1]
		}
		if (changes.maxExtraWorkMin) {
			changes.maxExtraWorkHours =
				maxExtraWorkHours.split(t("hours_shorthand"))[0] +
				t("hours_shorthand") +
				changes.maxExtraWorkMin
		}
		if (changes.hasMaxHoursPerDay != null) {
			currentContract.schedulePeriods[selectedPeriod].hasMaxHoursPerDay =
				changes.hasMaxHoursPerDay
		}
		this.setState(changes)
		this.props.dispatch(processAppliedScheduleChange(this.props.schedulePeriod.id, changes))
	}
	handleDaysMaxHoursChange = e => {
		e.stopPropagation()
		let { t } = this.context
		let { daysMaxHours } = this.state
		let { name, value } = e.target
		let newDaysMaxHours
		if (name.split("_")[0] === "min") {
			newDaysMaxHours = daysMaxHours.map((e, i) =>
				i === parseInt(name.split("_")[1])
					? e.split(t("hours_shorthand"))[0] + t("hours_shorthand") + value
					: e
			)
		} else {
			newDaysMaxHours = daysMaxHours.map((e, i) =>
				i === parseInt(name)
					? value + t("hours_shorthand") + e.split(t("hours_shorthand"))[1]
					: e
			)
		}
		this.setState({ daysMaxHours: newDaysMaxHours })
		this.props.dispatch(
			processAppliedScheduleChange(this.props.schedulePeriod.id, {
				daysMaxHours: newDaysMaxHours,
			})
		)
	}
	handleAddBooking = booking => {
		this.props.dispatch(processAddBookingTimeRange(booking))
	}
	handleEditBooking = booking => {
		this.props.dispatch(processEditBookingTimeRange(booking))
		this.setState({ editedBooking: {} })
	}
	timerangesCountDisplay = timeranges => {
		let { t } = this.context
		let minutes = timeranges.reduce(
			(acc, entry) => (acc += Math.round(entry.end.diff(entry.start, "seconds") / 60)),
			0
		)
		let remainder = minutes % 60
		return (
			Math.floor(minutes / 60) +
			t("hours_shorthand") +
			(remainder < 10 ? "0" + remainder : remainder)
		)
	}
	state = {
		showNewTimeRangeModal: false,
		showNewOtherActivityModal: false,
		showNewWorkshopHourModal: false,
		showNewBookingModal: false,
		newTimeRangeStartDate: moment(),
		newTimeRangeStart: moment().set({ hour: 14, minutes: 0, second: 0, milliseconds: 0 }),
		newTimeRangeEnd: moment()
			.set({ hour: 14, minutes: 0, second: 0, milliseconds: 0 })
			.add(3, "hours"),
		newTimeRangeType: "",
		newTimeRangeReplaces: "",
		newBookingInitialDate: moment(),
		otherActivityInit: "",
		editedBooking: {},
		maxExtraWorkHours: this.convertDecimalToHours(this.props.schedulePeriod.maxExtraWorkHours),
		maxWorkHours: this.timerangesCountDisplay(this.props.theoreticalHours),
		daysMaxHours: this.props.schedulePeriod.daysMaxHours.map(day =>
			this.convertDecimalToHours(day)
		),
	}
	render() {
		let { t } = this.context
		let {
			currentContract,
			availabilities,
			theoreticalHours,
			otherActivities,
			workshopHours,
			unsavedBookings,
			contractStartDate,
			isTwoWeeksSchedule,
			recurrentBookings,
			currAffiliate,
			isVarVarContract,
			isVarContract,
			selectedPeriod,
		} = this.props
		let {
			showNewTimeRangeModal,
			showNewBookingModal,
			showNewOtherActivityModal,
			showNewWorkshopHourModal,
			newTimeRangeType,
			newTimeRangeStart,
			newTimeRangeEnd,
			newBookingInitialDate,
			editedBooking,
			otherActivityInit,
			newTimeRangeReplaces,
		} = this.state
		let newTimeRangeModalButtons = [
			<PootsyButton key="a" text={t("add")} onClick={this.handleAddTimeRange} />,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.closeNewTimeRangeModal}
			/>,
		]
		let schedulePeriod = currentContract.schedulePeriods[selectedPeriod]
		let allTimeRanges = availabilities
			.concat(theoreticalHours)
			.concat(unsavedBookings)
			.concat(otherActivities)
			.concat(workshopHours)
			.concat(recurrentBookings)
		return (
			<Fragment>
				{showNewBookingModal && (
					<BookingFromWorkerScheduleModal
						closeModal={this.toggleNewBookingModal}
						initialStartDate={newBookingInitialDate}
						contractStartDate={contractStartDate}
						schedulePeriod={schedulePeriod}
						addBookingToSchedule={this.handleAddBooking}
						editScheduleBooking={this.handleEditBooking}
						editedBooking={editedBooking}
						isTwoWeeksSchedule={isTwoWeeksSchedule}
					/>
				)}
				{showNewOtherActivityModal && (
					<OtherActivityModal
						init={otherActivityInit}
						t={t}
						closeModal={this.toggleNewOtherActivityModal}
						submit={this.handleAddOtherActivity}
						hideDayPeriodSelector={true}
						disableDay={day =>
							day.isoWeekday() !== otherActivityInit.startDate.isoWeekday() ||
							!schedulePeriodApplies({
								schedulePeriod,
								startDate: day,
								dayPeriod: otherActivityInit.dayPeriod,
							})
						}
					/>
				)}
				{showNewWorkshopHourModal && (
					<WorkshopHourModal
						closeModal={this.toggleNewWorkshopHourModal}
						submit={this.handleAddWorkshopHour}
						hideDayPeriodSelector={true}
						t={t}
						disableDay={day =>
							day.isoWeekday() !== otherActivityInit.startDate.isoWeekday() ||
							!schedulePeriodApplies({
								schedulePeriod,
								startDate: day,
								dayPeriod: otherActivityInit.dayPeriod,
							})
						}
					/>
				)}
				{showNewTimeRangeModal && (
					<InputsChangeHandler onChange={this.handleNewTimeRangeChange}>
						<ModalLayout
							title={
								newTimeRangeReplaces
									? t("edit_time_range_modal_title")
									: t("new_time_range_modal_title")
							}
							formDivClass="new-time-range-modal"
							closeModal={this.closeNewTimeRangeModal}
							buttons={newTimeRangeModalButtons}
						>
							{!newTimeRangeReplaces && (
								<PootsySelectInput
									name="newTimeRangeType"
									defaultText={t("timerange_type")}
									selected={newTimeRangeType}
									options={[
										{ label: t("availability"), value: "avail" },
										{ label: t("theoretical_hour"), value: "theo-hour" },
									]}
								/>
							)}
							<LabeledDateTime
								label={t("start_time")}
								showDatePicker={false}
								timePickerProps={{
									value: newTimeRangeStart,
									name: "newTimeRangeStart",
									onChange: this.handleNewTimeRangeChange,
								}}
							/>
							<LabeledDateTime
								label={t("end_time")}
								showDatePicker={false}
								timePickerProps={{
									value: newTimeRangeEnd,
									name: "newTimeRangeEnd",
									onChange: this.handleNewTimeRangeChange,
									filterHours: h => h >= newTimeRangeStart.hours(),
									filterMinutes: m =>
										newTimeRangeStart.hours() < newTimeRangeEnd.hours()
											? m >= 0 && m <= 59
											: m > newTimeRangeStart.minute(),
								}}
							/>
						</ModalLayout>
					</InputsChangeHandler>
				)}
				<div className="above-planning">
					<div className="schedule-period-data">
						<InputsChangeHandler onChange={this.handleAppliedScheduleChange}>
							{!isVarVarContract && (
								<Fragment>
									<PootsyTwoTextInput
										name="maxWorkHours"
										disabled={[
											"fixed",
											"fixed_flex_week",
											"fixed_flex",
										].includes(this.props.currentContract.contractVariability)}
										label={t("nb_work_hours_per_week")}
										separator={t("hours_shorthand")}
										type={"number"}
										secondName={"maxWorkMin"}
										customClass="double-working-hours-per-week-input"
										min="0"
										values={
											schedulePeriod.maxWorkHours
												.toString()
												.includes(t("hours_shorthand"))
												? schedulePeriod.maxWorkHours.split(
														t("hours_shorthand")
												  )
												: this.convertDecimalToHours(
														schedulePeriod.maxWorkHours
												  ).split(t("hours_shorthand"))
										}
									/>
									<PootsyTwoTextInput
										name="maxExtraWorkHours"
										label={t("max_extra_hours_per_week")}
										customClass="double-working-hours-per-week-input"
										secondName={"maxExtraWorkMin"}
										type={"number"}
										separator={t("hours_shorthand")}
										min="0"
										values={this.state.maxExtraWorkHours.split(
											t("hours_shorthand")
										)}
									/>
									<PootsyCheckboxInput
										name="hasMaxHoursPerDay"
										id={schedulePeriod.id}
										label={t("max_hours_for_day")}
										checked={schedulePeriod.hasMaxHoursPerDay}
									/>
								</Fragment>
							)}
						</InputsChangeHandler>
					</div>
					<div className="planning-legend">
						{[
							{
								type: "avail",
								label: t("availability"),
								currentTotalDuration: this.timerangesCountDisplay(availabilities),
							},
							{
								type: "theo-hour",
								label: t("theoretical_hour"),
								currentTotalDuration: this.timerangesCountDisplay(theoreticalHours),
							},
							{
								type: "booking",
								label: t("booking"),
								currentTotalDuration: this.timerangesCountDisplay(unsavedBookings),
							},
							{
								type: "other-act",
								label: t("other_activity"),
								currentTotalDuration: this.timerangesCountDisplay(otherActivities),
							},
							{
								type: "other-act workshop-hour",
								label: t("workshop_hour"),
								currentTotalDuration: this.timerangesCountDisplay(workshopHours),
							},
						].map(entry => {
							if (entry.type === "theo-hour" && (isVarVarContract || isVarContract)) {
								return null
							}
							return (
								<div key={entry.type} className={"legend-item " + entry.type}>
									<div className="label">
										{entry.label} - {entry.currentTotalDuration}
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<WeekTimeranges
					timeranges={allTimeRanges}
					handleEditTimeRange={this.handleEditTimeRange}
					handleRemoveTimeRange={this.handleRemoveTimeRange}
					top={
						schedulePeriod.hasMaxHoursPerDay && (
							<div className="max-mins-per-days">
								{days.map(day => (
									<div key={day.tKey} className="max-mins-per-day">
										<PootsyTwoTextInput
											name={day.number}
											label={t("max_hours_for_day")}
											customClass="double-working-hours-per-week-input"
											secondName={`min_${day.number}`}
											type={"number"}
											separator={t("hours_shorthand")}
											min="0"
											onChange={this.handleDaysMaxHoursChange}
											values={this.state.daysMaxHours[day.number].split(
												t("hours_shorthand")
											)}
										/>
									</div>
								))}
							</div>
						)
					}
					bottom={
						<div className="bottom">
							{days.map(day => (
								<div key={day.tKey} className={"day-action " + day.tKey}>
									<div className="day-actions">
										<div
											className="action-button"
											onClick={this.openNewTimeRangeModal}
											data-day={day.number}
											data-type="avail"
										>
											+ {t("add_avail")}
										</div>
										{!(isVarVarContract || isVarContract) && (
											<div
												className="action-button"
												onClick={this.openNewTimeRangeModal}
												data-day={day.number}
												data-type="theo-hour"
											>
												+ {t("add_theo_hour")}
											</div>
										)}
										<div
											className="action-button"
											onClick={this.openNewOtherActivityModal}
											data-day={day.number}
										>
											+ {t("add_other_activity")}
										</div>
										{showWorkshop && currAffiliate.workshopAddOn && (
											<div
												className="action-button"
												onClick={this.openNewWorkshopHourModal}
												data-day={day.number}
											>
												+ {t("add_workshop_hour")}
											</div>
										)}
										<div
											className="action-button"
											onClick={this.openNewBookingModal}
											data-day={day.number}
										>
											+ {t("add_booking")}
										</div>
									</div>
								</div>
							))}
						</div>
					}
				/>
			</Fragment>
		)
	}
}

WorkerSchedule.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(WorkerSchedule)
