import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import moment from "moment"

import {
	ContentLayout,
	DatedLine,
	OptionBox,
	PootsyButton,
	PootsySubHeading,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import Paginator from "./Paginator"

import arrowRightIcon from "../static/images/arrow-right-icon.png"
import { TrainingModal } from "./TrainingModal"
import DateFilterer from "./DateFilterer"
import SearchWorker from "./SearchWorker"

class SingularTrainings extends Component {
	componentDidMount = () => {
		if (this.props.specificWorker)
			this.handleChanges({ searchedAffiliateWorkerId: this.props.workerId })

		this.props.dispatch(actions.fetchSingularTrainings())

		const categories = this.props.currAffiliate.secSocEvents.categories
		const training_category = categories.filter(category =>
			category.id.endsWith("_training")
		)[0]
		this.handleChanges({ activityCategory: training_category.id })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.singularTrainingsStateChange(changes))
	}

	updateQuery = async changes => {
		await this.handleChanges({ currentPage: changes.value })
		this.props.dispatch(actions.fetchSingularTrainings())
	}
	fetchListForPDF = () => {
		this.props.dispatch(actions.createTrainingsListPDF(this.context.t))
	}
	showEditTrainingModal = e => {
		let { index } = e.target.dataset
		let { singularTrainings } = this.props
		let currTraining = singularTrainings[index].attributes
		this.handleChanges({
			showEditTrainingModal: true,
			currentEditedTraining: index,
			trainingNote: currTraining.affiliate_note,
			trainingTitle: currTraining.title,
			activityCode: currTraining.ss_code,
			trainingType: currTraining.motif_type,
			trainingStart: moment(currTraining.start_date).set({
				hour: currTraining.start_time.hour,
				minute: currTraining.start_time.minute,
				second: currTraining.start_time.second,
			}),
			trainingEnd: currTraining.end_date
				? moment(currTraining.end_date).set({
						hour: currTraining.end_time.hour,
						minute: currTraining.end_time.minute,
						second: currTraining.end_time.second,
				  })
				: moment(currTraining.end_time),
		})
	}
	hideEditTrainingModal = () => {
		this.handleChanges({ showEditTrainingModal: false })
	}
	handleTrainingModalChange = changes => {
		this.handleChanges(changes)
	}
	toggleDeleteTrainingModal = e => {
		this.handleChanges({
			showDeleteWarning: true,
			currentDeletedTraining: e.target.dataset.index,
		})
	}
	closeDeleteWarningModal = () => {
		this.handleChanges({
			showDeleteWarning: false,
		})
	}
	submitUpdatedTraining = async () => {
		let {
			dispatch,
			singularTrainings,
			singularTrainingsPage: {
				currentEditedTraining,
				activityCode,
				activityCategory,
				trainingStart,
				trainingEnd,
				trainingNote,
			},
		} = this.props
		let currTraining = singularTrainings[currentEditedTraining]

		let init = {
			method: "PUT",
			body: JSON.stringify({
				execution_date: trainingStart.format("DD-MM-YYYY"),
				start_date: trainingStart.format("YYYY-MM-DD"),
				end_date: trainingEnd.format("YYYY-MM-DD"),
				start_time: trainingStart.format("HH:mm"),
				end_time: trainingEnd.format("HH:mm"),
				activity_code: activityCode,
				activity_category: activityCategory,
				affiliate_note: trainingNote,
				recurrence: "one_occurrence",
			}),
		}
		let url = `/front/affiliates/worker_contracts/${currTraining.attributes.worker_contract_id}/other_activities/${currTraining.id}`

		dispatch(actions.simpleFetch(url, init, "UPDATESINGULARTRAINING")).then(
			({ actionType }) => {
				if (actionType === actions.UPDATESINGULARTRAINING_SUCCESS) {
					this.props.dispatch(actions.fetchSingularTrainings())
				}
			}
		)
		this.hideEditTrainingModal()
	}
	deleteTraining = e => {
		let { index } = e.target.dataset
		let { dispatch, singularTrainings } = this.props
		let currTraining = singularTrainings[index]
		let url = `/front/affiliates/worker_contracts/${currTraining.attributes.worker_contract_id}/other_activities/${currTraining.id}`
		let init = {
			method: "DELETE",
			body: JSON.stringify({
				delete_type: "all",
				delete_date: currTraining.start_date,
			}),
		}
		dispatch(
			actions.simpleFetch(url, init, "DELETESINGULARTRAINING", { trainingIndex: index })
		).then(({ actionType }) => {
			if (actionType === actions.DELETESINGULARTRAINING_SUCCESS) {
				this.props.dispatch(actions.fetchSingularTrainings())
			}
		})
		this.handleChanges({
			showDeleteWarning: false,
		})
	}
	handleFilterCode = e => {
		let { value } = e.target
		this.handleChanges({ selectedCode: value })
		this.props.dispatch(actions.fetchSingularTrainings())
	}
	handleFilterWorker = workerName => {
		this.handleChanges({ searchedAffiliateWorker: { name: workerName } })
		if (workerName === "" || workerName === null) {
			this.handleChanges({ searchedAffiliateWorkerId: null })
			this.props.dispatch(actions.fetchSingularTrainings())
		}
	}
	handleFilterWorkerClick = worker => {
		this.handleChanges({
			searchedAffiliateWorkerId: Number(worker.id),
			searchedAffiliateWorker: { name: worker.attributes.display_name },
		})
		this.props.dispatch(actions.fetchSingularTrainings())
	}
	handleDateFilterChange = changes => {
		let newObj = { rangeStart: changes.from, rangeEnd: changes.to }
		this.handleChanges(newObj)
		this.props.dispatch(actions.fetchSingularTrainings())
	}
	renderButtons = (entry, index) => {
		let { t } = this.context
		return (
			<Fragment>
				<PootsyButton
					key="edit"
					text={t("edit")}
					theme="cancel"
					onClick={this.showEditTrainingModal}
					dataset={{ "data-index": index }}
				/>
				<PootsyButton
					key="refuse"
					onClick={this.toggleDeleteTrainingModal}
					customClass="absence-item-refuse-button"
					text={t("✕")}
					theme="fadedRed"
					dataset={{ "data-index": index }}
				/>
			</Fragment>
		)
	}
	render() {
		let { t } = this.context
		let {
			singularTrainings,
			selectedWorkers,
			currAffiliate,
			singularTrainingsPage: {
				showDeleteWarning,
				showEditTrainingModal,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorker,
				selectedCode,
				trainingTitle,
				activityCode,
				activityCategory,
				trainingStart,
				trainingEnd,
				trainingNote,
				currentDeletedTraining,
				currentPage,
				pagination,
			},
		} = this.props

		let filteredCodes = currAffiliate.secSocEvents.codes
		if (activityCategory) {
			filteredCodes = filteredCodes.filter(entry => entry.category_id === activityCategory)
		}

		let optionsBoxes = (
			<OptionBox boxTitle={t("singular_trainings")} customClass="TrainingFilters">
				<PootsySubHeading text={t("date_filters")} />
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					onChange={this.handleDateFilterChange}
				/>
				{!this.props.specificWorker && (
					<SearchWorker
						onChange={this.handleFilterWorker}
						onClick={this.handleFilterWorkerClick}
						value={searchedAffiliateWorker.name}
					/>
				)}

				<PootsySelectInput
					name="selected_code"
					defaultText={t("selected_code")}
					selected={selectedCode}
					options={filteredCodes.map(e => ({ label: t(e.id), value: e.id }))}
					onChange={this.handleFilterCode}
					allowEmpty={true}
				/>
				<PootsyButton text={t("download_list_pdf")} onClick={this.fetchListForPDF} />
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="Trainings">
				{showEditTrainingModal && (
					<TrainingModal
						selectedWorkers={selectedWorkers}
						trainingTitle={trainingTitle}
						activityCode={activityCode}
						activityCategory={activityCategory}
						trainingStart={trainingStart}
						trainingEnd={trainingEnd}
						trainingNote={trainingNote || ""}
						toggleModal={this.hideEditTrainingModal}
						editMode={true}
						onChange={this.handleTrainingModalChange}
						submit={this.submitUpdatedTraining}
						t={t}
					/>
				)}
				{showDeleteWarning && (
					<ModalLayout
						title={t("delete_training")}
						formDivClass="training-delete-warning-modal"
						closeModal={this.closeDeleteWarningModal}
					>
						<div className="delete-warning-prompt">
							{t("delete_training_warning_prompt")}
						</div>
						<div className="buttons">
							<PootsyButton
								text={t("submit")}
								dataset={{
									"data-index": currentDeletedTraining,
									"data-choice": "refuse",
								}}
								onClick={this.deleteTraining}
							/>
							<PootsyButton
								text={t("cancel")}
								theme="cancel"
								onClick={this.closeDeleteWarningModal}
							/>
						</div>
					</ModalLayout>
				)}
				<section>
					<div className="trainings-list">
						{singularTrainings.map((entry, index) => {
							let currentCode = entry.attributes.ss_code
							return (
								<DatedLine
									key={entry.id}
									date={moment(entry.attributes.start_date).format("DD")}
									month={moment(entry.attributes.start_date).format("MMMM")}
									customClass="training-item"
								>
									<div className="training-item-start-end">
										<div className="start-time">
											<label>{t("start")}:</label>
											<span>{entry.attributes.formated_start_time}</span>
										</div>
										<img alt="presentation" src={arrowRightIcon} />
										<div className="end-time">
											<label>{t("end")}:</label>
											<span>{entry.attributes.formated_end_time}</span>
										</div>
									</div>
									<div className="training-affiliate-note">
										<label>{t("note")}:</label>
										<span>{entry.attributes.note}</span>
									</div>
									{currentCode && (
										<div className="training-ss-code">
											<label>{t("activity_code")}:</label>
											<span>{`${currentCode} - ${t(
												entry.attributes.training_code_label
											)}`}</span>
										</div>
									)}
									<div className="training-affiliate-note">
										<label>{t("worker")}:</label>
										{entry.attributes.worker_display_name}
									</div>
									{this.renderButtons(entry, index)}
								</DatedLine>
							)
						})}
					</div>
				</section>
				<Paginator
					name="pageQuery"
					currPage={currentPage}
					totalPages={pagination.total_pages}
					goToPage={this.updateQuery}
				/>
			</ContentLayout>
		)
	}
}

SingularTrainings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	singularTrainingsPage: state.redComponents.singularTrainingsPage,
	singularTrainings: state.redData.singularTrainings,
	submitTrainingSuccess: state.requestsSuccess[actions.CREATETRAINING_SUCCESS],
	selectedWorkers: state.redComponents.trainingsPage.selectedWorkers,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(SingularTrainings)
