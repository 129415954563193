import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"
import SingularTrainings from "./SingularTrainings"

class WorkerTrainings extends Component {
	componentWillUnmount = () => {
		this.handleChanges({ searchedAffiliateWorkerId: null })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.singularTrainingsStateChange(changes))
	}
	render() {
		return (
			<SingularTrainings
				workerId={this.props.match.params.id}
				specificWorker={true}
			></SingularTrainings>
		)
	}
}

WorkerTrainings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
})
export default connect(mapStateToProps)(WorkerTrainings)
