import iso3166 from "../iso-3166-1.alpha-2.js"

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ")
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
	}
	// Directly return the joined string
	return splitStr.join(" ")
}

export const countryCodes = iso3166
	.map(e => ({
		value: e.value,
		label: titleCase(e.label),
	}))
	.sort((a, b) => a.label.localeCompare(b.label))
// 	.filter(entry => entry.value !== "GAB") // yeah they overrode it. who needs standards amirite...
// 	.concat([
// 	{ value: "GAA", label: "ANTILLES AMERICAINES" },
// 	{ value: "GAB", label: "ANTILLES BRITANIQUES" },
// 	{ value: "GAF", label: "ANTILLES FRANCAISES" },
// 	{ value: "GAP", label: "APATRIDE" },
// 	{ value: "GBO", label: "BOPHUTATSWANA" },
// 	{ value: "GCA", label: "CABINDA" },
// 	{ value: "OAF", label: "d'origine afghane" },
// 	{ value: "OAL", label: "d'origine albanaise" },
// 	{ value: "ODZ", label: "d'origine algérienne" },
// 	{ value: "ODE", label: "d'origine allemande" },
// 	{ value: "OAO", label: "d'origine angolaise" },
// 	{ value: "OAR", label: "d'origine argentine" },
// 	{ value: "OAM", label: "d'origine arménienne" },
// 	{ value: "OAT", label: "d'origine autrichienne" },
// 	{ value: "OAZ", label: "d'origine azerbaïdjanaise" },
// 	{ value: "OBH", label: "d'origine bahreïnie" },
// 	{ value: "OBD", label: "d'origine bangladeshienne" },
// 	{ value: "OBJ", label: "d'origine béninoise" },
// 	{ value: "OBT", label: "d'origine bhoutanaise" },
// 	{ value: "OBY", label: "d'origine biélorusse" },
// 	{ value: "OBM", label: "d'origine birmane" },
// 	{ value: "OBO", label: "d'origine bolivienne" },
// 	{ value: "OBA", label: "d'origine bosniaque" },
// 	{ value: "OBR", label: "d'origine brésilienne" },
// 	{ value: "OBG", label: "d'origine bulgare" },
// 	{ value: "OBI", label: "d'origine burundaise" },
// 	{ value: "OKH", label: "d'origine cambodgienne" },
// 	{ value: "OCM", label: "d'origine camerounaise" },
// 	{ value: "OCV", label: "d'origine capverdienne" },
// 	{ value: "OCF", label: "d'origine centrafricaine" },
// 	{ value: "OCL", label: "d'origine chilienne" },
// 	{ value: "OCN", label: "d'origine chinoise" },
// 	{ value: "OCO", label: "d'origine colombienne" },
// 	{ value: "OCG", label: "d'origine congolaise" },
// 	{ value: "OCB", label: "d'origine congolaise Brazzaville" },
// 	{ value: "OKR", label: "d'origine coréenne" },
// 	{ value: "OHR", label: "d'origine croate" },
// 	{ value: "OCU", label: "d'origine cubaine" },
// 	{ value: "OZA", label: "d'origine d'afrique du Sud" },
// 	{ value: "OCI", label: "d'origine de Côte d'Ivoire" },
// 	{ value: "ODJ", label: "d'origine djiboutienne" },
// 	{ value: "ODO", label: "d'origine dominicaine" },
// 	{ value: "OBF", label: "d'origine du Burkina Faso" },
// 	{ value: "OSL", label: "d'origine du Sierra Leone" },
// 	{ value: "OEG", label: "d'origine égyptienne" },
// 	{ value: "OEC", label: "d'origine équatorienne" },
// 	{ value: "OER", label: "d'origine érythréenne" },
// 	{ value: "OES", label: "d'origine espagnole" },
// 	{ value: "OEE", label: "d'origine estonienne" },
// 	{ value: "OET", label: "d'origine éthiopienne" },
// 	{ value: "OFI", label: "d'origine finlandaise" },
// 	{ value: "OFR", label: "d'origine française" },
// 	{ value: "OGA", label: "d'origine Gabonaise" },
// 	{ value: "OGE", label: "d'origine géorgienne" },
// 	{ value: "OGH", label: "d'origine ghanéenne" },
// 	{ value: "OGR", label: "d'origine grecque" },
// 	{ value: "OGT", label: "d'origine guatémaltèque" },
// 	{ value: "OGN", label: "d'origine guinéenne" },
// 	{ value: "OGY", label: "d'origine guyanaise" },
// 	{ value: "OHT", label: "d'origine haïtienne" },
// 	{ value: "OHU", label: "d'origine hongroise" },
// 	{ value: "OIN", label: "d'origine indienne" },
// 	{ value: "OID", label: "d'origine indonésienne" },
// 	{ value: "OIQ", label: "d'origine irakienne" },
// 	{ value: "OIR", label: "d'origine iranienne" },
// 	{ value: "OIL", label: "d'origine israëlienne" },
// 	{ value: "OIT", label: "d'origine italienne" },
// 	{ value: "OJO", label: "d'origine jordanienne" },
// 	{ value: "OKZ", label: "d'origine kazakh" },
// 	{ value: "OKE", label: "d'origine kenyane" },
// 	{ value: "OKM", label: "d'origine khmer" },
// 	{ value: "OKG", label: "d'origine kirghize" },
// 	{ value: "OKW", label: "d'origine koweïtienne" },
// 	{ value: "OLA", label: "d'origine laotienne" },
// 	{ value: "OLV", label: "d'origine lettonienne" },
// 	{ value: "OLB", label: "d'origine libanaise" },
// 	{ value: "OLR", label: "d'origine libérienne" },
// 	{ value: "OLY", label: "d'origine libyenne" },
// 	{ value: "OLT", label: "d'origine lituanienne" },
// 	{ value: "OMK", label: "d'origine macédonienne" },
// 	{ value: "OMG", label: "d'origine malagassy" },
// 	{ value: "OMY", label: "d'origine Malaisienne" },
// 	{ value: "OML", label: "d'origine Malienne" },
// 	{ value: "OMA", label: "d'origine marocaine" },
// 	{ value: "OMR", label: "d'origine mauritanienne" },
// 	{ value: "OMD", label: "d'origine Moldave" },
// 	{ value: "OMZ", label: "d'origine mozambique" },
// 	{ value: "ONA", label: "d'origine namibienne" },
// 	{ value: "ONP", label: "d'origine Népalaise" },
// 	{ value: "ONI", label: "d'origine nicaraguayenne" },
// 	{ value: "ONG", label: "d'origine nigériane" },
// 	{ value: "ONE", label: "d'origine nigérienne" },
// 	{ value: "OUG", label: "d'origine ougandaise" },
// 	{ value: "OUZ", label: "d'origine ouzbeks" },
// 	{ value: "OPK", label: "d'origine pakistanaise" },
// 	{ value: "OPP", label: "d'origine palestinienne" },
// 	{ value: "OPS", label: "d'origine palestinienne" },
// 	{ value: "OPA", label: "d'origine panaméenne" },
// 	{ value: "OPY", label: "d'origine paraguayenne" },
// 	{ value: "OPE", label: "d'origine péruvienne" },
// 	{ value: "OPH", label: "d'origine philippines" },
// 	{ value: "OPL", label: "d'origine polonaise" },
// 	{ value: "OPT", label: "d'origine portugaise" },
// 	{ value: "ORO", label: "d'origine roumaine" },
// 	{ value: "ORU", label: "d'origine russe" },
// 	{ value: "ORW", label: "d'origine rwandaise" },
// 	{ value: "OSV", label: "d'origine salvadorienne" },
// 	{ value: "OEH", label: "d'origine saoudienne" },
// 	{ value: "OSN", label: "d'origine sénégalaise" },
// 	{ value: "ORS", label: "d'origine serbe" },
// 	{ value: "OSI", label: "d'origine Slovène" },
// 	{ value: "OSO", label: "d'origine somalienne" },
// 	{ value: "OSD", label: "d'origine soudanaise" },
// 	{ value: "OLK", label: "d'origine srilankaise" },
// 	{ value: "OSY", label: "d'origine syrienne" },
// 	{ value: "OTD", label: "d'origine tchadienne" },
// 	{ value: "OCZ", label: "d'origine tchèque" },
// 	{ value: "OTH", label: "d'origine thaïlandaise" },
// 	{ value: "OTB", label: "d'origine tibétaine" },
// 	{ value: "OTG", label: "d'origine togolaise" },
// 	{ value: "OTU", label: "d'origine tunisienne" },
// 	{ value: "OTR", label: "d'origine turque" },
// 	{ value: "OUA", label: "d'origine ukrainienne" },
// 	{ value: "OUY", label: "d'origine uruguayenne" },
// 	{ value: "OVN", label: "d'origine vietnamienne" },
// 	{ value: "OYE", label: "d'origine yéménite" },
// 	{ value: "OZI", label: "d'origine zaïroise" },
// 	{ value: "GEM", label: "EN MER" },
// 	{ value: "GHW", label: "HAWAII" },
// 	{ value: "GHK", label: "HONG KONG (R.U.)" },
// 	{ value: "GID", label: "ILE DE DOMINICA" },
// 	{ value: "GIC", label: "ILES CANARIES" },
// 	{ value: "GIP", label: "ILES DU PACIFIQUE" },
// 	{ value: "GSI", label: "INCONNU GROUP S" },
// 	{ value: "GXX", label: "INDETERMINE" },
// 	{ value: "GKO", label: "KOSOVO" },
// 	{ value: "GMD", label: "MADERE" },
// 	{ value: "GOU", label: "ONU INDETERMINE" },
// 	{ value: "OGW", label: "originaire de Guinée-Bissau" },
// 	{ value: "OMM", label: "originaire de Myanmar" },
// 	{ value: "OAE", label: "originaire des Emirats Arabes Unis" },
// 	{ value: "GOT", label: "OTAN" },
// 	{ value: "GXY", label: "PAS ENCORE DEFINITEVEMENT ETABLIE" },
// 	{ value: "GRE", label: "RAYE POUR L'ETRANGER" },
// 	{ value: "GRF", label: "REFUGIE" },
// 	{ value: "GRO", label: "REFUGIE ONU" },
// 	{ value: "GSE", label: "SENEGAMBIE" },
// 	{ value: "GSH", label: "SHAPE" },
// 	{ value: "GTA", label: "TAHITI" },
// 	{ value: "GNZ", label: "TERRITOIRE DEP. DE LA NOUVELLE ZELANDE" },
// 	{ value: "GDA", label: "TERRITOIRE DEP. DE L'AUSTRALIE" },
// 	{ value: "GTL", label: "TIMOR" },
// 	{ value: "GTK", label: "TRANSKEY" },
// ])

export default [
	{
		categoryName: "groups_identification",
		inputs: [
			{
				label: "cellphone",
				inputType: "text",
				required: false,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.mobilePhone ||
					(currentWorker.mobilePhone &&
						currentWorker.mobilePhone.replace("+", "00").replace(/ /g, "")),
			},
			{
				label: "private_email",
				inputType: "text",
				required: false,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.email || currentWorker.email,
			},
			{
				label: "nationality",
				inputType: "select",
				required: false,
				options: countryCodes,
			},
			{
				label: "birthplace_zip_code",
				inputType: "text",
				required: false,
			},
			{
				label: "birthplace_town",
				inputType: "text",
				required: false,
			},
			{
				label: "date_of_birth",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "birthplace_country",
				inputType: "select",
				required: false,
				options: countryCodes,
			},
			{
				label: "badge_number",
				inputType: "text",
				required: false,
			},
			{
				label: "worker_profile",
				inputType: "select",
				required: true,
				options: [
					{ label: "STUDENT", value: "STUD" },
					{ label: "OUVRIER", value: "OUVR" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.worker_profile || currentWorker.worker_profile,
			},
			{
				label: "region",
				inputType: "select",
				required: false,
				options: [
					{ label: "region_nd", value: "0" },
					{ label: "region_brussels", value: "1" },
					{ label: "region_flanders", value: "2" },
					{ label: "region_wallonia", value: "3" },
					{ label: "region_federal", value: "4" },
				],
				default: (currentWorker, secSocPrefill) => {
					let valuesMapping = { Wallonie: "3", Bruxelles: "1" }
					if (secSocPrefill.region) return valuesMapping[secSocPrefill.region]
					return valuesMapping[currentWorker.region]
				},
			},
			{
				label: "language_regimen",
				inputType: "select",
				required: false,
				options: [
					{ label: "lang_regimen_nd", value: "0" },
					{ label: "lang_regimen_fr", value: "1" },
					{ label: "lang_regimen_nl", value: "2" },
					{ label: "lang_regimen_de", value: "3" },
					{ label: "lang_regimen_frnl", value: "4" },
					{ label: "lang_regimen_frde", value: "5" },
				],
				default: (currentWorker, secSocPrefill) => {
					let valuesMapping = { fr: "1", nl: "2" }
					if (secSocPrefill.mainLanguage) return valuesMapping[secSocPrefill.mainLanguage]
					return valuesMapping[currentWorker.mainLanguage]
				},
			},
			{
				label: "language",
				inputType: "select",
				required: false,
				options: [
					{ label: "lang_nd", value: "0" },
					{ label: "lang_fr", value: "1" },
					{ label: "lang_nl", value: "2" },
					{ label: "lang_de", value: "3" },
					{ label: "lang_other", value: "4" },
				],
				default: (currentWorker, secSocPrefill) => {
					let valuesMapping = { fr: "1", nl: "2" }
					if (secSocPrefill.mainLanguage) return valuesMapping[secSocPrefill.mainLanguage]
					return valuesMapping[currentWorker.mainLanguage]
				},
			},
			{
				label: "civil_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "civil_status_celib", value: "1" },
					{ label: "civil_status_married", value: "2" },
					{ label: "civil_status_widow", value: "3" },
					{ label: "civil_status_divorced", value: "4" },
					{ label: "civil_status_separated", value: "5" },
					{ label: "civil_status_cohab", value: "6" },
				],
			},
			{
				label: "soc_sec_id",
				inputType: "text",
				required: false,
				disabled: true,
				value: currentWorker => currentWorker.socSecId,
			},
		],
	},
	{
		categoryName: "groups_conjoint",
		show: values => ["2", "5", "6"].includes(values["civil_status"]),
		inputs: [
			{
				label: "partner_disabled",
				inputType: "select",
				required: false,
				options: [
					{ label: "no", value: "false" },
					{ label: "yes", value: "true" },
				],
			},
			{
				label: "partner_name",
				inputType: "text",
				required: false,
			},
			{
				label: "partner_wedding_date",
				inputType: "datepicker",
				required: false,
				show: values => values["civil_status"] === "2",
			},
			{
				label: "dependent_partner_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "dependent_partner_revenue", value: "0" }, // conjoint avec revenus
					{ label: "dependent_partner_no_revenue", value: "1" }, // conjoint sans revenus
					{ label: "dependent_partner_disabled", value: "2" }, // conjoint handicapé à charge
					{ label: "dependent_partner_worker_prepension_non_head", value: "3" }, // conjoint à charge ; travailleur prépensionné non-chef de ménage
					{ label: "dependent_partner_disabled_worker_prepension_non_head", value: "4" }, // conjoint handicapé à charge ; travailleur prépensionné non-chef de ménage
					{ label: "dependent_partner_worker_prepension_head", value: "5" }, // conjoint non à charge ; travailleur prépensionné chef de ménage
					{ label: "dependent_partner_minimal_pension", value: "8" }, // conjoint pension minime
					{ label: "dependent_partner_minimal_revenue", value: "9" }, // conjoint revenus minimes
				],
			},
		],
	},
	{
		categoryName: "groups_contrat",
		inputs: [
			{
				label: "risk_class", // "Z89"
				inputType: "select",
				options: [
					{ label: "risk_class_1", value: "1" },
					{ label: "risk_class_2", value: "2" },
					{ label: "risk_class_3", value: "3" },
					{ label: "risk_class_4", value: "4" },
					{ label: "risk_class_5", value: "5" },
					{ label: "risk_class_6", value: "6" },
					{ label: "risk_class_401", value: "401" },
					{ label: "risk_class_402", value: "402" },
					{ label: "risk_class_403", value: "403" },
					{ label: "risk_class_404", value: "404" },
					{ label: "risk_class_405", value: "405" },
					{ label: "risk_class_406", value: "406" },
					{ label: "risk_class_407", value: "407" },
					{ label: "risk_class_408", value: "408" },
				],
			},
			{
				label: "ccte",
				inputType: "select",
				required: true,
				hideInput: currAffiliate => currAffiliate.id !== 66,
				//"Proxy Services S.A.",
				options: [
					{ label: "1", value: "1" },
					{ label: "2", value: "2" },
					{ label: "3", value: "3" },
				],
			},
			{
				label: "end_of_indemnity_date",
				inputType: "datepicker",
				required: false, //"Contract" section. Displayed only if the user has chosen an "End date reason"
				show: values => values["end_reason"],
				allowUnset: true,
			},
			{
				label: "theoretical_end_of_indemnity_date",
				inputType: "datepicker",
				required: false, //"Contract" section. Displayed only if the user has chosen an "End date reason"
				show: values => values["end_reason"],
				allowUnset: true,
			},
			{
				label: "bic",
				inputType: "text",
				required: true,
			},
			{
				label: "iban",
				inputType: "text",
				required: true,
			},
			{
				label: "end_reason",
				inputType: "select",
				required: (values, currentAffiliate, currentContract, workerContracts) =>
					end_reason_required(currentAffiliate, values, currentContract, workerContracts),
				options: [
					{ label: "end_reason_nd", value: "0" }, // non significatif
					{ label: "end_reason_dem_no_notice", value: "1" }, // démission sans préavis
					{ label: "end_reason_terminated", value: "2" }, // licenciement
					{ label: "end_reason_notice_by_worker", value: "3" }, // préavis par le travailleur
					{ label: "end_reason_death", value: "4" }, // décès
					{ label: "end_reason_retired", value: "5" }, // pension de retraite
					{ label: "end_reason_end_fixed_term", value: "8" }, // fin contrat durée déterminée / trav net. défini
					{ label: "end_reason_misconduct", value: "9" }, // rupture par l’employeur pour motif grave
					{ label: "end_reason_mutated", value: "12" }, // transfert / mutation
					{ label: "end_reason_status_change", value: "13" }, // changement de statut
					{ label: "end_reason_medical", value: "50" }, // force majeure raison médicale
					{ label: "end_reason_non_medical_necessity", value: "51" }, // force majeure autre que raison médicale
					{ label: "end_reason_accord", value: "54" }, // convention de rupture de commun accord
					{ label: "end_reason_bankruptcy", value: "55" }, // faillite
					{ label: "end_reason_rcc", value: "56" }, // rcc : régime de chômage avec complément d’entreprise
					{ label: "end_reason_rcic", value: "57" }, // rcic : régime de chômage avec indemnité complémentaire pour travailleurs agés
					{ label: "end_reason_cao_32b", value: "58" }, // cao 32 bis transfert conventionnel d’entreprise
					{ label: "end_reason_collective_layoff", value: "59" }, // licenciement collectif
					{ label: "end_reason_closed", value: "60" }, // fermeture
					{ label: "force_majeure_employer", value: "61" }, // fermeture
					{ label: "end_reason_no_certificate_or_c4", value: "99" }, // ni attestation occup ni c4
				],
			},
			{
				label: "contract_type",
				inputType: "select",
				required: true,
				options: [
					{ label: "contract_type_fixed_term", value: "1" }, // contrat de travail à durée déterminée
					{ label: "contract_type_permanent", value: "2" }, // contrat de travail à durée indéterminée
					{ label: "contract_type_substitute", value: "3" }, // contrat de remplacement
					{ label: "contract_type_defined_tasks", value: "4" }, // pour taches définies
					{ label: "contract_type_specific_tasks", value: "5" }, // pour taches spécifiques
					{ label: "contract_type_intern", value: "6" }, // statutaire en stage
					{ label: "contract_type_interim", value: "7" }, // contractuel en intérimaire
					{ label: "contract_type_doctor", value: "8" }, // médecin
					{ label: "contract_type_jobist", value: "9" }, // jobiste
					{ label: "contract_type_concierge", value: "10" }, // concierge
					{ label: "contract_type_law_24071987", value: "11" }, // contrat loi (24/07/1987)
				],
			},
			{
				label: "fixed_term_contract_length",
				inputType: "select",
				required: false,
				show: values => values["contract_type"] === "1",
				options: [
					{ label: "contract_length_under_3_months", value: "A" },
					{ label: "contract_length_over_3_months", value: "B" },
				],
			},
			{
				label: "full_or_part_time_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "part_time_nd", value: "0" }, // inconnu
					{ label: "part_time_full_time", value: "1" }, // travail à temps plein
					{ label: "part_time_voluntary_full_days", value: "2" }, // temps partiel volontaire - journées complètes
					{ label: "part_time_voluntary_part_days", value: "3" }, // temps partiel volontaire - journées incomplètes
					{ label: "part_time_voluntary_nd", value: "5" }, // temps partiel volontaire - horaire indéterminé
					{ label: "part_time_involuntary_full_days", value: "6" }, // temps partiel involontaire - journées complètes
					{ label: "part_time_involuntary_part_days", value: "7" }, // temps partiel involontaire - journées incomplètes
					{ label: "part_time_involuntary_nd", value: "8" }, // temps partiel involontaire - horaire indéterminé
				],
			},
		],
	},
	{
		categoryName: "groups_categorie_du_contrat",
		inputs: [
			{
				label: "worker_title",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.worker_title,
			},
		],
	},
	{
		categoryName: "groups_remuneration",
		inputs: [
			{
				label: "gross_salary",
				inputType: "text",
				required: true,
			},
			{
				label: "pay_period",
				inputType: "select",
				required: true,
				options: [
					{ label: "undefined_compensation", value: 0 },
					{ label: "fixed_period", value: 1 },
					{ label: "periodic_with_variable_part", value: 2 },
					{ label: "daily_compensation", value: 3 },
					{ label: "hourly_compensation", value: 4 },
					{ label: "compensation_per_piece", value: 5 },
					{ label: "service_vouchers_hourly_compensation", value: 6 },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.pay_period,
			},
			{
				label: "payment_mode",
				inputType: "select",
				required: false,
				options: [
					{ label: "payment_mode_other", value: "0" }, // paiement par moyen autre que banque
					{ label: "payment_mode_sepa", value: "1" }, // paiement par banque (sepa)
					{ label: "payment_mode_cash", value: "2" }, // de la main à la main
					{ label: "payment_mode_bank_non_sepa", value: "6" }, // banque non-sepa étranger
				],
			},
			{
				label: "periodicity_of_remuneration",
				inputType: "select",
				required: false,
				options: [
					{ label: "per_month", value: "1" },
					{ label: "per_half_month", value: "2" },
					{ label: "per_two_weeks", value: "3" },
					{ label: "per_four_weeks", value: "4" },
					{ label: "per_week", value: "5" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.periodicity_of_remuneration,
			},
			// This should only be displayed when affiliate.meal_vouchers_config.use_different_codes == true
			{
				label: "meal_voucher_type",
				inputType: "select",
				required: false,
				hideInput: currAffiliate =>
					!(
						currAffiliate &&
						currAffiliate.id !== 54 &&
						currAffiliate.mealVoucherConfig &&
						currAffiliate.mealVoucherConfig.use_different_codes
					),
				options: [
					{ label: "three_euro_meal_voucher", value: "three_euro_meal_voucher" },
					{ label: "four_euro_meal_voucher", value: "four_euro_meal_voucher" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.meal_voucher_type,
			},
			// This should only be displayed when affiliate.dienstenthuis? && affiliate.meal_vouchers_config.use_different_codes == true
			{
				label: "meal_voucher_type",
				inputType: "select",
				required: false,
				hideInput: currAffiliate =>
					!(
						currAffiliate &&
						currAffiliate.id === 54 &&
						currAffiliate.mealVoucherConfig &&
						currAffiliate.mealVoucherConfig.use_different_codes
					),
				options: [
					{ label: "three_euro_meal_voucher", value: "three_euro_meal_voucher" },
					{ label: "four_euro_meal_voucher", value: "four_euro_meal_voucher" },
					{
						label: "two_point_five_euro_meal_voucher",
						value: "two_point_five_euro_meal_voucher",
					},
					{
						label: "three_point_five_euro_meal_voucher",
						value: "three_point_five_euro_meal_voucher",
					},
					{ label: "five_euro_meal_voucher", value: "five_euro_meal_voucher" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.meal_voucher_type,
			},
		],
	},
	{
		categoryName: "groups_imputations",
		inputs: [
			{
				label: "level_1_number",
				inputType: "text",
				required: false,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.level_1_number,
			},
			{
				label: "level_2_number",
				inputType: "text",
				required: false,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.level_2_number,
			},
			{
				label: "level_3_number",
				inputType: "text",
				required: false,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.level_3_number,
			},
		],
	},
	{
		categoryName: "groups_onss",
		inputs: [
			{
				label: "local_unit_number",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.local_unit_number,
			},
			{
				label: "onss_discount_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "groups_onss_discount_code_1", value: "1" },
					{ label: "groups_onss_discount_code_2", value: "2" },
					{ label: "groups_onss_discount_code_22", value: "22" },
					{ label: "groups_onss_discount_code_2A", value: "2A" },
					{ label: "groups_onss_discount_code_2B", value: "2B" },
					{ label: "groups_onss_discount_code_2C", value: "2C" },
					{ label: "groups_onss_discount_code_2D", value: "2D" },
					{ label: "groups_onss_discount_code_2E", value: "2E" },
					{ label: "groups_onss_discount_code_2F", value: "2F" },
					{ label: "groups_onss_discount_code_2G", value: "2G" },
					{ label: "groups_onss_discount_code_2H", value: "2H" },
					{ label: "groups_onss_discount_code_2J", value: "2J" },
					{ label: "groups_onss_discount_code_2K", value: "2K" },
					{ label: "groups_onss_discount_code_2L", value: "2L" },
					{ label: "groups_onss_discount_code_2M", value: "2M" },
					{ label: "groups_onss_discount_code_2N", value: "2N" },
					{ label: "groups_onss_discount_code_2P", value: "2P" },
					{ label: "groups_onss_discount_code_2Q", value: "2Q" },
					{ label: "groups_onss_discount_code_2R", value: "2R" },
					{ label: "groups_onss_discount_code_2S", value: "2S" },
					{ label: "groups_onss_discount_code_2T", value: "2T" },
					{ label: "groups_onss_discount_code_2U", value: "2U" },
					{ label: "groups_onss_discount_code_2V", value: "2V" },
					{ label: "groups_onss_discount_code_2W", value: "2W" },
					{ label: "groups_onss_discount_code_2X", value: "2X" },
					{ label: "groups_onss_discount_code_2Y", value: "2Y" },
					{ label: "groups_onss_discount_code_2Z", value: "2Z" },
					{ label: "groups_onss_discount_code_3", value: "3" },
					{ label: "groups_onss_discount_code_4A", value: "4A" },
					{ label: "groups_onss_discount_code_4B", value: "4B" },
					{ label: "groups_onss_discount_code_4X", value: "4X" },
					{ label: "groups_onss_discount_code_4Y", value: "4Y" },
					{ label: "groups_onss_discount_code_4Z", value: "4Z" },
					{ label: "groups_onss_discount_code_5", value: "5" },
					{ label: "groups_onss_discount_code_6", value: "6" },
					{ label: "groups_onss_discount_code_6A", value: "6A" },
					{ label: "groups_onss_discount_code_6B", value: "6B" },
					{ label: "groups_onss_discount_code_7", value: "7" },
					{ label: "groups_onss_discount_code_8", value: "8" },
					{ label: "groups_onss_discount_code_8A", value: "8A" },
					{ label: "groups_onss_discount_code_9", value: "9" },
					{ label: "groups_onss_discount_code_A", value: "A" },
					{ label: "groups_onss_discount_code_B", value: "B" },
					{ label: "groups_onss_discount_code_C", value: "C" },
					{ label: "groups_onss_discount_code_D", value: "D" },
					{ label: "groups_onss_discount_code_E", value: "E" },
					{ label: "groups_onss_discount_code_F", value: "F" },
					{ label: "groups_onss_discount_code_G", value: "G" },
					{ label: "groups_onss_discount_code_H", value: "H" },
					{ label: "groups_onss_discount_code_I", value: "I" },
					{ label: "groups_onss_discount_code_J", value: "J" },
					{ label: "groups_onss_discount_code_J1", value: "J1" },
					{ label: "groups_onss_discount_code_K", value: "K" },
					{ label: "groups_onss_discount_code_L", value: "L" },
					{ label: "groups_onss_discount_code_M", value: "M" },
					{ label: "groups_onss_discount_code_N", value: "N" },
					{ label: "groups_onss_discount_code_NP", value: "NP" },
					{ label: "groups_onss_discount_code_P", value: "P" },
					{ label: "groups_onss_discount_code_Q", value: "Q" },
					{ label: "groups_onss_discount_code_R", value: "R" },
					{ label: "groups_onss_discount_code_R1", value: "R1" },
					{ label: "groups_onss_discount_code_S", value: "S" },
					{ label: "groups_onss_discount_code_T", value: "T" },
					{ label: "groups_onss_discount_code_T1", value: "T1" },
					{ label: "groups_onss_discount_code_U", value: "U" },
					{ label: "groups_onss_discount_code_V", value: "V" },
					{ label: "groups_onss_discount_code_W", value: "W" },
					{ label: "groups_onss_discount_code_X", value: "X" },
					{ label: "groups_onss_discount_code_Y", value: "Y" },
					{ label: "groups_onss_discount_code_Z", value: "Z" },
				],
			},
			{
				label: "start_date_onss_discount", //  To be displayed only if a value is chosen in CRO. Should be in ONSS
				inputType: "datepicker",
				required: true,
				show: values => values["onss_discount_code"],
			},
			{
				label: "job_promotion",
				inputType: "select",
				required: false,
				options: [
					{ label: "groups_job_promotion_0", value: "0" },
					{ label: "groups_job_promotion_1", value: "1" },
					{ label: "groups_job_promotion_2", value: "2" },
					{ label: "groups_job_promotion_3", value: "3" },
					{ label: "groups_job_promotion_4", value: "4" },
					{ label: "groups_job_promotion_5", value: "5" },
					{ label: "groups_job_promotion_10", value: "10" },
					{ label: "groups_job_promotion_11", value: "11" },
					{ label: "groups_job_promotion_12", value: "12" },
					{ label: "groups_job_promotion_13", value: "13" },
					{ label: "groups_job_promotion_14", value: "14" },
					{ label: "groups_job_promotion_15", value: "15" },
					{ label: "groups_job_promotion_16", value: "16" },
					{ label: "groups_job_promotion_17", value: "17" },
					{ label: "groups_job_promotion_18", value: "18" },
					{ label: "groups_job_promotion_19", value: "19" },
					{ label: "groups_job_promotion_21", value: "21" },
					{ label: "groups_job_promotion_22", value: "22" },
					{ label: "groups_job_promotion_23", value: "23" },
					{ label: "groups_job_promotion_24", value: "24" },
				],
			},
			{
				label: "work_allowance", //  To be displayed only if a value is chosen in CRO. Should be in ONSS
				inputType: "select",
				required: false,
				options: [
					{ label: "groups_impulsion_less_25", value: "13" },
					{ label: "groups_impulsion_12_plus_months", value: "14" },
					{ label: "groups_impulsion_insertion", value: "15" },
					{ label: "groups_activa_312_days", value: "16" },
					{ label: "groups_activa_apt_reduite", value: "17" },
				],
			},
			{
				label: "work_allowance_start_date", //  To be displayed only if a value is chosen in CRO. Should be in ONSS
				inputType: "datepicker",
				required: false,
				show: values => values["work_allowance"],
			},
		],
	},
	{
		categoryName: "groups_precompte_professionnel",
		inputs: [
			{
				label: "worker_disabled",
				inputType: "select",
				required: false,
				options: [
					{ label: "no", value: "false" },
					{ label: "yes", value: "true" },
				],
			},
			{
				label: "child_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_child_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "other_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_other_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "over_65_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_over_65_dependents_number",
				inputType: "text",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_bilan_social",
		inputs: [
			{
				label: "education_level",
				inputType: "select",
				required: false,
				options: [
					{ label: "education_primary", value: "1" }, // etudes primaires
					{ label: "education_secondary", value: "2" }, // etudes secondaires
					{ label: "education_superior", value: "3" }, // etudes supérieures non universitaires
					{ label: "education_univeristy", value: "4" }, // etudes universitaires
				],
			},
		],
	},
	{
		categoryName: "groups_divers",
		inputs: [
			{
				label: "community",
				inputType: "select",
				required: false,
				options: [
					{ label: "community_nd", value: "0" },
					{ label: "community_french", value: "1" },
					{ label: "community_dutch", value: "2" },
					{ label: "community_german", value: "3" },
				],
			},
		],
	},
	{
		categoryName: "groups_regime_de_travail",
		inputs: [],
	},
	{
		categoryName: "groups_anciennete",
		inputs: [
			{
				label: "first_entry_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "seniority_start_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "baremic_seniority_start_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_end_of_contract_dates",
		show: values => values.endDate && values.endDate.isValid(),
		inputs: [
			{
				label: "notice_notification_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "notice_start_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_allocations_familiales",
		inputs: [],
	},
	{
		categoryName: "groups_social_benefits",
		inputs: [
			{
				label: "children_family_allowance",
				inputType: "text",
				required: false,
			},
		],
	},
	{
		categoryName: "schedule",
		inputs: [
			{
				label: "work_interruption",
				inputType: "select",
				required: false,
				options: [
					{ label: "credit_temps", value: "01" },
					{ label: "conge_parental", value: "02" },
					//{ label: "conge_parental_cct64", value: "03" },
					{ label: "soins_palliatifs", value: "04" },
					{ label: "reprise_partielle", value: "05" },
					{ label: "soins_grand_malade", value: "07" },
					{ label: "resume_after_sickness_new_employer", value: "16" },
					{ label: "conge_parental_corona", value: "19" },
				],
			},
			{
				label: "work_interruption_start_date",
				inputType: "datepicker",
				required: false,
				show: values => !!values.work_interruption,
			},
			{
				label: "work_interruption_end_date",
				inputType: "datepicker",
				required: false,
				show: values => !!values.work_interruption,
			},
			{
				label: "work_interruption_percentage",
				inputType: "text",
				required: false,
				show: values => !!values.work_interruption,
			},
			{
				label: "mutual_insurance_approval_date",
				inputType: "datepicker",
				required: false,
				show: values =>
					!!values.work_interruption &&
					(values.work_interruption === "16" || values.work_interruption === "05"),
			},
			{
				label: "sickness_start_date",
				inputType: "datepicker",
				required: false,
				show: values => !!values.work_interruption && values.work_interruption === "16",
			},
			{
				label: "motif_type",
				inputType: "select",
				required: false,
				options: [
					{ label: "without_motif_type", value: "0" },
					{ label: "with_motif_type", value: "1" },
				],
				show: values => !!values.work_interruption && values.work_interruption === "01",
			},
			{
				label: "guaranteed_salary",
				inputType: "select",
				required: false,
				options: [
					{ label: "without_guaranteed_salary", value: "0" },
					{ label: "with_guaranteed_salary", value: "1" },
				],
				show: values => !!values.work_interruption && values.work_interruption === "05",
			},
		],
	},
]

function end_reason_required(affiliate, values, currentContract, workerContracts) {
	if (affiliate.secSocName === "Group S" || affiliate.secSocName === "Securex") {
		let hasEndDate = values.endDate && values.endDate.isValid()
		let childContract = workerContracts.find(e => e.parentID === currentContract.id)
		if (hasEndDate && !childContract) {
			return true
		}
	}
	return false
}
