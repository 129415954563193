import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"

import * as actions from "../actions"
import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"
import PSVScanningZone from "./PSVScanningZone"
import { PootsyButton, NoCSSInfoBox } from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsyFileInput } from "./FunctionalInputs"

class PSVScanner extends Component {
	state = {
		includeExpiredVouchers: false,
		refusedVisible: false,
		expiredVisible: false,
		alreadyAttributedVisible: false,
		knownVisible: false,
		createdVisible: false,
		linkedVisible: false,
	}

	componentWillUnmount = () => {
		this.props.dispatch(actions.resetPSVScannerState())
	}
	showRefused = () => {
		this.setState({ refusedVisible: !this.state.refusedVisible })
	}
	showExpired = () => {
		this.setState({ expiredVisible: !this.state.expiredVisible })
	}
	showAlreadyAttributed = () => {
		this.setState({ alreadyAttributedVisible: !this.state.alreadyAttributedVisible })
	}
	showKnown = () => {
		this.setState({ knownVisible: !this.state.knownVisible })
	}
	showCreated = () => {
		this.setState({ createdVisible: !this.state.createdVisible })
	}
	showLinked = () => {
		this.setState({ linkedVisible: !this.state.linkedVisible })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.PSVScannerStateChange(changes))
	}
	toggleIncludeExpiredVouchers = () => {
		this.setState({ includeExpiredVouchers: !this.state.includeExpiredVouchers })
	}
	handleNewCode = code => {
		let { scannedCodes } = this.props.component
		code = code.substring(0, 33)
		this.handleChanges({
			scannedCodes: [...scannedCodes, { code }],
			showPSVLinkingReportModal: false, // sentry bug #1047722992
		})
		this.checkPSV()
		this.codesZone.scrollTop = this.codesZone.scrollHeight
	}
	closeShowPSVLinkingReportModal = () => {
		this.handleChanges({ showPSVLinkingReportModal: false })
	}
	removeScannedCode = e => {
		let { index } = e.target.dataset
		const scannedCodes = [...this.props.component.scannedCodes]
		scannedCodes.splice(index, 1)
		this.handleChanges({ scannedCodes })
	}
	checkPSV = _.debounce(() => {
		let {
			dispatch,
			workerSodexoRef,
			scanningFor: subjectType,
			customerSodexoRef,
			subjectID,
		} = this.props
		dispatch(
			actions.checkSodexoPSV({
				subjectType,
				workerSodexoRef,
				customerSodexoRef,
				subjectID,
				includeExpiredVouchers: this.state.includeExpiredVouchers,
			})
		)
	}, 1000)
	sendPsv = async () => {
		let {
			dispatch,
			workerSodexoRef,
			scanningFor: subjectType,
			customerSodexoRef,
			subjectID,
			afterSubmitAction,
			component: { importFile },
		} = this.props

		if (importFile && importFile.file) {
			this.sendSodexoCSV()
		} else {
			let { actionType } = await dispatch(
				actions.sendSodexoPSV({
					subjectType,
					workerSodexoRef,
					customerSodexoRef,
					subjectID,
					includeExpiredVouchers: this.state.includeExpiredVouchers,
				})
			)
			if (actionType === actions.SEND_SODEXO_PSV_SUCCESS) {
				this.handleChanges({ scannedCodes: [] })
				if (afterSubmitAction) dispatch(afterSubmitAction())
			}
		}
	}
	sendSodexoCSV = async () => {
		let { afterSubmitAction, dispatch } = this.props
		let { actionType } = await dispatch(
			actions.sendSodexoCSV(this.state.includeExpiredVouchers)
		)
		if (actionType === actions.SEND_SODEXO_PSV_SUCCESS) {
			this.handleChanges({
				scannedCodes: [],
				showPSVLinkingReportModal: true,
				importFile: "",
			})
			if (afterSubmitAction) dispatch(afterSubmitAction())
		}
	}

	render() {
		let { t } = this.context
		let {
			hideSendButton,
			counter,
			component: {
				scannedCodes,
				isSendingPSV,
				showPSVLinkingReportModal,
				PSVLinkingReport,
				importFile,
			},
		} = this.props
		return (
			<div className="PSVScanner">
				<PootsyCheckboxInput
					name="include_expired"
					label={t("include_expired_vouchers")}
					checked={this.state.includeExpiredVouchers}
					onChange={this.toggleIncludeExpiredVouchers}
				/>
				<PSVScanningZone onScan={this.handleNewCode} />
				{scannedCodes.length > 0 && (
					<div className="scanned-codes-meta">
						<NoCSSInfoBox
							label={t("scanned_codes_count")}
							value={scannedCodes.length}
						/>
						<NoCSSInfoBox
							label={t("valid_scanned_codes_count")}
							value={scannedCodes.filter(entry => entry.valid).length}
						/>
						<NoCSSInfoBox
							label={t("invalid_scanned_codes_count")}
							value={scannedCodes.filter(entry => !entry.valid).length}
						/>
						<NoCSSInfoBox
							label={t("expired_scanned_codes_count")}
							value={scannedCodes.filter(entry => entry.expired).length}
						/>
					</div>
				)}
				<div className="scanned-codes" ref={el => (this.codesZone = el)}>
					{scannedCodes.map((entry, index) => (
						<div key={index} className="scanned-code">
							<div className="code">{entry.code}</div>
							<div className="message">{t(entry.message)}</div>
							<div
								className="remove-button"
								data-index={index}
								onClick={this.removeScannedCode}
							>
								x
							</div>
						</div>
					))}
				</div>
				<div className="buttons">
					{this.props.showImportFile && (
						<InputsChangeHandler onChange={this.handleChanges}>
							<PootsyFileInput name="importFile" label={t("upload_psv_input")} />
							{importFile && (
								<div className="current-import-file-name">
									{importFile.filename}
								</div>
							)}
						</InputsChangeHandler>
					)}
					{!hideSendButton && (
						<PootsyButton
							text={t("send_psv")}
							onClick={this.sendPsv}
							fetching={isSendingPSV}
						/>
					)}
					{counter && (
						<div className="counter">
							{scannedCodes.length} / {counter}
						</div>
					)}
				</div>
				{showPSVLinkingReportModal && (
					<ModalLayout
						title={t("send_psv_report_title")}
						closeModal={this.closeShowPSVLinkingReportModal}
						formDivClass="psv-linking-report-modal"
					>
						<div className="report-detail voucher-count">
							<div className="label">{t("send_psv_report_vouchers_count")}:</div>
							<div className="value">{PSVLinkingReport.vouchers_count}</div>
						</div>
						<div className="report-detail" onClick={this.showLinked}>
							<div className="label">{t("send_psv_report_vouchers_linked")}:</div>
							<div className="value">
								{PSVLinkingReport.psvs.filter(entry => entry.auto_linked).length}
							</div>
						</div>
						{this.state.linkedVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.auto_linked)
									.map(record => (
										<div key={record.niss}>
											{record.code} - {t(record.message)}
										</div>
									))}
							</div>
						)}
						<div className="report-detail" onClick={this.showCreated}>
							<div className="label">{t("send_psv_report_vouchers_to_link")}:</div>
							<div className="value">
								{
									PSVLinkingReport.psvs.filter(
										entry => entry.created && !entry.auto_linked
									).length
								}
							</div>
						</div>
						{this.state.createdVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.created && !entry.auto_linked)
									.map(record => (
										<div key={record.niss}>
											{record.code} - {t(record.message)}
										</div>
									))}
							</div>
						)}
						<div className="report-detail" onClick={this.showKnown}>
							<div className="label">{t("send_psv_report_known_vouchers")}:</div>
							<div className="value">
								{PSVLinkingReport.psvs.filter(entry => entry.known).length}
							</div>
						</div>
						{this.state.knownVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.known)
									.map(record => (
										<div key={record.niss}>
											{record.code} - {t(record.message)}
										</div>
									))}
							</div>
						)}
						<div className="report-detail" onClick={this.showAlreadyAttributed}>
							<div className="label">
								{t("send_psv_report_already_attributed_vouchers")}:
							</div>
							<div className="value">
								{
									PSVLinkingReport.psvs.filter(entry => entry.already_attributed)
										.length
								}
							</div>
						</div>
						{this.state.alreadyAttributedVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.already_attributed)
									.map(record => (
										<div key={record.niss}>
											{record.code} - {t(record.message)}
										</div>
									))}
							</div>
						)}
						<div className="report-detail" onClick={this.showExpired}>
							<div className="label">{t("send_psv_report_vouchers_expired")}:</div>
							<div className="value">
								{PSVLinkingReport.psvs.filter(entry => entry.expired).length}
							</div>
						</div>
						{this.state.expiredVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.expired)
									.map(record => (
										<div key={record.niss}>{record.code}</div>
									))}
							</div>
						)}
						<div className="report-detail" onClick={this.showRefused}>
							<div className="label">{t("send_psv_report_refused_vouchers")}:</div>
							<div className="value">
								{PSVLinkingReport.psvs.filter(entry => entry.refused).length}
							</div>
						</div>
						{this.state.refusedVisible && (
							<div className="report-detail-detail">
								{PSVLinkingReport.psvs
									.filter(entry => entry.refused)
									.map(record => (
										<div key={record.niss}>
											{record.code} - {t(record.message)}
										</div>
									))}
							</div>
						)}
					</ModalLayout>
				)}
			</div>
		)
	}
}

PSVScanner.propTypes = {
	scanningFor: PropTypes.string.isRequired,
}
PSVScanner.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.PSVScannerComponent,
})
export default connect(mapStateToProps)(PSVScanner)
