import "jspdf-autotable"
import { denormalizeImpactedBookingActions, simpleFetch } from "./index"
import { stringifyQuery } from "../helpers/qs"
import jsPDF from "jspdf"
import "jspdf-autotable"
import moment from "moment/moment"

/// action creators

export const GROUP_TRAININGS_STATE_CHANGE = "GROUP_TRAININGS_STATE_CHANGE"
export const groupTrainingsStateChange = changes => ({
	type: GROUP_TRAININGS_STATE_CHANGE,
	changes,
})

export const SINGULAR_TRAININGS_STATE_CHANGE = "SINGULAR_TRAININGS_STATE_CHANGE"
export const singularTrainingsStateChange = changes => ({
	type: SINGULAR_TRAININGS_STATE_CHANGE,
	changes,
})

export const TRAININGS_PAGE_STATE_CHANGE = "TRAININGS_PAGE_STATE_CHANGE"
export const trainingsPageStateChange = changes => ({ type: TRAININGS_PAGE_STATE_CHANGE, changes })

export const CREATETRAINING = "CREATETRAINING"
export const CREATETRAINING_REQUEST = "CREATETRAINING_REQUEST"
export const CREATETRAINING_SUCCESS = "CREATETRAINING_SUCCESS"
export const CREATETRAINING_FAILURE = "CREATETRAINING_FAILURE"

export const UPDATESINGULARTRAINING_REQUEST = "UPDATESINGULARTRAINING_REQUEST"
export const UPDATESINGULARTRAINING_SUCCESS = "UPDATESINGULARTRAINING_SUCCESS"
export const UPDATESINGULARTRAINING_FAILURE = "UPDATESINGULARTRAINING_FAILURE"

export const DELETESINGULARTRAINING_REQUEST = "DELETESINGULARTRAINING_REQUEST"
export const DELETESINGULARTRAINING_SUCCESS = "DELETESINGULARTRAINING_SUCCESS"
export const DELETESINGULARTRAINING_FAILURE = "DELETESINGULARTRAINING_FAILURE"

export const UPDATEGROUPTRAINING_REQUEST = "UPDATEGROUPTRAINING_REQUEST"
export const UPDATEGROUPTRAINING_SUCCESS = "UPDATEGROUPTRAINING_SUCCESS"
export const UPDATEGROUPTRAINING_FAILURE = "UPDATEGROUPTRAINING_FAILURE"

export const DELETEGROUPTRAINING_REQUEST = "DELETEGROUPTRAINING_REQUEST"
export const DELETEGROUPTRAINING_SUCCESS = "DELETEGROUPTRAINING_SUCCESS"
export const DELETEGROUPTRAINING_FAILURE = "DELETEGROUPTRAINING_FAILURE"

export const FETCH_ALL_TRAININGS = "FETCH_ALL_TRAININGS"
export const FETCH_ALL_TRAININGS_REQUEST = "FETCH_ALL_BOOKINGS_REQUEST"
export const FETCH_ALL_TRAININGS_SUCCESS = "FETCH_ALL_BOOKINGS_SUCCESS"
export const FETCH_ALL_TRAININGS_FAILURE = "FETCH_ALL_BOOKINGS_FAILURE"
export const createTrainingsListPDF = (translate, grouped = null) => async (dispatch, getState) => {
	let {
		redComponents: { singularTrainingsPage, groupTrainingsPage },
	} = getState()
	const attributes = grouped ? groupTrainingsPage : singularTrainingsPage
	let params = {
		start_date: attributes.rangeStart ? attributes.rangeStart.format("YYYY-MM-DD") : null,
		end_date: attributes.rangeEnd ? attributes.rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: attributes.searchedAffiliateWorkerId,
		code: attributes.selectedCode,
		...(grouped && { grouped }),
	}
	let url = "/front/affiliates/trainings/list" + stringifyQuery(params)
	let init = { method: "GET" }
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCH_ALL_TRAININGS))
	console.log(data, actionType)

	if (actionType === "FETCH_ALL_TRAININGS_SUCCESS") {
		let columns = [
			{ header: translate("date"), dataKey: "start_date" },
			{ header: translate("start_time"), dataKey: "start_time" },
			{ header: translate("end_time"), dataKey: "end_time" },
			{ header: translate("activity_code"), dataKey: "ss_code" },
			{ header: translate("activity_code_label"), dataKey: "training_code_label" },
		]
		if (grouped) {
			columns.splice(1, 0, {
				header: translate("workers"),
				dataKey: "workers_display_names_list",
			})
		} else {
			columns.splice(1, 0, { header: translate("worker"), dataKey: "worker_display_name" })
		}
		let trainingsListForPDF = {
			showHead: "firstPage",
			margin: { top: 5, left: 5, bottom: 5, right: 5 },
			styles: {
				fontSize: 9,
				cellPadding: 1,
				halign: "left",
				valign: "middle",
				cellWidth: "wrap",
			},
			columns: columns,
			body: data.data.map(entry => ({
				start_date: entry.attributes.start_date,
				workers_display_names_list: entry.attributes.workers_display_names_list,
				worker_display_name: entry.attributes.worker_display_name,
				start_time: entry.attributes.formated_start_time,
				end_time: entry.attributes.formated_end_time,
				ss_code: entry.attributes.ss_code,
				training_code_label: translate(entry.attributes.training_code_label),
			})),
		}
		let doc = new jsPDF("landscape")
		doc.text(`${moment().format("DD/MM/YY")} ${translate("trainings_list_pdf_header")}`, 5, 10)
		doc.autoTable(trainingsListForPDF)
		doc.save("trainings_list.pdf")
	}
}

export const FETCHSINGULARTRAININGS_REQUEST = "FETCHSINGULARTRAININGS_REQUEST"
export const FETCHSINGULARTRAININGS_SUCCESS = "FETCHSINGULARTRAININGS_SUCCESS"
export const FETCHSINGULARTRAININGS_FAILURE = "FETCHSINGULARTRAININGS_FAILURE"
export const fetchSingularTrainings = () => (dispatch, getState) => {
	let {
		redComponents: {
			singularTrainingsPage: {
				currentPage,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorkerId,
				selectedCode,
			},
		},
	} = getState()
	let params = {
		page: currentPage,
		start_date: rangeStart ? rangeStart.format("YYYY-MM-DD") : null,
		end_date: rangeEnd ? rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: searchedAffiliateWorkerId,
		code: selectedCode,
	}

	dispatch(
		simpleFetch(
			"/front/affiliates/trainings" + stringifyQuery(params),
			{ method: "GET" },
			"FETCHSINGULARTRAININGS"
		)
	)
}

export const FETCHGROUPTRAININGS_REQUEST = "FETCHGROUPTRAININGS_REQUEST"
export const FETCHGROUPTRAININGS_SUCCESS = "FETCHGROUPTRAININGS_SUCCESS"
export const FETCHGROUPTRAININGS_FAILURE = "FETCHGROUPTRAININGS_FAILURE"
export const fetchGroupTrainings = () => (dispatch, getState) => {
	let {
		redComponents: {
			groupTrainingsPage: {
				currentPage,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorkerId,
				selectedCode,
			},
		},
	} = getState()
	let params = {
		grouped: true,
		page: currentPage,
		start_date: rangeStart ? rangeStart.format("YYYY-MM-DD") : null,
		end_date: rangeEnd ? rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: searchedAffiliateWorkerId,
		code: selectedCode,
	}

	dispatch(
		simpleFetch(
			"/front/affiliates/trainings" + stringifyQuery(params),
			{ method: "GET" },
			"FETCHGROUPTRAININGS"
		)
	)
}

export const submitTraining = () => (dispatch, getState) => {
	let {
		redData: { impactedBookings },
		redComponents: {
			trainingsPage,
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()

	let affiliate_worker = trainingsPage.selectedWorkers[trainingsPage.workerIndex]

	if (affiliate_worker) {
		let affiliate_worker_id = affiliate_worker.id

		let url = "/front/affiliates/worker_contracts/0/other_activities"

		let attributes = {
			affiliate_worker_id: affiliate_worker_id,
			affiliate_worker_ids: trainingsPage.selectedWorkers.map(worker => worker.id),
			start_date: trainingsPage.trainingStart.format("YYYY-MM-DD"),
			end_date: trainingsPage.trainingEnd.format("YYYY-MM-DD"),
			start_time: trainingsPage.trainingStart.format("HH:mm"),
			end_time: trainingsPage.trainingEnd.format("HH:mm"),
			affiliate_note: trainingsPage.trainingNote,
			activity_code: trainingsPage.activityCode,
			activity_category: trainingsPage.activityCategory,
			force_unlink: forceUnlink,
			impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		}

		let init = {
			method: "POST",
			body: JSON.stringify(attributes),
		}

		dispatch(simpleFetch(url, init, CREATETRAINING))
	} else {
		dispatch(trainingsPageStateChange({ workerIndex: 0 }))
		const currentPath = window.location.pathname
		if (currentPath.endsWith("/group_trainings")) {
			dispatch(fetchGroupTrainings())
		} else if (currentPath.endsWith("/singular_trainings")) {
			dispatch(fetchSingularTrainings())
		}
	}
}
