import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import { PootsyButton, FlexLineBreak, PootsySubHeading } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import {
	PootsyTextInput,
	PootsyRadioInputs,
	PootsySelectInput,
	PootsyCheckboxInput,
	PootsyTextArea,
} from "./FunctionalInputs"

class UpdateResidenceModal extends Component {
	constructor(props) {
		super(props)
		let {
			id,
			attributes: {
				street_name,
				street_number,
				street_box,
				town,
				zip_code,
				pets,
				surface,
				residence_type,
				how_to_get_in,
				other_info,
				country,
				deleted,
			},
		} = props.residence
		// Sometimes pets is null
		pets = pets || false
		this.state = {
			id: id,
			street: street_name,
			streetNumber: street_number,
			box: street_box,
			zip: zip_code,
			city: town,
			country: country,
			residence: residence_type,
			surface: surface,
			howToGetIn: how_to_get_in,
			otherInfo: other_info,
			customerPets: pets.toString(),
			deleted: deleted,
		}
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	submit = () => {
		let { dispatch, onCancel, customerId } = this.props
		let {
			id,
			street,
			streetNumber,
			box,
			zip,
			city,
			country,
			residence,
			surface,
			customerPets,
			howToGetIn,
			otherInfo,
			deleted,
		} = this.state
		let init = {
			method: "PUT",
			body: JSON.stringify({
				residence_type: residence,
				surface: surface,
				pets: customerPets,
				street_name: street,
				street_number: streetNumber,
				street_box: box,
				zip_code: zip,
				town: city,
				country: country,
				customer_id: customerId,
				how_to_get_in: howToGetIn,
				other_info: otherInfo,
				deleted: deleted,
			}),
		}
		let url = "/front/affiliates/customers/residences/" + id
		dispatch(actions.simpleFetch(url, init, "UPDATERESIDENCE"))
		onCancel()
	}
	toggleActiveResidence = () => {
		this.setState(prevState => ({ deleted: !prevState.deleted }))
	}
	render() {
		let { t } = this.context
		let { onCancel } = this.props
		let {
			street,
			streetNumber,
			box,
			zip,
			city,
			country,
			residence,
			surface,
			customerPets,
			howToGetIn,
			otherInfo,
			deleted,
		} = this.state

		let buttons = [
			<PootsyButton key="a" text={t("confirm")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={onCancel} />,
		]
		return (
			<InputsChangeHandler setParentState={this.setState}>
				<ModalLayout
					title={t("update_residence_modal_title")}
					buttons={buttons}
					closeModal={onCancel}
					formDivClass="update-residence-modal"
				>
					<FlexLineBreak />
					<PootsyCheckboxInput
						name="deleted"
						label={t("deactivated")}
						checked={deleted}
						onChange={this.toggleActiveResidence}
					/>
					<FlexLineBreak />
					<PootsySubHeading text={t("residence_details")} />
					<FlexLineBreak />
					<PootsySelectInput
						name="residence"
						defaultText={t("residence_type")}
						selected={residence}
						options={[
							{ label: t("apartment"), value: "apartment" },
							{ label: t("house"), value: "house" },
							{ label: t("other"), value: "other" },
						]}
					/>
					<PootsyTextInput
						name="surface"
						type="number"
						label={t("surface")}
						value={surface}
					/>
					<PootsyRadioInputs
						groupName="customerPets"
						groupLabel={t("pets")}
						selected={customerPets}
						options={[
							{ id: "radio-pets", value: "true", label: t("yes") },
							{ id: "radio-no-pets", value: "false", label: t("no") },
						]}
					/>
					<FlexLineBreak />
					<PootsySubHeading text={t("address")} />
					<FlexLineBreak />
					<PootsyAddressBlockInputs
						setValues={this.setState}
						values={{
							city: city,
							zip: zip,
							box: box,
							streetNumber: streetNumber,
							street: street,
							country: country,
						}}
					/>
					<PootsyTextArea
						name="howToGetIn"
						label={t("how_to_get_in")}
						value={howToGetIn}
					/>
					<PootsyTextArea name="otherInfo" label={t("other_info")} value={otherInfo} />
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

UpdateResidenceModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(UpdateResidenceModal)
