import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import ModalLayout from "./ModalLayout"
import PootsyCalendar from "./PootsyCalendar"
import { PootsySubHeading, PootsyButton } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsyCheckboxInput, PootsyTextInput } from "./FunctionalInputs"

class TimesheetExportModal extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.timesheetExportModalStateChange(changes))
	}

	handleFilterBySquadChange = e => {
		if (!e.target.checked) this.handleChanges({ squadsSelected: {} })
		this.handleChanges({ filterBySquad: e.target.checked, workersToExport: [] })
	}

	handleSquadsSelectedChange = e => {
		const { name, checked } = e.target

		let {
			component: { squadsSelected, timesheetsBySquad, workersToExport },
		} = this.props

		if (!checked) {
			const workerContractIds = timesheetsBySquad[name].allSquadTimesheets.map(
				entry => entry.worker_contract_id
			)

			this.handleChanges({
				workersToExport: workersToExport.filter(
					entry => !workerContractIds.includes(entry)
				),
			})
		}
		this.handleChanges({
			squadsSelected: {
				...squadsSelected,
				[name]: checked,
			},
		})
	}

	handleAutoExportSendingChanges = e => {
		this.handleChanges({ sendExportToSecSocAutomatically: e.target.checked })
	}

	handleIsSplittedPerSquad = e => {
		this.handleChanges({ isSplittedPerSquad: e.target.checked })
	}

	selectExportPeriod = e => {
		e.stopPropagation()
		this.handleChanges({ selectedWeek: e.target.value, customExportDate: undefined })
	}
	selectExportType = e => {
		e.stopPropagation()
		this.handleChanges({ selectedExportType: e.target.value, workersToExport: [] })
	}
	handleWorkerToExportSelection = e => {
		e.stopPropagation()
		let { name, checked } = e.target
		let {
			component: { workersToExport },
		} = this.props
		let workerID = Number(name.split("::")[1])
		if (checked) {
			this.handleChanges({ workersToExport: [...workersToExport, workerID] })
			return
		}
		this.handleChanges({ workersToExport: workersToExport.filter(entry => entry !== workerID) })
	}
	timesheetIsExportable = entry => {
		let {
			component: { customExportDate },
		} = this.props
		return (
			entry.isClosed ||
			(customExportDate &&
				entry.intermediateClosureDates.some(
					icd =>
						icd.isSameOrAfter(customExportDate) || icd.isSameOrAfter(entry.contractEnd)
				))
		)
	}

	checkImportType = entry => {
		let {
			component: { selectedExportType },
		} = this.props
		if (selectedExportType && entry.exportTypes && entry.exportTypes.length > 0) {
			return entry.exportTypes.includes(selectedExportType)
		}
		return true
	}

	stageAllClosedTimesheetsForExport = isExportedAt => {
		let {
			component: { customExportDate, workersToExport: currentWorkersToExport },
			timesheetsComponent: { allTimesheets },
		} = this.props
		let workersToExport = []
		allTimesheets.forEach(entry => {
			if (
				entry.isClosed ||
				(customExportDate &&
					entry.intermediateClosureDates.some(
						entry =>
							entry.isSameOrAfter(customExportDate) ||
							entry.isSameOrAfter(entry.contractEnd)
					))
			) {
				if (isExportedAt && !!entry.exportedAt && this.checkImportType(entry))
					workersToExport.push(entry.worker_contract_id)
				if (!isExportedAt && (!entry.exportedAt || !this.checkImportType(entry)))
					workersToExport.push(entry.worker_contract_id)
			}
		})
		if (currentWorkersToExport.length === workersToExport.length) {
			workersToExport = []
		}
		this.handleChanges({ workersToExport })
	}
	getLoginFromCookie = () => {
		let login = null
		document.cookie.split("; ").forEach(value => {
			let [cookieName, cookieValue] = value.split("=")
			if (cookieName === "login") login = cookieValue
		})
		return login
	}
	stageAllUpfrontPaymentsForExport = () => {
		let {
			component: { workersToExport: currentWorkersToExport },
			timesheetsComponent: { allTimesheets },
		} = this.props
		let workersToExport = []
		allTimesheets.forEach(entry => {
			if (entry.needsUpfrontPayment && this.timesheetIsExportable(entry)) {
				workersToExport.push(entry.worker_contract_id)
			}
		})
		if (currentWorkersToExport.length === workersToExport.length) {
			workersToExport = []
		}
		this.handleChanges({ workersToExport })
	}
	stageAllTimesheetsNeedingCorrectionForExport = () => {
		let {
			component: { workersToExport: currentWorkersToExport },
			timesheetsComponent: { timesheetsNeedingCorrection },
		} = this.props
		let workersToExport = timesheetsNeedingCorrection
			.filter(entry => entry.isClosed)
			.map(entry => entry.worker_contract_id)
		if (currentWorkersToExport.length === workersToExport.length) {
			workersToExport = []
		}
		this.handleChanges({ workersToExport })
	}
	stageAllTimesheetsClosedAfterLastTsc = () => {
		let {
			component: { workersToExport: currentWorkersToExport },
			timesheetsComponent: { timesheetsClosedAfterLastTsc },
		} = this.props
		let workersToExport = timesheetsClosedAfterLastTsc
			.filter(entry => entry.isClosed)
			.map(entry => entry.worker_contract_id)
		if (currentWorkersToExport.length === workersToExport.length) {
			workersToExport = []
		}
		this.handleChanges({ workersToExport })
	}
	pushIntoSquad = (entry, splitWorkersToExport) => {
		let {
			component: { squadsSelected, filterBySquad },
		} = this.props
		let squadId = entry.squad_id
		if ((!filterBySquad || squadsSelected[squadId]) && !splitWorkersToExport[squadId]) {
			splitWorkersToExport[squadId] = []
		}
		splitWorkersToExport[squadId].push(entry.worker_contract_id)
	}
	getTimesheetCounts = (timesheetsBySquad, squadId) => {
		const allTimesheetsCount = timesheetsBySquad[squadId].allSquadTimesheets.length

		const sentCount = timesheetsBySquad[squadId].allExportedTimesheets.length
		const closedCount = timesheetsBySquad[squadId].allClosedTimesheets.length

		return {
			sentCount: `${sentCount}/${allTimesheetsCount}`,
			closedCount: `${closedCount}/${allTimesheetsCount}`,
		}
	}

	renderTimesheetCounts = (timesheetsBySquad, squadId) => {
		const { sentCount, closedCount } = this.getTimesheetCounts(timesheetsBySquad, squadId)
		return (
			<div className="squad-statistics">
				<div>Sent: {sentCount}</div>
				<div>Closed: {closedCount}</div>
			</div>
		)
	}
	stageAllTimesheetSplittedPerSquadForExportAndSubmit = async () => {
		let {
			closeModal,
			dispatch,
			timesheetsComponent: { allTimesheets },
		} = this.props
		let splitWorkersToExport = {}
		allTimesheets.forEach(entry => {
			if (entry.isClosed) {
				this.pushIntoSquad(entry, splitWorkersToExport)
			}
		})
		console.error("workersToExport", splitWorkersToExport)
		for (const squadId in splitWorkersToExport) {
			if (splitWorkersToExport.hasOwnProperty(squadId)) {
				const workersArray = splitWorkersToExport[squadId]
				await this.submitSplittedPerSquadExport(workersArray)
			}
		}
		closeModal()
		await dispatch(actions.fetchTimesheets())
	}

	requestWorkerMonthExport = () => {
		let { dispatch, timesheetsComponent, selectedExportType, isSplittedPerSquad } = this.props
		let query = {
			for_month: timesheetsComponent.selectedMonth.format(),
			worker_contracts: timesheetsComponent.selectedWorker,
			export_type: selectedExportType,
			is_splitted_per_squad: isSplittedPerSquad,
		}
		dispatch(actions.requestTimesheetDataExport(query, true))
	}
	submitTimesheetExport = () => {
		let {
			dispatch,
			closeModal,
			component: {
				sendExportToSecSocAutomatically,
				selectedWeek,
				workersToExport,
				customExportDate,
				selectedExportType,
				socSecLogin,
				socSecPassword,
				isSplittedPerSquad,
			},
			timesheetsComponent: { selectedMonth },
		} = this.props
		let query = {
			for_month: selectedMonth.format(),
			worker_contracts: workersToExport,
			export_type: selectedExportType,
			is_splitted_per_squad: isSplittedPerSquad,
		}
		if (customExportDate && selectedWeek !== "all") {
			query.to_date =
				moment(customExportDate).format() ||
				moment(selectedMonth)
					.endOf("month")
					.format()
		}
		if (sendExportToSecSocAutomatically) {
			query.ftp_login = socSecLogin || this.getLoginFromCookie()
			query.ftp_password = socSecPassword
			document.cookie = `login=${socSecLogin}`
		}
		dispatch(actions.requestTimesheetDataExport(query, true))
		closeModal()
	}
	submitSplittedPerSquadExport = async splitWorkersToExport => {
		let {
			dispatch,
			component: {
				sendExportToSecSocAutomatically,
				selectedWeek,
				customExportDate,
				selectedExportType,
				socSecLogin,
				socSecPassword,
				isSplittedPerSquad,
			},
			timesheetsComponent: { selectedMonth },
		} = this.props
		let query = {
			for_month: selectedMonth.format(),
			worker_contracts: splitWorkersToExport,
			export_type: selectedExportType,
			is_splitted_per_squad: isSplittedPerSquad,
		}
		if (customExportDate && selectedWeek !== "all") {
			query.to_date =
				moment(customExportDate).format() ||
				moment(selectedMonth)
					.endOf("month")
					.format()
		}
		if (sendExportToSecSocAutomatically) {
			query.ftp_login = socSecLogin || this.getLoginFromCookie()
			query.ftp_password = socSecPassword
			document.cookie = `login=${socSecLogin}`
		}
		console.error("start", query)
		await dispatch(actions.requestSplittedTimesheetDataExport(query, true))
		console.error("end", query)
	}
	closeConfirmationModal = () => {
		this.handleChanges({ showSplitConfirmationModal: false })
	}
	openConfirmationModal = () => {
		this.handleChanges({ showSplitConfirmationModal: true })
	}
	closeFilterConfirmationModal = () => {
		this.handleChanges({ showFilterConfirmationModal: false })
	}
	closeFilterConfirmationModalAndResetTimesheests = () => {
		this.handleChanges({ showFilterConfirmationModal: false, workersToExport: [] })
	}
	openFilterConfirmationModal = () => {
		this.handleChanges({ showFilterConfirmationModal: true })
	}
	closeConfirmationModalAndAcceptSplitPerSquad = () => {
		this.closeConfirmationModal()
		this.handleChanges({ isSplittedPerSquad: true })
	}
	closeConfirmationModalAndresetSplitPerSquad = () => {
		this.closeConfirmationModal()
		this.handleChanges({ isSplittedPerSquad: false })
	}
	closeConfirmationModalAndResetFilterBySquad = () => {
		this.closeFilterConfirmationModal()
		this.handleChanges({ filterBySquad: false })
	}

	render() {
		let { t } = this.context
		let {
			closeModal,
			currAffiliate,
			component: {
				customExportDate,
				isSplittedPerSquad,
				selectedWeek,
				workersToExport,
				selectedExportType,
				sendExportToSecSocAutomatically,
				socSecLogin,
				secSocPassword,
				showSplitConfirmationModal,
				showFilterConfirmationModal,
				filterBySquad,
				squadsSelected,
				timesheetsBySquad,
			},
			timesheetsComponent: {
				allTimesheets,
				selectedMonth,
				upfrontPaymentTimesheets,
				timesheetsNeedingCorrection,
			},
		} = this.props
		socSecLogin = socSecLogin || this.getLoginFromCookie()

		currAffiliate.squads.forEach(squad => {
			squadsSelected[squad.id] = squadsSelected[squad.id] || false // Initialize each squad as not selected
			timesheetsBySquad[squad.id] = {
				allExportedTimesheets: [],
				allClosedTimesheets: [],
				allSquadTimesheets: [],
				exportedTimesheets: [],
				exportableTimesheets: [],
				unexportableTimesheets: [],
			}
		})

		timesheetsBySquad[0] = {
			exportedTimesheets: [],
			exportableTimesheets: [],
			unexportableTimesheets: [],
		}

		allTimesheets.forEach(entry => {
			const squadTimesheets = timesheetsBySquad[entry.squad_id]
			const isSquadSelected = !filterBySquad || squadsSelected[entry.squad_id]

			if (squadTimesheets) squadTimesheets.allSquadTimesheets.push(entry)

			let timesheetCategory = ""

			if (squadTimesheets && entry.isClosed) squadTimesheets.allClosedTimesheets.push(entry)
			if (
				entry.exportedAt &&
				entry.exportTypes &&
				entry.exportTypes.includes(selectedExportType)
			) {
				timesheetCategory = "exportedTimesheets"
			} else {
				timesheetCategory = this.timesheetIsExportable(entry)
					? "exportableTimesheets"
					: "unexportableTimesheets"
			}

			if (squadTimesheets) {
				if (
					entry.exportedAt &&
					entry.exportTypes &&
					entry.exportTypes.includes(selectedExportType)
				) {
					squadTimesheets.allExportedTimesheets.push(entry)
				}
				if (isSquadSelected) squadTimesheets[timesheetCategory].push(entry)
			} else if (!filterBySquad) {
				timesheetsBySquad[0][timesheetCategory].push(entry)
			}
		})

		let exportedTimesheetsBySquad = Object.values(timesheetsBySquad).flatMap(
			squad => squad.exportedTimesheets
		)
		let exportableTimesheetsBySquad = Object.values(timesheetsBySquad).flatMap(
			squad => squad.exportableTimesheets
		)
		let unexportableTimesheetsBySquad = Object.values(timesheetsBySquad).flatMap(
			squad => squad.unexportableTimesheets
		)

		let exportDisabled =
			["Group S", "Securex"].includes(this.props.currAffiliate.secSocName) &&
			!selectedExportType

		let useSplitPerSquadButton = [
			<PootsyButton key="a" text={t("yes")} onClick={this.closeConfirmationModal} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.closeConfirmationModalAndresetSplitPerSquad}
			/>,
		]

		let useFilterBySquadButton = [
			<PootsyButton
				key="a"
				text={t("yes")}
				onClick={this.closeFilterConfirmationModalAndResetTimesheests}
			/>,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.closeConfirmationModalAndResetFilterBySquad}
			/>,
		]
		return (
			<ModalLayout
				customClass="TimesheetExportModal"
				title={t("timesheet_select_week_modal_title")}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
				buttons={
					<PootsyButton
						text={t("export")}
						onClick={
							isSplittedPerSquad
								? this.stageAllTimesheetSplittedPerSquadForExportAndSubmit
								: this.submitTimesheetExport
						}
						disabled={exportDisabled}
					/>
				}
			>
				{showFilterConfirmationModal && (
					<ModalLayout
						title={t("use_filter_by_squad_confirmation_title")}
						buttons={useFilterBySquadButton}
						closeModal={this.closeFilterConfirmationModal}
					>
						<div className="split-per-squad-prompt">
							{t("use_filter_by_squad_confirmation_prompt")}
						</div>
					</ModalLayout>
				)}
				{showSplitConfirmationModal && (
					<ModalLayout
						title={t("use_split_per_squad_confirmation_title")}
						buttons={useSplitPerSquadButton}
						closeModal={this.closeConfirmationModal}
					>
						<div className="split-per-squad-prompt">
							{t("use_split_per_squad_confirmation_prompt") +
								" " +
								currAffiliate.secSocName}
						</div>
					</ModalLayout>
				)}
				<PootsySubHeading text={t("month") + " " + selectedMonth.format("MMMM")} />
				<PootsyRadioInputs
					groupName="selectedWeek"
					groupLabel={t("timesheets_codes_sums_timespan")}
					selected={selectedWeek}
					onChange={this.selectExportPeriod}
					options={[
						{ id: "alll", value: "all", label: t("all") },
						{
							id: "custom",
							value: "custom",
							label: t("timesheets_codes_sums_timespan"),
						},
					]}
				/>
				{selectedWeek === "custom" && (
					<PootsyCalendar
						name="customExportDate"
						value={customExportDate}
						onChange={this.handleChanges}
						calendarStyle={{ zIndex: 2 }}
						disableDay={d =>
							d.isBefore(selectedMonth, "month") || d.isAfter(selectedMonth, "month")
						}
					/>
				)}
				{["Group S", "Securex"].includes(this.props.currAffiliate.secSocName) && (
					<PootsyRadioInputs
						groupName="selectedExportType"
						groupLabel={t("timesheets_export_type_label")}
						selected={selectedExportType}
						onChange={this.selectExportType}
						options={[
							{ id: "upfront", value: "upfront", label: t("export_type_upfront") },
							{
								id: "normal",
								value: "normal",
								label: t("export_type_normal"),
							},
							{
								id: "correction",
								value: "correction",
								label: t("export_type_correction"),
							},
						]}
					/>
				)}
				{moment.isMoment(customExportDate) && (
					<PootsySubHeading
						text={t("export_until_date", {
							date: customExportDate.format("DD/MM/YY"),
						})}
					/>
				)}
				{currAffiliate.squads.length > 0 && (
					<div className="filter-squads-checkbox">
						<PootsyCheckboxInput
							name="filterBySquad"
							label={t("filter_by_squad")}
							checked={filterBySquad}
							onChange={
								filterBySquad
									? this.handleFilterBySquadChange
									: this.openFilterConfirmationModal
							}
						/>
					</div>
				)}
				{filterBySquad && (
					<div className="squads-selector">
						{currAffiliate.squads.map(squad => (
							<div className="pootsy-simple-line">
								<PootsyCheckboxInput
									key={squad.id}
									name={squad.id}
									label={squad.name}
									checked={squadsSelected[squad.id] || false}
									onChange={this.handleSquadsSelectedChange}
								/>
								{this.renderTimesheetCounts(timesheetsBySquad, squad.id)}
							</div>
						))}
					</div>
				)}
				<div className="infos">
					{[
						{
							label: t("unclosed_timesheets_count"),
							value: unexportableTimesheetsBySquad.length,
						},
						{
							label: t("closed_timesheets_count"),
							value: exportableTimesheetsBySquad.length,
						},
						{
							label: t("upfront_payment_timesheets_count"),
							value: upfrontPaymentTimesheets.length,
						},
						{
							label: t("timesheets_needing_correction"),
							value: timesheetsNeedingCorrection.length,
						},
					].map(entry => (
						<div key={entry.label} className="info">
							<div className="label">{entry.label}:</div>
							<div className="value">{entry.value}</div>
						</div>
					))}
				</div>
				{!isSplittedPerSquad && (
					<>
						<div className="timesheet-selectors">
							<div className="selector-header">
								<PootsySubHeading text={t("closed_and_not_sent")} />
								<PootsyButton
									text={t("select_all")}
									size="small"
									onClick={() => this.stageAllClosedTimesheetsForExport(false)}
								/>
								<PootsyButton
									text={t("select_upfront_payment_timesheets")}
									size="small"
									onClick={this.stageAllUpfrontPaymentsForExport}
									disabled={selectedExportType !== "upfront"}
								/>
								<PootsyButton
									text={t("select_timesheets_closed_after_last_tsc")}
									size="small"
									onClick={this.stageAllTimesheetsClosedAfterLastTsc}
									disabled={selectedExportType !== "correction"}
								/>
							</div>
							{selectedExportType === "correction" && (
								<div className="timesheets_correction_warning">
									{t("timesheets_corrections_disclaimer")}
								</div>
							)}
							<div className="selector closed">
								{exportableTimesheetsBySquad.map(entry => (
									<PootsyCheckboxInput
										key={entry.worker_contract_id}
										name={"workerID::" + entry.worker_contract_id}
										label={entry.worker_name}
										checked={workersToExport.includes(entry.worker_contract_id)}
										onChange={this.handleWorkerToExportSelection}
									/>
								))}
								{exportableTimesheetsBySquad.length === 0 && t("none_available")}
							</div>
							<div className="selector-header">
								<PootsySubHeading text={t("closed_and_already_sent")} />
								<PootsyButton
									text={t("select_all")}
									size="small"
									onClick={() => this.stageAllClosedTimesheetsForExport(true)}
								/>
								<PootsyButton
									text={t("select_upfront_payment_timesheets")}
									size="small"
									onClick={this.stageAllUpfrontPaymentsForExport}
									disabled={selectedExportType !== "upfront"}
								/>
								<PootsyButton
									text={t("select_timesheets_closed_after_last_tsc")}
									size="small"
									onClick={this.stageAllTimesheetsClosedAfterLastTsc}
									disabled={selectedExportType !== "correction"}
								/>
							</div>
							{selectedExportType === "correction" && (
								<div className="timesheets_correction_warning">
									{t("timesheets_corrections_disclaimer")}
								</div>
							)}
							<div className="selector closed">
								{exportedTimesheetsBySquad.map(entry => (
									<PootsyCheckboxInput
										key={entry.worker_contract_id}
										name={"workerID::" + entry.worker_contract_id}
										label={entry.worker_name}
										checked={workersToExport.includes(entry.worker_contract_id)}
										onChange={this.handleWorkerToExportSelection}
									/>
								))}
								{exportedTimesheetsBySquad.length === 0 && t("none_available")}
							</div>
							{unexportableTimesheetsBySquad.length > 0 && (
								<Fragment>
									<PootsySubHeading text={t("unclosed_timesheets")} />
									<div className="selector opened">
										{unexportableTimesheetsBySquad.map(entry => (
											<div
												key={entry.worker_contract_id}
												className="unexportable-timesheet"
											>
												{entry.worker_name}
											</div>
										))}
									</div>
								</Fragment>
							)}
						</div>
						<div className="timesheet-staged-for-export">
							{t("timeheetNB_will_be_exported", {
								timesheetNB: workersToExport.length,
							})}{" "}
							-{" "}
							{
								[
									...new Set(
										[
											...exportableTimesheetsBySquad,
											...exportedTimesheetsBySquad,
										]
											.filter(e =>
												workersToExport.includes(e.worker_contract_id)
											)
											.map(e => e.worker_name)
									),
								].length
							}{" "}
							{t("worker") + "s"}
						</div>
					</>
				)}

				<div className="secsoc-export-modal-footer">
					{currAffiliate.secSocName === "Group S" && (
						<Fragment>
							{isSplittedPerSquad && (
								<div className="timesheet-selectors">
									<b>
										{t("use_split_per_squad_confirmation_prompt") +
											" " +
											currAffiliate.secSocName}
									</b>
								</div>
							)}
							<PootsyCheckboxInput
								name="isSplittedPerSquad"
								label={t("is_splitted_per_squad")}
								checked={isSplittedPerSquad}
								onChange={
									isSplittedPerSquad
										? this.handleIsSplittedPerSquad
										: this.openConfirmationModal
								}
							/>
							<PootsyCheckboxInput
								name="sendExportToSecSocAutomatically"
								label={t("send_export_to_secsoc_automatically")}
								checked={sendExportToSecSocAutomatically}
							/>
							{sendExportToSecSocAutomatically && (
								<div className="secsoc-login-form">
									<PootsyTextInput
										name="socSecLogin"
										autocomplete="username"
										label={t("login")}
										value={socSecLogin || this.getLoginFromCookie()}
									/>
									<PootsyTextInput
										name="socSecPassword"
										type="password"
										autocomplete="current-password"
										value={secSocPassword}
										label={t("password")}
									/>
								</div>
							)}
						</Fragment>
					)}
				</div>
			</ModalLayout>
		)
	}
}

TimesheetExportModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.timesheetExportModalComponent,
	timesheetsComponent: state.redComponents.timesheetsComponent,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(TimesheetExportModal)
