import { simpleFetch, fetchBookings, acknowledgeErrors, FETCH_WORKSHOP_ORDERS } from "./index"
import { stringifyQuery } from "../helpers/qs"
import moment from "moment"

export const SODEXO_UICHANGE = "SODEXO_UICHANGE"
export const sodexoUIChange = changes => ({ type: SODEXO_UICHANGE, changes })

export const SODEXO_CONFLICTS_STATE_CHANGE = "SODEXO_CONFLICTS_STATE_CHANGE"
export const sodexoConflictsStateChange = changes => ({
	type: SODEXO_CONFLICTS_STATE_CHANGE,
	changes,
})

export const SODEXO_PAPER_SERVICE_VOUCHERS_STATE_CHANGE =
	"SODEXO_PAPER_SERVICE_VOUCHERS_STATE_CHANGE"
export const sodexoPaperServiceVoucherStateChange = changes => ({
	type: SODEXO_PAPER_SERVICE_VOUCHERS_STATE_CHANGE,
	changes,
})

export const SODEXO_INSUFFICIENT_STATE_CHANGE = "SODEXO_INSUFFICIENT_STATE_CHANGE"
export const sodexoInsufficientStateChange = changes => ({
	type: SODEXO_INSUFFICIENT_STATE_CHANGE,
	changes,
})

export const SODEXO_VALIDATE_STATE_CHANGE = "SODEXO_VALIDATE_STATE_CHANGE"
export const sodexoValidateStateChange = changes => ({
	type: SODEXO_VALIDATE_STATE_CHANGE,
	changes,
})

export const SEND_PAYMENT_REMINDER_SUCCESS = "SEND_PAYMENT_REMINDER_SUCCESS"

export const PSV_SCANNER_STATE_CHANGE = "PSV_SCANNER_STATE_CHANGE"
export const PSVScannerStateChange = changes => ({ type: PSV_SCANNER_STATE_CHANGE, changes })

export const SEARCH_SODEXO_WORK_STATE_CHANGE = "SEARCH_SODEXO_WORK_STATE_CHANGE"
export const searchSodexoWorkStateChange = changes => ({
	type: SEARCH_SODEXO_WORK_STATE_CHANGE,
	changes,
})

export const RESET_PSV_SCANNER_STATE = "RESET_PSV_SCANNER_STATE"
export const resetPSVScannerState = () => ({ type: RESET_PSV_SCANNER_STATE })

export const SODEXO_OPPOSED_STATE_CHANGE = "SODEXO_OPPOSED_STATE_CHANGE"
export const sodexoOpposedStateChange = changes => ({ type: SODEXO_OPPOSED_STATE_CHANGE, changes })

export const PSV_DATA_REQUEST = "PSV_DATA_REQUEST"
export const PSV_DATA_SUCCESS = "PSV_DATA_SUCCESS"
export const PSV_DATA_FAILURE = "PSV_DATA_FAILURE"

export const RETURN_SLIP_PDF_REQUEST = "RETURN_SLIP_PDF_REQUEST"
export const RETURN_SLIP_PDF_SUCCESS = "RETURN_SLIP_PDF_SUCCESS"
export const RETURN_SLIP_PDF_FAILURE = "RETURN_SLIP_PDF_FAILURE"

export const SODEXO_DECLARATION_CSV_REQUEST = "SODEXO_DECLARATION_CSV_REQUEST"
export const SODEXO_DECLARATION_CSV_SUCCESS = "SODEXO_DECLARATION_CSV_SUCCESS"
export const SODEXO_DECLARATION_CSV_FAILURE = "SODEXO_DECLARATION_CSV_FAILURE"

export const SODEXO_MANUAL_SYNC_REQUEST = "SODEXO_MANUAL_SYNC_REQUEST"
export const SODEXO_MANUAL_SYNC_SUCCESS = "SODEXO_MANUAL_SYNC_SUCCESS"
export const SODEXO_MANUAL_SYNC_FAILURE = "SODEXO_MANUAL_SYNC_FAILURE"

export const SODEXO_CONNECTION_TOKEN_REQUEST = "SODEXO_CONNECTION_TOKEN_REQUEST"
export const SODEXO_CONNECTION_TOKEN_SUCCESS = "SODEXO_CONNECTION_TOKEN_SUCCESS"
export const SODEXO_CONNECTION_TOKEN_FAILURE = "SODEXO_CONNECTION_TOKEN_FAILURE"

export const BATCH_VALIDATE_SODEXO_BOOKINGS = "BATCH_VALIDATE_SODEXO_BOOKINGS"
export const BATCH_VALIDATE_SODEXO_BOOKINGS_REQUEST = "BATCH_VALIDATE_SODEXO_BOOKINGS_REQUEST"
export const BATCH_VALIDATE_SODEXO_BOOKINGS_SUCCESS = "BATCH_VALIDATE_SODEXO_BOOKINGS_SUCCESS"
export const BATCH_VALIDATE_SODEXO_BOOKINGS_FAILURE = "BATCH_VALIDATE_SODEXO_BOOKINGS_FAILURE"

export const CHEQUES_LIST_EXPORT = "CHEQUES_LIST_EXPORT"
export const CHEQUES_LIST_EXPORT_REQUEST = "CHEQUES_LIST_EXPORT_REQUEST"
export const CHEQUES_LIST_EXPORT_SUCCESS = "CHEQUES_LIST_EXPORT_SUCCESS"
export const CHEQUES_LIST_EXPORT_FAILURE = "CHEQUES_LIST_EXPORT_FAILURE"

export const CHECK_VALIDATIONS_STATUS = "CHECK_VALIDATIONS_STATUS"
export const CHECK_VALIDATIONS_STATUS_REQUEST = "CHECK_VALIDATIONS_STATUS_REQUEST"
export const CHECK_VALIDATIONS_STATUS_SUCCESS = "CHECK_VALIDATIONS_STATUS_SUCCESS"
export const CHECK_VALIDATIONS_STATUS_FAILURE = "CHECK_VALIDATIONS_STATUS_FAILURE"

export const FETCH_SODEXO_BOOKINGS = "FETCH_SODEXO_BOOKINGS"
export const FETCH_SODEXO_BOOKINGS_REQUEST = "FETCH_SODEXO_BOOKINGS_REQUEST"
export const FETCH_SODEXO_BOOKINGS_SUCCESS = "FETCH_SODEXO_BOOKINGS_SUCCESS"
export const FETCH_SODEXO_BOOKINGS_FAILURE = "FETCH_SODEXO_BOOKINGS_FAILURE"

export const SUBMIT_SODEXO_LINKS = "SUBMIT_SODEXO_LINKS"
export const SUBMIT_SODEXO_LINKS_REQUEST = "SUBMIT_SODEXO_LINKS_REQUEST"
export const SUBMIT_SODEXO_LINKS_SUCCESS = "SUBMIT_SODEXO_LINKS_SUCCESS"
export const SUBMIT_SODEXO_LINKS_FAILURE = "SUBMIT_SODEXO_LINKS_FAILURE"

export const FETCH_SODEXO_PSV_DELIVERIES = "FETCH_SODEXO_PSV_DELIVERIES"
export const FETCH_SODEXO_PSV_DELIVERIES_REQUEST = "FETCH_SODEXO_PSV_DELIVERIES_REQUEST"
export const FETCH_SODEXO_PSV_DELIVERIES_SUCCESS = "FETCH_SODEXO_PSV_DELIVERIES_SUCCESS"
export const FETCH_SODEXO_PSV_DELIVERIES_FAILURE = "FETCH_SODEXO_PSV_DELIVERIES_FAILURE"
export const SODEXO_PSV_DELIVERY_VALUE_STATE_CHANGE = "SODEXO_PSV_DELIVERY_VALUE_STATE_CHANGE"
export const paperDeliveriesStateChange = changes => ({
	type: SODEXO_PSV_DELIVERY_VALUE_STATE_CHANGE,
	changes,
})

export const PSV_DELIVERY_STATE_CHANGE = "PSV_DELIVERY_STATE_CHANGE"
export const FETCH_SODEXO_PSV_DELIVERY = "FETCH_SODEXO_PSV_DELIVERY"
export const FETCH_SODEXO_PSV_DELIVERY_REQUEST = "FETCH_SODEXO_PSV_DELIVERY_REQUEST"
export const FETCH_SODEXO_PSV_DELIVERY_SUCCESS = "FETCH_SODEXO_PSV_DELIVERY_SUCCESS"
export const FETCH_SODEXO_PSV_DELIVERY_FAILURE = "FETCH_SODEXO_PSV_DELIVERY_FAILURE"

export const UPDATE_SODEXO_PSV_DELIVERY = "UPDATE_SODEXO_PSV_DELIVERY"
export const UPDATE_SODEXO_PSV_DELIVERY_REQUEST = "UPDATE_SODEXO_PSV_DELIVERY_REQUEST"
export const UPDATE_SODEXO_PSV_DELIVERY_SUCCESS = "UPDATE_SODEXO_PSV_DELIVERY_SUCCESS"
export const UPDATE_SODEXO_PSV_DELIVERY_FAILURE = "UPDATE_SODEXO_PSV_DELIVERY_FAILURE"

export const CANCEL_SODEXO_PSV_DELIVERY = "CANCEL_SODEXO_PSV_DELIVERY"
export const CANCEL_SODEXO_PSV_DELIVERY_REQUEST = "CANCEL_SODEXO_PSV_DELIVERY_REQUEST"
export const CANCEL_SODEXO_PSV_DELIVERY_SUCCESS = "CANCEL_SODEXO_PSV_DELIVERY_SUCCESS"
export const CANCEL_SODEXO_PSV_DELIVERY_FAILURE = "CANCEL_SODEXO_PSV_DELIVERY_FAILURE"

export const CLOSE_SODEXO_PSV_DELIVERY = "CLOSE_SODEXO_PSV_DELIVERY"
export const CLOSE_SODEXO_PSV_DELIVERY_REQUEST = "CLOSE_SODEXO_PSV_DELIVERY_REQUEST"
export const CLOSE_SODEXO_PSV_DELIVERY_SUCCESS = "CLOSE_SODEXO_PSV_DELIVERY_SUCCESS"
export const CLOSE_SODEXO_PSV_DELIVERY_FAILURE = "CLOSE_SODEXO_PSV_DELIVERY_FAILURE"

export const REOPEN_SODEXO_PSV_DELIVERY = "REOPEN_SODEXO_PSV_DELIVERY"
export const REOPEN_SODEXO_PSV_DELIVERY_REQUEST = "REOPEN_SODEXO_PSV_DELIVERY_REQUEST"
export const REOPEN_SODEXO_PSV_DELIVERY_SUCCESS = "REOPEN_SODEXO_PSV_DELIVERY_SUCCESS"
export const REOPEN_SODEXO_PSV_DELIVERY_FAILURE = "REOPEN_SODEXO_PSV_DELIVERY_FAILURE"

export const CSV_SODEXO_PSV_DELIVERY = "CSV_SODEXO_PSV_DELIVERY"
export const CSV_SODEXO_PSV_DELIVERY_REQUEST = "CSV_SODEXO_PSV_DELIVERY_REQUEST"
export const CSV_SODEXO_PSV_DELIVERY_SUCCESS = "CSV_SODEXO_PSV_DELIVERY_SUCCESS"
export const CSV_SODEXO_PSV_DELIVERY_FAILURE = "CSV_SODEXO_PSV_DELIVERY_FAILURE"

export const DELETE_PSV_PSV_DELIVERY = "DELETE_PSV_PSV_DELIVERY"
export const DELETE_PSV_PSV_DELIVERY_REQUEST = "DELETE_PSV_PSV_DELIVERY_REQUEST"
export const DELETE_PSV_PSV_DELIVERY_SUCCESS = "DELETE_PSV_PSV_DELIVERY_SUCCESS"
export const DELETE_PSV_PSV_DELIVERY_FAILURE = "DELETE_PSV_PSV_DELIVERY_FAILURE"

export const MODAL_SEARCH_PSV_STATE_CHANGE = "MODAL_SEARCH_PSV_STATE_CHANGE"
export const modalSearchPsvStateChange = changes => ({
	type: MODAL_SEARCH_PSV_STATE_CHANGE,
	changes,
})

export const FETCH_NEW_PSV_DELIVERY = "FETCH_NEW_PSV_DELIVERY"
export const FETCH_NEW_PSV_DELIVERY_REQUEST = "FETCH_NEW_PSV_DELIVERY_REQUEST"
export const FETCH_NEW_PSV_DELIVERY_SUCCESS = "FETCH_NEW_PSV_DELIVERY_SUCCESS"
export const FETCH_NEW_PSV_DELIVERY_FAILURE = "FETCH_NEW_PSV_DELIVERY_FAILURE"

export const SUBMIT_NEW_PSV_DELIVERY = "SUBMIT_NEW_PSV_DELIVERY"
export const SUBMIT_NEW_PSV_DELIVERY_REQUEST = "SUBMIT_NEW_PSV_DELIVERY_REQUEST"
export const SUBMIT_NEW_PSV_DELIVERY_SUCCESS = "SUBMIT_NEW_PSV_DELIVERY_SUCCESS"
export const SUBMIT_NEW_PSV_DELIVERY_FAILURE = "SUBMIT_NEW_PSV_DELIVERY_FAILURE"

export const FETCH_PSV = "FETCH_PSV"
export const FETCH_PSV_REQUEST = "FETCH_PSV_REQUEST"
export const FETCH_PSV_SUCCESS = "FETCH_PSV_SUCCESS"
export const FETCH_PSV_FAILURE = "FETCH_PSV_FAILURE"

export const SUBMIT_SODEXO_LINKS_BATCH = "SUBMIT_SODEXO_LINKS_BATCH"
export const SUBMIT_SODEXO_LINKS_BATCH_REQUEST = "SUBMIT_SODEXO_LINKS_BATCH_REQUEST"
export const SUBMIT_SODEXO_LINKS_BATCH_SUCCESS = "SUBMIT_SODEXO_LINKS_BATCH_SUCCESS"
export const SUBMIT_SODEXO_LINKS_BATCH_FAILURE = "SUBMIT_SODEXO_LINKS_BATCH_FAILURE"

export const ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY = "ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY"
export const ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_REQUEST =
	"ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_REQUEST"
export const ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_SUCCESS =
	"ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_SUCCESS"
export const ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_FAILURE =
	"ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_FAILURE"

export const ADD_PSV_TO_DELIVERY = "ADD_PSV_TO_DELIVERY"
export const ADD_PSV_TO_DELIVERY_REQUEST = "ADD_PSV_TO_DELIVERY_REQUEST"
export const ADD_PSV_TO_DELIVERY_SUCCESS = "ADD_PSV_TO_DELIVERY_SUCCESS"
export const ADD_PSV_TO_DELIVERY_FAILURE = "ADD_PSV_TO_DELIVERY_FAILURE"
export const RESET_PSV_DELIVERY = "RESET_PSV_DELIVERY"
export const paperDeliveryStateChange = changes => ({ type: PSV_DELIVERY_STATE_CHANGE, changes })
export const resetPaperDelivery = () => ({ type: RESET_PSV_DELIVERY })
export const fetchPaperDelivery = id => async (dispatch, getState) => {
	let {
		redComponents: {
			PSVDeliveryComponent: { note },
		},
	} = getState()
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + id
	let { actionType, data } = await dispatch(
		simpleFetch(url, { method: "GET" }, FETCH_SODEXO_PSV_DELIVERY)
	)
	if (actionType === FETCH_SODEXO_PSV_DELIVERY_SUCCESS && !note && data.data.attributes.note) {
		dispatch(paperDeliveryStateChange({ note: data.data.attributes.note }))
	}
}

export const addPsvToGroup = (id, groupID) => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_service_vouchers/" + id
	dispatch(
		simpleFetch(
			url,
			{ method: "PUT", body: JSON.stringify({ paper_voucher_delivery_group_id: groupID }) },
			ADD_PSV_TO_DELIVERY
		)
	).then(() => {
		dispatch(fetchPaperDelivery(groupID))
	})
}

export const fetchPSV = code => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_service_vouchers/show?code=" + code
	dispatch(simpleFetch(url, { method: "GET" }, FETCH_PSV))
}

export const ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_STATE_CHANGE =
	"ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_STATE_CHANGE"
export const addPaperDeliveryStateChange = changes => ({
	type: ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_STATE_CHANGE,
	changes,
})

export const createNewPaperDelivery = body => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/"
	return dispatch(
		simpleFetch(url, { method: "POST", body: JSON.stringify(body) }, SUBMIT_NEW_PSV_DELIVERY)
	)
}

export const addToPaperDelivery = body => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + body.id
	return dispatch(
		simpleFetch(
			url,
			{ method: "PUT", body: JSON.stringify(body) },
			ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY
		)
	)
}

export const NEW_PSV_DELIVERY_STATE_CHANGE = "NEW_PSV_DELIVERY_STATE_CHANGE"
export const newPaperDeliveryStateChange = changes => ({
	type: NEW_PSV_DELIVERY_STATE_CHANGE,
	changes,
})

export const fetchAvailablePSVForDelivery = args => (dispatch, getState) => {
	let { monthStartFilter, monthEndFilter, monthStartGroup, squad, subjectType } = args
	monthStartGroup = monthStartGroup ? monthStartGroup : moment()
	let params = {
		month_start_filter: monthStartFilter ? monthStartFilter : monthStartGroup,
		month_end_filter: monthEndFilter ? monthEndFilter : null,
		squad_id: squad,
		subject_type: subjectType,
	}
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/new" + stringifyQuery(params)
	dispatch(simpleFetch(url, { method: "GET" }, FETCH_NEW_PSV_DELIVERY))
}

export const fetchAvailablePSVForDeliveryForNew = () => (dispatch, getState) => {
	let {
		redComponents: {
			newPaperDeliveryComponent: {
				monthStartFilter,
				monthEndFilter,
				monthStartGroup,
				squad,
				subjectType,
			},
		},
	} = getState()
	let params = {
		monthStartFilter: monthStartFilter.format("YYYY-MM-DD"),
		monthEndFilter: monthEndFilter.format("YYYY-MM-DD"),
		monthStartGroup: monthStartGroup.format("YYYY-MM-DD"),
		squad: squad,
		subjectType: subjectType,
	}
	dispatch(fetchAvailablePSVForDelivery(params))
}

export const fetchAvailablePSVForDeliveryForUpdate = () => (dispatch, getState) => {
	let {
		redComponents: {
			updatePSVdeliveryGroupComponent: {
				monthStartFilter,
				monthEndFilter,
				monthStartGroup,
				squad,
				subjectType,
			},
		},
	} = getState()
	let params = {
		monthStartFilter: monthStartFilter.format("YYYY-MM-DD"),
		monthEndFilter: monthEndFilter.format("YYYY-MM-DD"),
		monthStartGroup: monthStartGroup.format("YYYY-MM-DD"),
		squad: squad,
		subjectType: subjectType,
	}
	dispatch(fetchAvailablePSVForDelivery(params))
}

export const deletePSVInGroup = (id, groupID) => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_service_vouchers/" + id
	dispatch(
		simpleFetch(
			url,
			{ method: "PUT", body: JSON.stringify({ paper_voucher_delivery_group_id: null }) },
			DELETE_PSV_PSV_DELIVERY
		)
	).then(() => dispatch(fetchPaperDelivery(groupID)))
}

export const downloadDeliveryCSV = id => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + id + "/export_csv"
	dispatch(
		simpleFetch(url, { method: "GET" }, CSV_SODEXO_PSV_DELIVERY, {
			fileRequest: true,
			fileKeyName: "csvDeliveryGroupExport",
		})
	)
}

export const updatePaperDelivery = body => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + body.id
	dispatch(
		simpleFetch(url, { method: "PUT", body: JSON.stringify(body) }, UPDATE_SODEXO_PSV_DELIVERY)
	)
}
export const closePaperDelivery = id => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + id + "/close"
	dispatch(simpleFetch(url, { method: "PUT" }, CLOSE_SODEXO_PSV_DELIVERY))
}
export const cancelPaperDelivery = id => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + id + "/cancel"
	dispatch(simpleFetch(url, { method: "PUT" }, CANCEL_SODEXO_PSV_DELIVERY))
}

export const reopenPaperDelivery = id => (dispatch, getState) => {
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups/" + id + "/reopen"
	dispatch(simpleFetch(url, { method: "PUT" }, REOPEN_SODEXO_PSV_DELIVERY))
}
export const SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK = "SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK"
export const SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LIN_REQUEST =
	"SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_REQUEST"
export const SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_SUCCESS =
	"SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_SUCCESS"
export const SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_FAILURE =
	"SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_FAILURE"

export const FETCH_SODEXO_PAPER_SERVICE_VOUCHERS = "FETCH_SODEXO_PAPER_SERVICE_VOUCHERS"
export const FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_REQUEST =
	"FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_REQUEST"
export const FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_SUCCESS =
	"FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_SUCCESS"
export const FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_FAILURE =
	"FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_FAILURE"

export const SEND_SODEXO_PSV = "SEND_SODEXO_PSV"
export const SEND_SODEXO_PSV_REQUEST = "SEND_SODEXO_PSV_REQUEST"
export const SEND_SODEXO_PSV_SUCCESS = "SEND_SODEXO_PSV_SUCCESS"
export const SEND_SODEXO_PSV_FAILURE = "SEND_SODEXO_PSV_FAILURE"

export const fetchSodexoBookings = query => (dispatch, getState) => {
	let init = { method: "GET" }
	let finalQuery = Object.keys(query).reduce((acc, key) => {
		let value = query[key]
		if (!value) return acc
		acc[key] = value
		if (moment.isMoment(value)) {
			acc[key] = value.format("YYYY-MM-DD")
		}
		return acc
	}, {})
	let url = "/front/affiliates/sodexo/sodexo_bookings" + stringifyQuery(finalQuery)
	dispatch(simpleFetch(url, init, FETCH_SODEXO_BOOKINGS))
}

export const fetchSodexoPaperServiceVouchers = query => (dispatch, getState) => {
	let init = { method: "GET" }
	let finalQuery = Object.keys(query).reduce((acc, key) => {
		let value = query[key]
		if (!value) return acc
		acc[key] = value
		if (moment.isMoment(value)) {
			acc[key] = value.format("YYYY-MM-DD")
		}
		return acc
	}, {})
	finalQuery.origin = "affiliate"
	let url = "/front/affiliates/sodexo/sodexo_paper_service_vouchers" + stringifyQuery(finalQuery)
	dispatch(simpleFetch(url, init, FETCH_SODEXO_PAPER_SERVICE_VOUCHERS))
}

export const fetchSodexoBookingsForSodexoConflicts = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoConflictsComponent: {
				rangeStart,
				rangeEnd,
				sBookingsPage,
				filterSodexoBookingsCustomerSodexoRef,
				filterSodexoBookingsWorkerSodexoRef,
				selectedPootsyServiceTab,
				specificWorker,
				specificCustomer,
			},
		},
	} = getState()
	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: sBookingsPage,
		filter_pootsy_linkage: "not_linked",
		filter_customer_sodexo_reference:
			filterSodexoBookingsCustomerSodexoRef || specificCustomer.sodexo,
		filter_worker_sodexo_reference:
			filterSodexoBookingsWorkerSodexoRef || specificWorker.sodexo,
		...query,
	}
	if (selectedPootsyServiceTab === "workshop_orders") {
		finalQuery.filter_sodexo_activity = "Ironing"
	}
	dispatch(fetchSodexoBookings(finalQuery))
}

export const fetchPootsyBookingsForSodexoConflicts = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoConflictsComponent: {
				rangeStart,
				rangeEnd,
				pBookingsPage,
				selectedSquadId,
				filterPootsyBookingsCustomerSodexoRef,
				filterPootsyBookingsWorkerSodexoRef,
				specificWorker,
				specificCustomer,
				filterWorkerOrCustomer,
			},
		},
	} = getState()
	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: pBookingsPage,
		filter_voucher_type: "electronic",
		filter_sodexo_linkage: "not_linked",
		filter_customer_sodexo_reference:
			filterPootsyBookingsCustomerSodexoRef || specificCustomer.sodexo,
		filter_worker_sodexo_reference:
			filterPootsyBookingsWorkerSodexoRef || specificWorker.sodexo,
		filter_worker_or_customer: filterWorkerOrCustomer,
		reject_cancelled_occurrences: true,
		...query,
	}
	if (selectedSquadId) {
		finalQuery.filter_squad_ids = [selectedSquadId]
	}
	dispatch(fetchBookings(finalQuery, { source: "SODEXO" }))
}

const fetchPootsyWorkshopOrdersForSodexo = query => (dispatch, getState) => {
	let url = "/front/affiliates/laundry/laundry_orders" + stringifyQuery(query)
	let init = { method: "GET" }
	return dispatch(simpleFetch(url, init, FETCH_WORKSHOP_ORDERS))
}

export const fetchPootsyWorkshopOrdersForSodexoConflicts = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoConflictsComponent: {
				rangeStart,
				rangeEnd,
				pWorkshopOrdersPage,
				filterPootsyBookingsCustomerSodexoRef,
				filterPootsyBookingsWorkerSodexoRef,
				selectedSquadId,
				specificWorker,
				specificCustomer,
				filterWorkerOrCustomer,
			},
		},
	} = getState()
	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: pWorkshopOrdersPage,
		filter_voucher_type: "electronic",
		filter_sodexo_linkage: "not_linked",
		filter_customer_sodexo_reference:
			filterPootsyBookingsCustomerSodexoRef || specificCustomer.sodexo,
		filter_worker_sodexo_reference:
			filterPootsyBookingsWorkerSodexoRef || specificWorker.sodexo,
		filter_worker_or_customer: filterWorkerOrCustomer,
		filter_limbo: false,
		compute_total_billable_vouchers: false,
	}
	if (selectedSquadId) {
		finalQuery.filter_squad_ids = [selectedSquadId]
	}
	dispatch(fetchPootsyWorkshopOrdersForSodexo(finalQuery))
}

export const fetchPootsyWorkshopOrdersForSodexoPsvConflicts = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: {
				rangeStart,
				rangeEnd,
				pWorkshopOrdersPage,
				filterPootsyBookingsCustomerSodexoRef,
				filterPootsyBookingsWorkerSodexoRef,
				selectedSquadId,
				specificWorker,
			},
		},
	} = getState()
	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: pWorkshopOrdersPage,
		filter_voucher_type: "paper",
		filter_paper_voucher_completion: "psv_incomplete",
		filter_customer_sodexo_reference: filterPootsyBookingsCustomerSodexoRef,
		filter_worker_sodexo_reference:
			filterPootsyBookingsWorkerSodexoRef || specificWorker.sodexo,
		filter_worker_or_customer: true,
		filter_limbo: false,
		compute_total_billable_vouchers: false,
	}
	if (selectedSquadId) {
		finalQuery.filter_squad_ids = [selectedSquadId]
	}
	dispatch(fetchPootsyWorkshopOrdersForSodexo(finalQuery))
}

export const fetchPootsyPaperDeliveriesForSodexo = () => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let init = { method: "GET" }
	let url = "/front/affiliates/sodexo/paper_voucher_delivery_groups" + stringifyQuery(realQuery)
	dispatch(simpleFetch(url, init, FETCH_SODEXO_PSV_DELIVERIES))
}

export const fetchPootsyBookingsForPaperServiceVouchers = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: {
				rangeStart,
				rangeEnd,
				pBookingsPage,
				selectedSquadId,
				filterPootsyBookingsWorkerSodexoRef,
				filterPootsyBookingsCustomerSodexoRef,
				specificWorker,
			},
		},
	} = getState()
	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: pBookingsPage,
		filter_voucher_type: "paper",
		filter_paper_voucher_completion: "psv_incomplete",
		filter_customer_sodexo_reference: filterPootsyBookingsCustomerSodexoRef,
		filter_worker_sodexo_reference:
			filterPootsyBookingsWorkerSodexoRef || specificWorker.sodexo,
		filter_worker_or_customer: true,
		with_total_voucher_count: true,
		reject_cancelled_occurrences: true,
		...query,
	}
	if (selectedSquadId) {
		finalQuery.filter_squad_ids = [selectedSquadId]
	}
	dispatch(fetchBookings(finalQuery, { source: "SODEXO" }))
}

export const fetchPaperServiceVouchersForSodexoPSVConflicts = query => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: {
				rangeStart,
				rangeEnd,
				sPSVsPage,
				filterSodexoPSVsWorkerSodexoRef,
				filterSodexoPSVsCustomerSodexoRef,
				specificWorker,
			},
		},
	} = getState()

	let finalQuery = {
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		page: sPSVsPage,
		filter_pootsy_linkage: "not_linked",
		filter_customer_sodexo_reference: filterSodexoPSVsCustomerSodexoRef,
		filter_worker_sodexo_reference: filterSodexoPSVsWorkerSodexoRef || specificWorker.sodexo,
		...query,
	}
	dispatch(fetchSodexoPaperServiceVouchers(finalQuery))
}

export const checkSodexoValidationStatus = () => async (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
		redComponents: {
			sodexoValidateComponent: { validationsStatus: statusBeforeRequest },
		},
	} = getState()
	let url = "/front/affiliates/sodexo/sodexo_update_booking_status/check_validations_semaphore"
	let init = { method: "GET" }
	let { actionType, data } = await dispatch(simpleFetch(url, init, CHECK_VALIDATIONS_STATUS))
	if (actionType === CHECK_VALIDATIONS_STATUS_FAILURE) {
		dispatch(acknowledgeErrors())
	} else if (
		actionType === CHECK_VALIDATIONS_STATUS_SUCCESS &&
		statusBeforeRequest !== "no_validations_in_progress" &&
		data.validations_status === "no_validations_in_progress"
	) {
		dispatch(fetchBookings(realQuery))
	}
}

export const submitSodexoLinks = () => async (dispatch, getState) => {
	let {
		redComponents: {
			sodexoConflictsComponent: { stagedLinks },
		},
	} = getState()
	let url = "/front/affiliates/sodexo/sodexo_link"
	let init = {
		method: "POST",
		body: JSON.stringify({
			links: stagedLinks.map(entry => ({
				pootsy_booking_occurrence_id: entry.pootsyBookingID,
				pootsy_laundry_order_id: entry.pootsyWorkshopOrderID,
				sodexo_work_id: entry.sodexoBookingID,
				changes: entry.changes,
			})),
		}),
	}
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_SODEXO_LINKS))
	if (actionType === SUBMIT_SODEXO_LINKS_SUCCESS) {
		dispatch(
			sodexoConflictsStateChange({
				stagedLinks: [],
				specificWorker: { name: "", sodexo: "" },
				filterPootsyBookingsCustomerSodexoRef: null,
				filterPootsyBookingsWorkerSodexoRef: null,
				filterSodexoBookingsCustomerSodexoRef: null,
				filterSodexoBookingsWorkerSodexoRef: null,
			})
		)
		dispatch(fetchSodexoBookingsForSodexoConflicts())
		dispatch(fetchPootsyBookingsForSodexoConflicts())
	}
}

export const fetchAllBookingsForSodexoPSVConflicts = () => (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: { selectedPootsyServiceTab },
		},
	} = getState()
	dispatch(
		sodexoPaperServiceVoucherStateChange({
			stagedPsvID: -1,
			stagedPootsyBookingID: -1,
			showSodexoStagedPsv: false,
			showPootsyStagedBooking: false,
		})
	)
	if (selectedPootsyServiceTab === "bookings") {
		dispatch(fetchPootsyBookingsForPaperServiceVouchers())
	} else {
		dispatch(fetchPootsyWorkshopOrdersForSodexoPsvConflicts())
	}
	dispatch(fetchPaperServiceVouchersForSodexoPSVConflicts())
}

export const sendSodexoCSV = (includeExpiredVouchers = false) => async (dispatch, getState) => {
	let data = { include_expired: includeExpiredVouchers }
	let url = "/front/affiliates/sodexo/sodexo_paper_service_vouchers"

	data.file = {
		attribute: "psv_input",
		filename: "psv_input",
		base64_content: getState().redComponents.PSVScannerComponent.importFile.file,
	}

	let init = {
		method: "POST",
		body: JSON.stringify(data),
	}
	return dispatch(simpleFetch(url, init, SEND_SODEXO_PSV))
}

export const sendSodexoPSV = ({
	subjectType,
	workerSodexoRef,
	customerSodexoRef,
	subjectID,
	dryRun = false,
	includeExpiredVouchers = false,
}) => async (dispatch, getState) => {
	let {
		redComponents: {
			PSVScannerComponent: { scannedCodes },
		},
	} = getState()
	let url = "/front/affiliates/sodexo/sodexo_paper_service_vouchers"
	let data = {
		paper_service_voucher_codes: scannedCodes.map(e => e.code),
		worker_sodexo_reference: workerSodexoRef,
		customer_sodexo_reference: customerSodexoRef,
		subject_type: subjectType,
		subject_id: subjectID,
		dry_run: dryRun,
		include_expired: includeExpiredVouchers,
	}

	let init = {
		method: "POST",
		body: JSON.stringify(data),
	}
	return dispatch(simpleFetch(url, init, SEND_SODEXO_PSV))
}

export const checkSodexoPSV = ({
	subjectType,
	workerSodexoRef,
	customerSodexoRef,
	subjectID,
	includeExpiredVouchers = false,
}) => async (dispatch, getState) => {
	let { actionType, data } = await dispatch(
		sendSodexoPSV({
			workerSodexoRef,
			customerSodexoRef,
			subjectType,
			subjectID,
			dryRun: true,
			includeExpiredVouchers,
		})
	)
	let scannedCodes = getState().redComponents.PSVScannerComponent.scannedCodes
	if (actionType === SEND_SODEXO_PSV_SUCCESS) {
		let received_psv = data.data.report.psvs
		dispatch(
			PSVScannerStateChange({
				scannedCodes: scannedCodes.map(
					code1 => received_psv.find(code2 => code2.code === code1.code) || code1
				),
			})
		)
	}
}

export const submitSodexoPaperServiveVouchersLinks = () => async (dispatch, getState) => {
	let {
		redData: { sodexoPaperServiceVouchers },
		redComponents: {
			sodexoPsvConflictsComponent: { stagedLinks },
		},
	} = getState()

	let assignedPsvs = []
	let links = stagedLinks.reduce((acc, stagedLink) => {
		stagedLink.psvLinks.forEach(psvLink => {
			let psvGroup = sodexoPaperServiceVouchers.find(entry => entry.id === psvLink.psvId)
			if (!psvGroup) {
				return
			}
			let usedPsvs = psvGroup.paperServiceVouchers
				.filter(entry => !assignedPsvs.includes(entry.id)) // filter-out psv already used
				.slice(0, psvLink.count) // take the amount that was assigned for this booking
				.map(entry => entry.id)

			assignedPsvs = assignedPsvs.concat(usedPsvs)
			acc.push({
				service_type: stagedLink.serviceType,
				service_id: stagedLink.service.id,
				paper_service_voucher_ids: usedPsvs,
			})
		})
		return acc
	}, [])

	let url = "/front/affiliates/sodexo/sodexo_paper_service_voucher_link"
	let init = { method: "POST", body: JSON.stringify({ links: links }) }

	let { actionType } = await dispatch(
		simpleFetch(url, init, SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK)
	)
	if (actionType === SUBMIT_SODEXO_PAPER_SERVICE_VOUCHERS_LINK_SUCCESS) {
		dispatch(
			sodexoPaperServiceVoucherStateChange({
				stagedLinks: [],
				showPootsyStagedBooking: false,
				showPootsyStagedWorkshopOrder: false,
				filterPootsyBookingsCustomerSodexoRef: null,
				filterPootsyBookingsWorkerSodexoRef: null,
				filterSodexoPSVsCustomerSodexoRef: null,
				filterSodexoPSVsWorkerSodexoRef: null,
			})
		)
		dispatch(fetchAllBookingsForSodexoPSVConflicts())
	}
}

export const submitAutomaticLinkingPSV = () => async (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: {
				rangeStart,
				rangeEnd,
				filterSodexoPSVsWorkerSodexoRef,
				selectedSquadId,
				selectedPootsyServiceTab,
			},
		},
	} = getState()

	let finalQuery = {
		service_type: selectedPootsyServiceTab,
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		filter_worker_sodexo_reference: filterSodexoPSVsWorkerSodexoRef,
		filter_squad_ids: selectedSquadId,
	}
	let url = "/front/affiliates/sodexo/sodexo_paper_service_voucher_link/create_batch"
	let init = {
		method: "POST",
		body: JSON.stringify(finalQuery),
	}
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_SODEXO_LINKS_BATCH))
	if (actionType === SUBMIT_SODEXO_LINKS_BATCH_SUCCESS) {
		dispatch(fetchAllBookingsForSodexoPSVConflicts())
		dispatch(sodexoPaperServiceVoucherStateChange({ stagedLinks: [] }))
	}
}

export const submitAutomaticFakeLinkingPSV = () => async (dispatch, getState) => {
	let {
		redComponents: {
			sodexoPsvConflictsComponent: {
				rangeStart,
				rangeEnd,
				filterSodexoPSVsWorkerSodexoRef,
				specificWorker,
				selectedSquadId,
				selectedPootsyServiceTab,
			},
		},
	} = getState()
	await dispatch(sodexoPaperServiceVoucherStateChange({ isBatchingFake: true }))

	let finalQuery = {
		service_type: selectedPootsyServiceTab,
		from_date: rangeStart.format("YYYY-MM-DD"),
		to_date: rangeEnd.format("YYYY-MM-DD"),
		filter_worker_sodexo_reference: filterSodexoPSVsWorkerSodexoRef || specificWorker.sodexo,
		filter_squad_ids: selectedSquadId,
	}
	let url = "/front/affiliates/sodexo/sodexo_paper_service_voucher_link/create_batch_fakes"
	let init = {
		method: "POST",
		body: JSON.stringify(finalQuery),
	}
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_SODEXO_LINKS_BATCH))
	dispatch(sodexoPaperServiceVoucherStateChange({ isBatchingFake: false, linkFakeModal: false }))

	if (actionType === SUBMIT_SODEXO_LINKS_BATCH_SUCCESS) {
		dispatch(fetchAllBookingsForSodexoPSVConflicts())
		dispatch(sodexoPaperServiceVoucherStateChange({ stagedLinks: [] }))
	}
}
export const SODEXO_CONFLICT_MODAL_STATE_CHANGE = "SODEXO_CONFLICT_MODAL_STATE_CHANGE"
export const sodexoConflictModalStateChange = changes => ({
	type: SODEXO_CONFLICT_MODAL_STATE_CHANGE,
	changes,
})

export const FETCH_SODEXO_WORK = "FETCH_SODEXO_WORK"
export const FETCH_SODEXO_WORK_REQUEST = "FETCH_SODEXO_WORK_REQUEST"
export const FETCH_SODEXO_WORK_SUCCESS = "FETCH_SODEXO_WORK_SUCCESS"
export const FETCH_SODEXO_WORK_FAILURE = "FETCH_SODEXO_WORK_FAILURE"
export const fetchSodexoWork = id => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/sodexo/sodexo_works/" + id
	dispatch(simpleFetch(url, init, FETCH_SODEXO_WORK))
}

export const SUBMIT_UNLINK_SODEXO_WORK = "SUBMIT_UNLINK_SODEXO_WORK"
export const SUBMIT_UNLINK_SODEXO_WORK_REQUEST = "SUBMIT_UNLINK_SODEXO_WORK_REQUEST"
export const SUBMIT_UNLINK_SODEXO_WORK_SUCCESS = "SUBMIT_UNLINK_SODEXO_WORK_SUCCESS"
export const SUBMIT_UNLINK_SODEXO_WORK_FAILURE = "SUBMIT_UNLINK_SODEXO_WORK_FAILURE"
export const unlinkSodexoWork = id => (dispatch, getState) => {
	let init = { method: "POST", body: JSON.stringify({ id }) }
	let url = "/front/affiliates/sodexo/sodexo_works/unlink_subject"
	dispatch(simpleFetch(url, init, FETCH_SODEXO_WORK))
}
