import React, { useState } from "react"
import { useSelector } from "react-redux"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, LabeledDateTime, PootsySubHeading } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsySelectInput, PootsyTextArea } from "./FunctionalInputs"

import SearchWorker from "./SearchWorker"

export const TrainingModal = ({
	editMode,
	selectedWorkers,
	toggleModal,
	activityCode,
	trainingStart,
	trainingEnd,
	trainingNote,
	activityCategory,
	onChange,
	submit,
	t,
}) => {
	const [times, setTimes] = useState({
		startDate: trainingStart,
		startTime: trainingStart,
		endDate: trainingEnd,
		endTime: trainingEnd,
	})

	const [showRequired, setShowRequired] = useState({
		activityCode: false,
		activityCategory: false,
		trainingStatus: false,
		trainingType: false,
	})

	function handleChanges(changes) {
		onChange(changes)
	}

	const handleWorkerFilter = worker => {
		handleChanges({ selectedWorkers: [...selectedWorkers, worker] })
	}
	const handleRemoveValue = e => {
		const value = parseInt(e.target.dataset.value, 10)

		handleChanges({
			selectedWorkers: selectedWorkers.filter(worker => worker.id !== value),
		})
	}

	const currAffiliate = useSelector(state => state.redData.currentAffiliate)

	function handleStartTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.trainingStartTime.isAfter(times.endTime)) {
			let newEndTime = newTimes.endTime
				.clone()
				.add(changes.trainingStartTime.diff(times.endTime, "minutes") + 5, "minutes")
			newTimes["endTime"] = newEndTime
		}
		newTimes["startTime"] = changes.trainingStartTime
		onChange({
			trainingStart: newTimes.startTime,
			trainingEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleEndTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.trainingEndTime.isBefore(times.startTime)) {
			newTimes["startTime"] = changes.trainingEndTime
		}
		newTimes["endTime"] = changes.trainingEndTime
		onChange({
			trainingStart: newTimes.startTime,
			trainingEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleStartDateChanges(changes) {
		let newTimes = Object.assign({}, times)
		let diff = Math.round(changes.trainingStartDate.diff(times.startTime, "seconds") / 60)
		let newStartDate = newTimes.startTime.clone().add(diff, "minutes")
		if (newStartDate.isAfter(times.endTime)) {
			let newEndDate = newTimes.endTime
				.clone()
				.add(Math.floor(newStartDate.diff(times.endTime, "minutes") / 1440) + 1, "days")
			newTimes["endTime"] = newEndDate
			newTimes["endDate"] = newEndDate
		}
		newTimes["startTime"] = newStartDate
		newTimes["startDate"] = newStartDate
		onChange({
			trainingStart: newTimes.startTime,
			trainingEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleBeginningOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["startTime"] = newTimes.startTime.clone().set({ hours: 0, minutes: 0, seconds: 0 })
		onChange({
			trainingStart: newTimes.startTime,
			trainingEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleEndOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["endTime"] = newTimes.endTime.clone().set({ hours: 23, minutes: 55, seconds: 0 })
		onChange({
			trainingStart: newTimes.startTime,
			trainingEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function showRequiredUpdater(toRequire) {
		let newRequired = { ...showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		setShowRequired(newRequired)
	}

	function submitTraining() {
		let toCheck = [{ activityCode: activityCode }, { activityCategory: activityCategory }],
			valid = true
		let notValid = []
		toCheck.forEach(entry => {
			if (Object.values(entry)[0] === "") {
				notValid.push(Object.keys(entry)[0])
				valid = false
			}
		})
		if (valid) {
			submit()
		} else showRequiredUpdater(notValid)
	}

	let buttons = [
		<PootsyButton key="a" text={t("save")} onClick={submitTraining} />,
		<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={toggleModal} />,
	]
	let filteredCodes = currAffiliate.secSocEvents.codes
	if (activityCategory) {
		filteredCodes = filteredCodes.filter(entry => entry.category_id === activityCategory)
	}

	const possibleCategories = currAffiliate.secSocEvents.categories.filter(
		category => category.id !== "group_s_benefit"
	)

	return (
		<InputsChangeHandler onChange={handleChanges} customClass="cleaner-absence-modal">
			<ModalLayout
				title={editMode ? t("edit_training") : t("create_new_training")}
				formDivClass="absence-modal-inputs"
				buttons={buttons}
				closeModal={toggleModal}
			>
				<div className="pootsy-multiselect">
					{!editMode && (
						<SearchWorker
							onClick={handleWorkerFilter}
							searchOnlyActiveWorkers={true}
							label="search_cleaners"
						/>
					)}
					{selectedWorkers.length > 0 && (
						<div className="pootsy-multiselect-current-filters">
							<div className="pootsy-multiselect-results-label">
								{t("selected_workers")}
							</div>
							{selectedWorkers.map(worker => (
								<span
									key={worker.id}
									data-value={worker.id}
									className={
										editMode
											? "pootsy-multiselect-results-label"
											: "pootsy-multiselect-current-filter"
									}
									onClick={handleRemoveValue}
								>
									{worker.attributes.display_name}
								</span>
							))}
						</div>
					)}
				</div>
				<div className="start-date-group">
					<LabeledDateTime
						label={t("start_date")}
						timePickerProps={{
							value: times.startTime,
							name: "trainingStartTime",
							onChange: handleStartTimeChanges,
						}}
						datePickerProps={{
							value: times.startDate,
							name: "trainingStartDate",
							onChange: handleStartDateChanges,
						}}
					/>
					<PootsyButton
						text={t("beginning_of_day")}
						theme="cancel"
						size="small"
						onClick={handleBeginningOfDay}
					/>
				</div>
				<div className="end-date-group">
					<LabeledDateTime
						label={t("end_time")}
						timePickerProps={{
							value: times.endTime,
							name: "trainingEndTime",
							onChange: handleEndTimeChanges,
						}}
						showDatePicker={false}
					/>
					<PootsyButton
						text={t("end_of_day")}
						theme="cancel"
						size="small"
						onClick={handleEndOfDay}
					/>
				</div>
				<PootsyTextArea label={t("comment")} name="trainingNote" value={trainingNote} />
				<PootsySubHeading text={t("select_impacted_bookings_action")} />
				<PootsySelectInput
					name="activityCategory"
					defaultText={t("activity_category")}
					selected={activityCategory}
					showRequired={showRequired.activityCategory}
					options={possibleCategories.map(entry => ({
						label: t(entry.label),
						value: entry.id,
					}))}
				/>
				<PootsySelectInput
					name="activityCode"
					defaultText={t("activity_type")}
					customClass="ss-code"
					selected={activityCode}
					showRequired={showRequired.activityCode}
					options={filteredCodes
						.sort((a, b) => t(a.label).localeCompare(t(b.label)))
						.map(entry => ({
							label: entry.id + " - " + t(entry.label),
							value: entry.id,
						}))}
				/>
			</ModalLayout>
		</InputsChangeHandler>
	)
}
