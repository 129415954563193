import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import * as actions from "../actions"

import { showScanBtn } from "../config"

import SlideIn from "./SlideIn"
import {
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	PootsyButton,
	NotificationCard,
	NoCSSInfoBox,
	SmallSpinnerSection,
} from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsySelectInput } from "./FunctionalInputs"
import InputsChangeHandler from "./InputsChangeHandler"
import DateFilterer from "./DateFilterer"
import Paginator from "./Paginator"
import ModalLayout from "./ModalLayout"
import SearchWorker from "./SearchWorker"
import PSVScanner from "./PSVScanner"

class SodexoPSVConflicts extends Component {
	state = {
		showRemovePSVModal: false,
		psv: undefined,
		showFakeLinkModal: false,
		isFilterActivated: false,
	}
	componentDidMount = () => {
		let { currUser } = this.props
		if (currUser.teams[0]) {
			this.handleStateChanges({ selectedSquadId: currUser.teams[0].id })
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetQuery())
	}
	currentStagedServiceLink = id => {
		return this.props.state.stagedLinks.find(entry => entry.service.id === id)
	}
	currentTotalPsvCountStagedForService = id => {
		let currentStagedLink = this.currentStagedServiceLink(id)
		if (!currentStagedLink) {
			return 0
		}
		return currentStagedLink.psvLinks.reduce((acc, entry) => acc + entry.count, 0)
	}
	missingPsvForCurrentServiceCandidate = service => {
		if (service.voucherCount) {
			return service.voucherCount - service.pServiceVouchersAffiliate.length
		} else if (service.bestKnownBillableSV) {
			return service.bestKnownBillableSV - service.psvs.length
		}
	}
	psvTakenFromGroup = id => {
		return this.props.state.stagedLinks.reduce((acc, entry) => {
			entry.psvLinks.forEach(entry => {
				if (entry.psvId === id) {
					acc += entry.count
				}
			})
			return acc
		}, 0)
	}
	psvLeftOnGroup = group => {
		return group.paperServiceVouchers.length - this.psvTakenFromGroup(group.id)
	}
	fetchAllBookings = () => {
		this.props.dispatch(actions.fetchAllBookingsForSodexoPSVConflicts())
		this.setState({ isFilterActivated: true })
	}
	handleChangeSquad = e => {
		this.handleStateChanges({ selectedSquadId: e.target.value, pBookingsPage: 1 })
		this.fetchAllBookings()
	}
	handleStateChanges = changes => {
		this.props.dispatch(actions.sodexoPaperServiceVoucherStateChange(changes))
	}
	handleSmartFilterChange = e => {
		this.handleStateChanges({ isSmartFiltersActive: e.target.checked })
	}
	handleDateFilterChange = changes => {
		let newObj = {
			rangeStart: changes.from,
			rangeEnd: changes.to,
			pBookingsPage: 1,
			sPSVsPage: 1,
			pWorkshopOrdersPage: 1,
		}
		this.handleStateChanges(newObj)
		this.fetchAllBookings()
	}
	selectPootsyServiceTab = tab => {
		this.handleStateChanges({ selectedPootsyServiceTab: tab })
		this.setState({ isFilterActivated: false })
	}
	openRemoveLinkablePsvModal = (e, psv) => {
		e.stopPropagation()
		this.setState({ showRemovePSVModal: true, psv: psv })
	}
	closeRemovePSVModal = () => {
		this.handleStateChanges({
			showSodexoStagedPsv: false,
			sPSVsPage: 1,
			psvCandidate: undefined,
		})
		this.setState({ showRemovePSVModal: false, psv: undefined })
		this.props.dispatch(actions.fetchPaperServiceVouchersForSodexoPSVConflicts())
	}
	submitDeletePsv = async psv => {
		let init = { method: "DELETE" }
		let url = `/front/affiliates/sodexo/sodexo_paper_service_vouchers/${psv.id}`
		let { actionType, data } = await this.props.dispatch(
			actions.simpleFetch(url, init, "REMOVE_SODEXO_PSV")
		)
		if (actionType === "REMOVE_SODEXO_PSV_SUCCESS") {
			let newPsv = this.state.psv
			newPsv.paperServiceVouchers = newPsv.paperServiceVouchers.filter(
				e => e.id !== data.destroyed_id
			)
			this.setState({ psv: newPsv })
			if (newPsv.paperServiceVouchers.length === 0) {
				this.closeRemovePSVModal()
			}
		}
	}
	handleSearchSpecificWorker = workerName => {
		let {
			state: { specificWorker },
		} = this.props
		this.handleStateChanges({
			specificWorker: { name: workerName, sodexo: "" },
			filterSodexoBookingsWorkerSodexoRef: null,
			filterPootsyBookingsWorkerSodexoRef: null,
		})
		if (specificWorker.sodexo) {
			// worker was previously selected
			this.fetchAllBookings()
		}
	}
	handleSpecificWorkerClick = worker => {
		let newSpecWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
		}
		this.handleStateChanges({ specificWorker: newSpecWorker, language: "" })
		this.fetchAllBookings()
	}
	nominatePootsyBooking = booking => {
		let {
			dispatch,
			state: { isSmartFiltersActive, showSodexoStagedPsv },
		} = this.props
		this.handleStateChanges({ bookingCandidate: { ...booking }, showPootsyStagedBooking: true })
		if (isSmartFiltersActive && !showSodexoStagedPsv) {
			// if the sodexo booking was already staged we don't refetch the list
			this.handleStateChanges({
				filterSodexoBookingsCustomerSodexoRef: booking.customerSodexoRef,
				filterSodexoBookingsWorkerSodexoRef: booking.workerSodexoRef,
				sPSVsPage: 1,
			})
			dispatch(actions.fetchPaperServiceVouchersForSodexoPSVConflicts())
		}
	}
	nominateWorkshopOrder = workshopOrder => {
		let {
			dispatch,
			state: { isSmartFiltersActive, showSodexoStagedPsv },
		} = this.props
		this.handleStateChanges({
			workshopOrderCandidate: workshopOrder,
			showPootsyStagedWorkshopOrder: true,
		})
		if (isSmartFiltersActive && !showSodexoStagedPsv) {
			// if the sodexo booking was already staged we don't refetch the list
			this.handleStateChanges({
				filterSodexoBookingsCustomerSodexoRef: workshopOrder.customerSodexoRef,
				filterSodexoBookingsWorkerSodexoRef: workshopOrder.workerSodexoRef,
				sPSVsPage: 1,
			})
			dispatch(actions.fetchPaperServiceVouchersForSodexoPSVConflicts())
		}
	}
	nominatePsv = psv => {
		let {
			dispatch,
			state: {
				isSmartFiltersActive,
				showPootsyStagedBooking,
				showPootsyStagedWorkshopOrder,
				selectedPootsyServiceTab,
			},
		} = this.props
		let psvsLeftOnGroup = this.psvLeftOnGroup(psv)
		if (psvsLeftOnGroup === 0) {
			return
		}
		// console.log(psv)
		this.handleStateChanges({ psvCandidate: { ...psv }, showSodexoStagedPsv: true })
		if (isSmartFiltersActive && !showPootsyStagedBooking && !showPootsyStagedWorkshopOrder) {
			// if the pootsy booking was already staged we don't refetch the list
			this.handleStateChanges({
				filterPootsyBookingsCustomerSodexoRef: psv.customerSodexoRef,
				filterPootsyBookingsWorkerSodexoRef: psv.workerSodexoRef,
				pBookingsPage: 1,
			})
			if (selectedPootsyServiceTab === "bookings") {
				dispatch(actions.fetchPootsyBookingsForPaperServiceVouchers())
			} else {
				dispatch(actions.fetchPootsyWorkshopOrdersForSodexoPsvConflicts())
			}
		}
	}
	submitLinks = () => {
		this.props.dispatch(actions.submitSodexoPaperServiveVouchersLinks())
	}
	handleRemoveServiceCandidate = () => {
		let {
			state: { showSodexoStagedPsv },
		} = this.props
		this.handleStateChanges({
			showPootsyStagedBooking: false,
			showPootsyStagedWorkshopOrder: false,
			filterSodexoBookingsCustomerSodexoRef: null,
			filterSodexoBookingsWorkerSodexoRef: null,
			sPSVsPage: 1,
			bookingCandidate: undefined,
			workshopOrderCandidate: undefined,
		})
		if (this.props.state.isSmartFiltersActive && !showSodexoStagedPsv) {
			// if the sodexo booking is still staged we don't need to refetch
			this.props.dispatch(actions.fetchPaperServiceVouchersForSodexoPSVConflicts())
		}
	}
	handleRemovePsvCandidate = () => {
		let {
			state: {
				showPootsyStagedBooking,
				showPootsyStagedWorkshopOrder,
				selectedPootsyServiceTab,
			},
		} = this.props
		this.handleStateChanges({
			showSodexoStagedPsv: false,
			filterPootsyBookingsCustomerSodexoRef: null,
			filterPootsyBookingsWorkerSodexoRef: null,
			pBookingsPage: 1,
			psvCandidate: undefined,
		})
		if (
			this.props.state.isSmartFiltersActive &&
			!showPootsyStagedBooking &&
			!showPootsyStagedWorkshopOrder
		) {
			// if the pootsy booking is still staged we don't need to refetch
			if (selectedPootsyServiceTab === "bookings") {
				this.props.dispatch(actions.fetchPootsyBookingsForPaperServiceVouchers())
			} else {
				this.props.dispatch(actions.fetchPootsyWorkshopOrdersForSodexoPsvConflicts())
			}
		}
	}
	handleChangePBookingsPage = changes => {
		this.handleStateChanges({ pBookingsPage: changes.value })
		this.props.dispatch(actions.fetchPootsyBookingsForPaperServiceVouchers())
	}
	handleChangePWorkshopOrdersPage = changes => {
		this.handleStateChanges({ pWorkshopOrdersPage: changes.value })
		this.props.dispatch(actions.fetchPootsyWorkshopOrdersForSodexoPsvConflicts())
	}
	handleChangeSBookingsPage = changes => {
		this.handleStateChanges({ sPSVsPage: changes.value })
		this.props.dispatch(actions.fetchPaperServiceVouchersForSodexoPSVConflicts())
	}
	stageServiceLink = () => {
		let {
			state: {
				stagedLinks,
				psvCandidate,
				bookingCandidate,
				workshopOrderCandidate,
				selectedPootsyServiceTab,
			},
		} = this.props

		let service = bookingCandidate
		let serviceType = "booking"
		if (selectedPootsyServiceTab === "workshop_orders") {
			service = workshopOrderCandidate
			serviceType = "workshop_order"
		}
		let psvsLeftOnGroup = this.psvLeftOnGroup(psvCandidate)
		let existingLink = this.currentStagedServiceLink(service.id)

		let newStagedLinks = undefined
		let missingPsvOnStagedService = this.missingPsvForCurrentServiceCandidate(service)
		if (existingLink) {
			let currentMissingPsvsForService =
				missingPsvOnStagedService - this.currentTotalPsvCountStagedForService(service.id)

			let psvToTake = Math.min(psvsLeftOnGroup, currentMissingPsvsForService)
			newStagedLinks = stagedLinks.map(entry => {
				if (entry.id === existingLink.id) {
					return {
						...entry,
						psvLinks: [...entry.psvLinks, { psvId: psvCandidate.id, count: psvToTake }],
					}
				} else {
					return entry
				}
			})
		} else {
			let stagedLink = {
				id: Math.floor(Math.random() * 1000000000000),
				serviceType: serviceType,
				service: service,
				psvLinks: [
					{
						psvId: psvCandidate.id,
						count: Math.min(psvsLeftOnGroup, missingPsvOnStagedService),
					},
				],
			}
			newStagedLinks = [...stagedLinks, stagedLink]
		}

		this.handleStateChanges({
			stagedLinks: newStagedLinks,
			showSodexoStagedPsv: false,
			showPootsyStagedBooking: false,
			showPootsyStagedWorkshopOrder: false,
		})
	}
	handleRemoveStagedLink = stagedLinkID => {
		let { stagedLinks } = this.props.state
		this.handleStateChanges({
			stagedLinks: stagedLinks.filter(entry => entry.id !== stagedLinkID),
		})
	}
	renderLinkableBookingOutline = show => {
		return (
			<SlideIn slideDirection="to-top" show={show}>
				<div className="linkable-service-outline">
					<div className="column">
						<div className="line" />
						<div className="line big" />
					</div>
					<div className="column">
						<div className="line" />
						<div className="line big" />
					</div>
				</div>
			</SlideIn>
		)
	}
	renderLinkableBooking = ({ show, booking, onClick = () => {} }) => {
		if (!booking || !show) {
			return null
		}
		let { t } = this.context
		let finalClass = "linkable-service"
		finalClass += onClick ? " clickable" : ""

		let missingPsvsForBooking = booking.voucherCount - booking.pServiceVouchersAffiliate.length
		let currentStagedPsvTotal = this.currentTotalPsvCountStagedForService(booking.id)
		if (currentStagedPsvTotal === missingPsvsForBooking) {
			// do not show the booking in the list if linked with enough psvs
			return null
		}
		if (currentStagedPsvTotal > 0) {
			finalClass += " staged-for-linking"
		}

		let pPSVcount = booking.pServiceVouchersAffiliate.length
		return (
			<SlideIn key={booking.id}>
				<div
					className={finalClass}
					onClick={() => {
						onClick(booking)
					}}
				>
					<div className="column">
						<div className="date">{booking.deliveryDate}</div>
						<div className="billable-hours">
							{t("service_vouchers") +
								": " +
								pPSVcount +
								" / " +
								booking.voucherCount}
						</div>
					</div>
					<div className="column">
						<div className="customer">{booking.customerName}</div>
						<div className="worker">{t("cleaner") + ": " + booking.workerName}</div>
					</div>
				</div>
			</SlideIn>
		)
	}
	renderLinkableWorkshopOrder = ({ show, workshopOrder, onClick = () => {} }) => {
		if (!workshopOrder || !show) {
			return null
		}
		let { t } = this.context
		let finalClass = "linkable-service"
		finalClass += onClick ? " clickable" : ""

		let missingPsvsForWo = workshopOrder.bestKnownBillableSV - workshopOrder.psvs.length
		let currentStagedPsvTotal = this.currentTotalPsvCountStagedForService(workshopOrder.id)
		if (currentStagedPsvTotal === missingPsvsForWo) {
			// do not show the workshopOrder in the list if linked with enough psvs
			return null
		}
		if (currentStagedPsvTotal > 0) {
			finalClass += " staged-for-linking"
		}

		let pPSVcount = workshopOrder.psvs.length
		return (
			<SlideIn key={workshopOrder.id}>
				<div
					className={finalClass}
					onClick={() => {
						onClick(workshopOrder)
					}}
				>
					<div className="column">
						<div className="date">
							{workshopOrder.executionDate.format("YYYY-MM-DD")}
						</div>
						<div className="billable-hours">
							{t("service_vouchers") +
								": " +
								pPSVcount +
								" / " +
								workshopOrder.bestKnownBillableSV}
						</div>
					</div>
					<div className="column">
						<div className="customer">{workshopOrder.customerName}</div>
						<div className="worker">
							{t("cleaner") + ": " + workshopOrder.workerName}
						</div>
					</div>
				</div>
			</SlideIn>
		)
	}
	renderStagedLink = stagedLink => {
		let { t } = this.context

		let date =
			stagedLink.service.deliveryDate || stagedLink.service.executionDate.format("YYYY-MM-DD")
		let totalPsvExpected =
			stagedLink.service.voucherCount || stagedLink.service.bestKnownBillableSV
		let totalPsvAlreadyLinked = (
			stagedLink.service.pServiceVouchersAffiliate || stagedLink.service.psvs
		).length
		return (
			<div className="staged-link">
				<div className="column">
					<div className="date">{date}</div>
					<div className="billable-hours">
						{t("service_vouchers")} : {totalPsvAlreadyLinked} / {totalPsvExpected}
						<div className="psv-added">
							+ {stagedLink.psvLinks.reduce((acc, entry) => acc + entry.count, 0)}
						</div>
					</div>
				</div>
				<div className="column">
					<div className="customer">{stagedLink.service.customerName}</div>
					<div className="worker">
						{t("cleaner") + ": " + stagedLink.service.workerName}
					</div>
				</div>
				<div className="column">{t(stagedLink.serviceType)}</div>
				<PootsyButton
					size="small"
					text={t("remove")}
					onClick={() => this.handleRemoveStagedLink(stagedLink.id)}
				/>
			</div>
		)
	}
	renderLinkablePSV = ({ show, psv, onClick = () => {} }) => {
		let { t } = this.context
		let finalClass = "linkable-service"
		finalClass += onClick ? " clickable" : ""

		let psvsLeftOnGroup = this.psvLeftOnGroup(psv)
		if (psvsLeftOnGroup !== psv.paperServiceVouchers.length) {
			finalClass += " staged-for-linking"
		}
		return (
			<SlideIn key={psv.id} show={show}>
				<div className={finalClass} onClick={() => onClick(psv)}>
					<div className="column">
						<div className="date">{psv.deliveryDate}</div>
						<div className="billable-hours">
							{t("psv_left")} : {psvsLeftOnGroup} / {psv.paperServiceVouchers.length}
						</div>
						<div className="warning-expired">
							{psv.paperServiceVouchers.some(voucher => voucher.is_expired)
								? t("expired")
								: ""}
						</div>
						<div className="codes">
							{psv.paperServiceVouchers.map(p => (
								<div key={p.id}>{p.code}</div>
							))}
						</div>
					</div>
					<div className="column">
						<div className="customer">{t("customer") + ": " + psv.customerName}</div>
						<div className="worker">
							{psv.workerName && t("cleaner") + ": " + psv.workerName}
						</div>
					</div>
					<div className="column">
						<div
							className="remove-button"
							onClick={e => {
								this.openRemoveLinkablePsvModal(e, psv)
							}}
						>
							X
						</div>
					</div>
				</div>
			</SlideIn>
		)
	}
	renderPsvCandidate = ({ show, psv, onClick = () => {} }) => {
		if (!show) {
			return null
		}
		let { t } = this.context
		let clickHandler = () => {
			onClick(psv.id)
		}
		let finalClass = "linkable-service"
		finalClass += onClick ? " clickable" : ""
		let psvsLeftOnGroup = this.psvLeftOnGroup(psv)
		if (psvsLeftOnGroup !== psv.paperServiceVouchers.length) {
			finalClass += " staged-for-linking"
		}
		return (
			<SlideIn key={psv.id} show={show}>
				<div className={finalClass} onClick={clickHandler}>
					<div className="column">
						<div className="date">{psv.deliveryDate}</div>
						<div className="billable-hours">
							{t("psv_left")} : {psvsLeftOnGroup} / {psv.paperServiceVouchers.length}
						</div>
						<div className="codes">
							{psv.paperServiceVouchers.map(p => (
								<div key={p.id}>{p.code}</div>
							))}
						</div>
					</div>
					<div className="column">
						<div className="customer">{t("customer") + ": " + psv.customerName}</div>
						<div className="worker">
							{psv.workerName && t("cleaner") + ": " + psv.workerName}
						</div>
					</div>
				</div>
			</SlideIn>
		)
	}
	render() {
		let {
			pootsyBookings,
			pootsyWorkshopOrders,
			sodexoPaperServiceVouchers,
			currAffiliate,
			state: {
				selectedPootsyServiceTab,
				psvCandidate,
				bookingCandidate,
				workshopOrderCandidate,
				showPootsyStagedBooking,
				showSodexoStagedPsv,
				showPootsyStagedWorkshopOrder,
				isSmartFiltersActive,
				stagedLinks,
				rangeStart,
				rangeEnd,
				today,
				pBookingsPage,
				pWorkshopOrdersPage,
				pWorkshopOrdersPagination,
				sPSVsPage,
				pBookingsPagination,
				sPSVsPagination,
				specificWorker,
				selectedSquadId,
				requestingFakePSVLinking,
				showFakeLinkModal,
				pTotalPSV,
			},
		} = this.props
		let { t } = this.context
		let optionBoxes = (
			<OptionBox>
				<PootsySelectInput
					name="squad"
					allowEmpty={true}
					defaultText={t("squads")}
					options={currAffiliate.squads.map(entry => ({
						label: entry.name,
						value: entry.id,
					}))}
					selected={selectedSquadId}
					onChange={this.handleChangeSquad}
				/>
				<PootsySubHeading text={t("date_filters")} />
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					rangeLimitEnd={today}
					onChange={this.handleDateFilterChange}
				/>
				<PootsySubHeading text={t("smart_filters")} />
				<PootsyCheckboxInput
					name="isSmartFiltersActive"
					label={t("smart_filters")}
					checked={isSmartFiltersActive}
					onChange={this.handleSmartFilterChange}
					disabled={showPootsyStagedBooking || showSodexoStagedPsv}
				/>

				{sodexoPaperServiceVouchers.length > 0 &&
					showScanBtn &&
					this.state.isFilterActivated && (
						<PootsyButton
							text={t("link_automatically")}
							onClick={() =>
								this.setState({ showAutoLinkModal: !this.state.showAutoLinkModal })
							}
						/>
					)}
				{showScanBtn && (
					<PootsyButton
						text={t("link_to_fake")}
						onClick={() =>
							this.handleStateChanges({ showFakeLinkModal: !showFakeLinkModal })
						}
					/>
				)}
				{showFakeLinkModal && (
					<ModalLayout
						title={t("link_to_fake")}
						customClass="link-fake-psv-modal"
						closeModal={() =>
							this.handleStateChanges({ showFakeLinkModal: !showFakeLinkModal })
						}
					>
						<PootsySubHeading text={t("linking_to_fake_explanation")} />
						{selectedPootsyServiceTab && (
							<NoCSSInfoBox
								label={t("service_type")}
								value={t(selectedPootsyServiceTab)}
							/>
						)}
						{pTotalPSV && pTotalPSV > 0 && (
							<NoCSSInfoBox label={t("total_psv_affected")} value={pTotalPSV} />
						)}
						<NoCSSInfoBox
							label={t("total_bookings_affected")}
							value={
								selectedPootsyServiceTab === "bookings"
									? pBookingsPagination.total_objects
									: pWorkshopOrdersPagination.total_objects
							}
						/>
						<PootsyButton
							text={t("link_to_fake")}
							fetching={requestingFakePSVLinking}
							onClick={() => {
								this.props.dispatch(actions.submitAutomaticFakeLinkingPSV())
							}}
						/>
					</ModalLayout>
				)}
				{this.state.showAutoLinkModal && (
					<ModalLayout
						title={t("link_to_automatic")}
						formDivClass="auto-link-psv-modal"
						closeModal={() =>
							this.setState({ showAutoLinkModal: !this.state.showAutoLinkModal })
						}
					>
						<PootsySubHeading text={t("linking_to_automatic_explanation")} />
						<NoCSSInfoBox
							label={t("service_type")}
							value={t(selectedPootsyServiceTab)}
						/>
						<NoCSSInfoBox label={t("total_psv_affected")} value={pTotalPSV} />
						<NoCSSInfoBox
							label={t("total_bookings_affected")}
							value={
								selectedPootsyServiceTab === "bookings"
									? pBookingsPagination.total_objects
									: pWorkshopOrdersPagination.total_objects
							}
						/>

						<PootsyButton
							text={t("link_automatically")}
							onClick={() => {
								this.props.dispatch(actions.submitAutomaticLinkingPSV())
								this.setState({ showAutoLinkModal: !this.state.showAutoLinkModal })
							}}
						/>
					</ModalLayout>
				)}
			</OptionBox>
		)

		let notifButtons = [
			<PootsyButton key="a" text={t("edit_booking_button")} onClick={this.submitLinks} />,
		]
		return (
			<InputsChangeHandler
				customClass="SodexoPSVConflicts"
				onChange={this.handleStateChanges}
			>
				<ContentLayout customClass="sodexo-conflicts" optionsBoxes={optionBoxes}>
					{this.state.showRemovePSVModal && (
						<ModalLayout
							title={t("delete_psv")}
							closeModal={() => this.closeRemovePSVModal()}
							formDivClass="delete-psv-modal"
						>
							{this.state.psv.paperServiceVouchers.map(psv => (
								<div key={psv.id} onClick={() => this.submitDeletePsv(psv)}>
									{psv.code}
								</div>
							))}
						</ModalLayout>
					)}
					<NotificationCard
						key="notif"
						text={t("notif_unsaved_changes")}
						active={stagedLinks.length > 0}
						buttons={notifButtons}
					/>
					<div className="inputs-area">
						<SearchWorker
							onChange={this.handleSearchSpecificWorker}
							onClick={this.handleSpecificWorkerClick}
							value={specificWorker && specificWorker.name}
						/>
						<PSVScanner
							scanningFor="booking"
							workerSodexoRef={specificWorker && specificWorker.sodexo}
							afterSubmitAction={actions.fetchAllBookingsForSodexoPSVConflicts}
							showImportFile={true}
							// it's only needed to pass context to psvscanner
							t={t("send_psv")}
						/>
					</div>
					<div className="staged-links">
						{stagedLinks.length > 0 && (
							<PootsySubHeading text={t("staged_booking_links")} />
						)}
						{stagedLinks.map(entry => (
							<SlideIn key={entry.id}>{this.renderStagedLink(entry)}</SlideIn>
						))}
					</div>
					<div className="staging-area">
						<div className="staged-pootsy-booking">
							{this.renderLinkableBookingOutline(
								!showPootsyStagedBooking && !showPootsyStagedWorkshopOrder
							)}
							{showPootsyStagedBooking
								? this.renderLinkableBooking({
										show: showPootsyStagedBooking,
										booking: bookingCandidate,
										onClick: this.handleRemoveServiceCandidate,
								  })
								: this.renderLinkableWorkshopOrder({
										show: showPootsyStagedWorkshopOrder,
										workshopOrder: workshopOrderCandidate,
										onClick: this.handleRemoveServiceCandidate,
								  })}
						</div>
						<div className="link">
							<div
								className={
									"link-line" +
									((showPootsyStagedBooking || showPootsyStagedWorkshopOrder) &&
									showSodexoStagedPsv
										? " pink"
										: " grey")
								}
							/>
							<PootsyButton
								text={t("link_sodexo_booking")}
								onClick={this.stageServiceLink}
								disabled={
									(!showPootsyStagedBooking && !showPootsyStagedWorkshopOrder) ||
									!showSodexoStagedPsv
								}
							/>
						</div>
						<div className="staged-sodexo-booking">
							{this.renderLinkableBookingOutline(!showSodexoStagedPsv)}
							{this.renderPsvCandidate(
								{
									psv: psvCandidate,
									show: showSodexoStagedPsv,
									onClick: this.handleRemovePsvCandidate,
								},
								true
							)}
						</div>
					</div>
					{currAffiliate.workshopAddOn && (
						<div className="tabs">
							{/* center that */}
							<div
								className={
									"tab bookings " +
									(selectedPootsyServiceTab === "bookings" ? "selected" : "")
								}
								onClick={() => this.selectPootsyServiceTab("bookings")}
							>
								{t("bookings")}
							</div>
							<div
								className={
									"tab workshop-orders " +
									(selectedPootsyServiceTab === "workshop_orders"
										? "selected"
										: "")
								}
								onClick={() => this.selectPootsyServiceTab("workshop_orders")}
							>
								{t("workshop_orders")}
							</div>
						</div>
					)}
					<div className="conflict-boxes">
						{selectedPootsyServiceTab === "bookings" && (
							<div className="pootsy-bookings">
								{!this.state.isFilterActivated && (
									<PootsySubHeading text={t("placeholder_bookings_list")} />
								)}
								{pTotalPSV > 0 && (
									<PootsySubHeading
										text={
											t("pootsy_bookings") +
											" (" +
											pTotalPSV +
											" " +
											t("psv_left") +
											")"
										}
									/>
								)}
								{this.state.isFilterActivated && (
									<div>
										<SmallSpinnerSection
											isLoading={this.props.state.fetchingBookings}
											sectionClass={"small-spinner-section"}
										>
											{pootsyBookings
												.filter(
													entry =>
														!showPootsyStagedBooking ||
														entry.id !== bookingCandidate.id
												)
												.map((entry, i) =>
													this.renderLinkableBooking({
														show: true,
														booking: entry,
														onClick: this.nominatePootsyBooking,
													})
												)}
										</SmallSpinnerSection>
										<Paginator
											currPage={pBookingsPage}
											totalPages={pBookingsPagination.total_pages}
											goToPage={this.handleChangePBookingsPage}
										/>
									</div>
								)}
							</div>
						)}
						{selectedPootsyServiceTab === "workshop_orders" && (
							<div className="pootsy-workshop-orders">
								{!this.state.isFilterActivated && (
									<PootsySubHeading text={t("placeholder_workshopOrders_list")} />
								)}
								{pTotalPSV > 0 && (
									<PootsySubHeading
										text={
											t("pootsy_workshop_orders") +
											" (" +
											pTotalPSV +
											" " +
											t("psv_left") +
											")"
										}
									/>
								)}
								{this.state.isFilterActivated && (
									<div>
										<SmallSpinnerSection
											isLoading={this.props.state.fetchingBookings}
										>
											{pootsyWorkshopOrders
												.filter(
													entry =>
														!(
															showPootsyStagedWorkshopOrder &&
															entry.id === workshopOrderCandidate.id
														)
												)
												.map((entry, i) =>
													this.renderLinkableWorkshopOrder({
														show: true,
														workshopOrder: entry,
														onClick: this.nominateWorkshopOrder,
													})
												)}
										</SmallSpinnerSection>
										<Paginator
											currPage={pWorkshopOrdersPage}
											totalPages={pWorkshopOrdersPagination.total_pages}
											goToPage={this.handleChangePWorkshopOrdersPage}
										/>
									</div>
								)}
							</div>
						)}
						{this.state.isFilterActivated && (
							<div className="sodexo-psvs">
								<PootsySubHeading text={t("scanned_paper_service_vouchers")} />
								<SmallSpinnerSection isLoading={this.props.state.fetchingPSVs}>
									{sodexoPaperServiceVouchers.map((entry, i) =>
										this.renderLinkablePSV({
											psv: entry,
											onClick: this.nominatePsv,
										})
									)}
								</SmallSpinnerSection>
								{sPSVsPagination && (
									<Paginator
										currPage={sPSVsPage}
										totalPages={sPSVsPagination.total_pages}
										goToPage={this.handleChangeSBookingsPage}
									/>
								)}
							</div>
						)}
					</div>
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

SodexoPSVConflicts.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	state: state.redComponents.sodexoPsvConflictsComponent,
	pootsyBookings: state.redData.bookings,
	pootsyWorkshopOrders: state.redData.workshopOrders,
	sodexoPaperServiceVouchers: state.redData.sodexoPaperServiceVouchers,
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(SodexoPSVConflicts)
