import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import ReactGA from "react-ga"
import { showDimona, showWorkshop, activatePageViewLogging, tabTitle } from "../config"

import * as actions from "../actions"
import { history } from "../history"

import { PootsyButton, ErrorBox, WarningModal } from "../components/FunctionalDesign"

import TopNav from "../components/TopNav"

import Dashboard from "../components/Dashboard"
import About from "../components/About"
import Agenda from "../components/Fagenda"
import BookingWishes from "../components/BookingWishes"
import Bookings from "../components/Bookings"
import BookingDetails from "../components/BookingDetails"
import NewBooking from "../components/NewBooking"
import CustomerDetails from "../components/CustomerDetails"
import NewWorker from "../components/NewWorker"
import Workers from "../components/Workers"
import WorkerPage from "../components/WorkerPage"
import { Communication } from "../components/Communication"
import Customers from "../components/Customers"
import PaymentReminderPage from "../components/PaymentReminderPage"
import Timesheets from "../components/Timesheets"
import Sodexo from "../components/Sodexo"
import Workshop from "../components/Workshop"
import NewWorkshopOrder from "../components/NewWorkshopOrder"
import Settings from "../components/Settings"
import Documents from "../components/Documents"
import WorkshopOrderDetails from "../components/WorkshopOrderDetails"
import NewPSVGroup from "../components/NewPSVGroup"
import UpdatePSVDeliveryGroup from "../components/UpdatePSVDeliveryGroup"
import PSVDeliveryDetails from "../components/PSVDeliveryDetails"
import { LocaleContext } from "../index.js"
import TrainingsPage from "../components/TrainingsPage"

let logPageView = () => {}
if (activatePageViewLogging) {
	ReactGA.initialize("UA-80708445-2")
	logPageView = () => {
		ReactGA.set({ page: window.location.pathname + window.location.search })
		ReactGA.pageview(window.location.pathname + window.location.search)
	}
}
history.listen(logPageView)

export class App extends Component {
	componentDidMount = () => {
		let {
			dispatch,
			redData: { currentAffiliate },
		} = this.props
		dispatch(actions.setLang(currentAffiliate.defaultLanguage))
		dispatch(actions.fetchRepacementTranslations())
		document.title = tabTitle
	}
	acknowledgeErrors = () => {
		this.props.dispatch(actions.acknowledgeErrors())
	}
	acknowledgeWarnings = () => {
		this.props.dispatch(actions.acknowledgeWarnings())
	}
	render() {
		let { t } = this.context
		let {
			appMeta,
			flashMessage,
			backendErrors,
			backendWarnings,
			locale,
			location,
			redData: { currentAffiliate },
		} = this.props
		return (
			<LocaleContext.Provider value={t}>
				<div className="wrapper">
					{appMeta.frozen && (
						<div
							style={{
								position: "fixed",
								top: 0,
								right: 0,
								padding: 10,
								background: "red",
								zIndex: 2,
							}}
						>
							FROZEN
						</div>
					)}
					{locale.translationsFetched && (
						<Fragment>
							<Route path="/" component={TopNav} />
							<Route exact path="/" component={Agenda} />
							<Route exact path="/booking-wishes" component={BookingWishes} />
							<Route exact path="/dashboard" component={Dashboard} />
							<Route path="/about" component={About} />
							<Route exact path="/bookings" component={Bookings} />
							<Switch>
								<Route exact path="/bookings/new" component={NewBooking} />
								<Route path="/bookings/:id" component={BookingDetails} />
							</Switch>
							{showWorkshop && currentAffiliate.workshopAddOn && (
								<Fragment>
									<Switch>
										<Route
											exact
											path="/workshop/new"
											component={NewWorkshopOrder}
										/>
										<Route
											path="/workshop/:id(\d+)"
											component={WorkshopOrderDetails}
										/>
										<Route path="/workshop" component={Workshop} />
									</Switch>
								</Fragment>
							)}
							<Route exact path="/cleaners" component={Workers} />
							<Route exact path="/communication" component={Communication} />
							<Switch>
								<Route exact path="/cleaners/new" component={NewWorker} />
								<Route path="/cleaners/:id" component={WorkerPage} />
							</Switch>
							<Switch>
								<Route
									path="/customers/payment_reminders/:id(\d+)"
									component={PaymentReminderPage}
								/>
								<Route path="/customers/:id(\d+)" component={CustomerDetails} />
								<Route path="/customers" component={Customers} />
							</Switch>
							<Route path="/settings" component={Settings} />
							<Route path="/timesheets" component={Timesheets} />
							<Route path="/trainings" component={TrainingsPage} />
							<Route path="/sodexo" component={Sodexo} />

							{showDimona && <Route path="/documents" component={Documents} />}

							<Route
								path="/paper_voucher_delivery_group/:id(\d+)"
								component={PSVDeliveryDetails}
							/>
							<Route path={"/new_paper_delivery_group"} component={NewPSVGroup} />
							<Route
								path={"/update_paper_delivery_group/:id"}
								component={UpdatePSVDeliveryGroup}
							/>
							<Route
								path="/sodexo/sodexo_login"
								render={() => (
									<Redirect
										to={{
											...location,
											pathname: "/settings/sodexo-connection",
										}}
									/>
								)}
							/>
						</Fragment>
					)}
					{backendErrors.show && (
						<ErrorBox
							errors={backendErrors.errors}
							errorStatus={backendErrors.status}
							acknowledge={this.acknowledgeErrors}
							button={
								<PootsyButton text={t("ok")} onClick={this.acknowledgeWarnings} />
							}
						/>
					)}
					{backendWarnings.show && (
						<WarningModal
							messages={backendWarnings.messages}
							acknowledge={this.acknowledgeWarnings}
							button={
								<PootsyButton text={t("ok")} onClick={this.acknowledgeWarnings} />
							}
						/>
					)}
					<div className={"flash-message" + (flashMessage.message ? " show" : "")}>
						{t(flashMessage.message)}
					</div>
				</div>
			</LocaleContext.Provider>
		)
	}
}

App.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => state
export default connect(mapStateToProps)(App)
