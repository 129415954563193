import moment from "moment"
import { stringifyQuery } from "../helpers/qs"
import jsPDF from "jspdf"
import "jspdf-autotable"

import {
	simpleFetch,
	retrieveTemplateVariables,
	RETRIEVE_TEMPLATE_VARIABLES_SUCCESS,
} from "./index"
import { normalizeDocumentTemplate } from "../normalizers/Normalizers"

export const CUSTOMERS_STATE_CHANGE = "CUSTOMERS_STATE_CHANGE"
export const customersStateChange = changes => ({ type: CUSTOMERS_STATE_CHANGE, changes })

export const CUSTOMER_DETAILS_STATE_CHANGE = "CUSTOMER_DETAILS_STATE_CHANGE"
export const customerDetailsStateChange = changes => ({
	type: CUSTOMER_DETAILS_STATE_CHANGE,
	changes,
})

export const CUSTOMERSUGGESTION_RESET = "CUSTOMERSUGGESTION_RESET"
export const resetCustomerSuggestions = () => ({ type: CUSTOMERSUGGESTION_RESET })

export const CHOOSE_CUSTOMER = "CHOOSE_CUSTOMER"
export const chooseCustomer = customer => ({ type: CHOOSE_CUSTOMER, customer: customer })

export const EDIT_CUSTOMERPROFILE = "EDIT_CUSTOMERPROFILE"
export const editCustomerProfile = data => ({ type: EDIT_CUSTOMERPROFILE, data })

export const RESET_CUSTOMERDATA = "RESET_CUSTOMERDATA"
export const resetCustomerData = () => ({ type: RESET_CUSTOMERDATA })

export const CUSTOMER_ABSENCE_MODAL_STATE_CHANGE = "CUSTOMER_ABSENCE_MODAL_STATE_CHANGE"
export const customerAbsenceModalStateChange = changes => ({
	type: CUSTOMER_ABSENCE_MODAL_STATE_CHANGE,
	changes,
})

export const RESET_CUSTOMER_ABSENCE_MODAL_STATE = "RESET_CUSTOMER_ABSENCE_MODAL_STATE"
export const resetCustomerAbsenceModalState = () => ({ type: RESET_CUSTOMER_ABSENCE_MODAL_STATE })

export const CUSTOMER_WORKSHOP_ORDERS_STATE_CHANGE = "CUSTOMER_WORKSHOP_ORDERS_STATE_CHANGE"
export const customerWorkshopOrdersStateChange = changes => ({
	type: CUSTOMER_WORKSHOP_ORDERS_STATE_CHANGE,
	changes,
})

export const CUSTOMER_TEMPLATES_STATE_CHANGE = "CUSTOMER_TEMPLATES_STATE_CHANGE"
export const customerTemplatesStateChange = changes => ({
	type: CUSTOMER_TEMPLATES_STATE_CHANGE,
	changes,
})

export const RESET_CUSTOMER_TEMPLATES_STATE = "RESET_CUSTOMER_TEMPLATES_STATE"
export const resetCustomerTemplatesState = () => ({ type: RESET_CUSTOMER_TEMPLATES_STATE })

export const CUSTOMERS_PAYMENT_REMINDERS_STATE_CHANGE = "CUSTOMERS_PAYMENT_REMINDERS_STATE_CHANGE"
export const customersPaymentRemindersStateChange = changes => ({
	type: CUSTOMERS_PAYMENT_REMINDERS_STATE_CHANGE,
	changes,
})

export const PAYMENT_REMINDER_MODAL_STATE_CHANGE = "PAYMENT_REMINDER_MODAL_STATE_CHANGE"
export const PaymentReminderPageStateChange = changes => ({
	type: PAYMENT_REMINDER_MODAL_STATE_CHANGE,
	changes,
})

export const RESET_PAYMENT_REMINDER = "RESET_PAYMENT_REMINDER"
export const resetPaymentReminder = changes => ({ type: RESET_PAYMENT_REMINDER })

export const UPDATECUSTOMER_REQUEST = "UPDATECUSTOMER_REQUEST"
export const UPDATECUSTOMER_SUCCESS = "UPDATECUSTOMER_SUCCESS"
export const UPDATECUSTOMER_FAILURE = "UPDATECUSTOMER_FAILURE"

export const CUSTOMERSFILTERS_REQUEST = "CUSTOMERSFILTERS_REQUEST"
export const CUSTOMERSFILTERS_SUCCESS = "CUSTOMERSFILTERS_SUCCESS"
export const CUSTOMERSFILTERS_FAILURE = "CUSTOMERSFILTERS_FAILURE"

export const CUSTOMERPROFILE_SUBMITNEWRESIDENCE_REQUEST =
	"CUSTOMERPROFILE_SUBMITNEWRESIDENCE_REQUEST"
export const CUSTOMERPROFILE_SUBMITNEWRESIDENCE_SUCCESS =
	"CUSTOMERPROFILE_SUBMITNEWRESIDENCE_SUCCESS"
export const CUSTOMERPROFILE_SUBMITNEWRESIDENCE_FAILURE =
	"CUSTOMERPROFILE_SUBMITNEWRESIDENCE_FAILURE"

export const UPDATERESIDENCE_REQUEST = "UPDATERESIDENCE_REQUEST"
export const UPDATERESIDENCE_SUCCESS = "UPDATERESIDENCE_SUCCESS"
export const UPDATERESIDENCE_FAILURE = "UPDATERESIDENCE_FAILURE"

export const CREATECUSTOMERFROMNEWBOOKING_REQUEST = "CREATECUSTOMERFROMNEWBOOKING_REQUEST"
export const CREATECUSTOMERFROMNEWBOOKING_SUCCESS = "CREATECUSTOMERFROMNEWBOOKING_SUCCESS"
export const CREATECUSTOMERFROMNEWBOOKING_FAILURE = "CREATECUSTOMERFROMNEWBOOKING_FAILURE"

export const CREATECUSTOMERFROMNEWDIRECTBOOKING_REQUEST =
	"CREATECUSTOMERFROMNEWDIRECTBOOKING_REQUEST"
export const CREATECUSTOMERFROMNEWDIRECTBOOKING_SUCCESS =
	"CREATECUSTOMERFROMNEWDIRECTBOOKING_SUCCESS"
export const CREATECUSTOMERFROMNEWDIRECTBOOKING_FAILURE =
	"CREATECUSTOMERFROMNEWDIRECTBOOKING_FAILURE"

export const CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER = "CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER"
export const CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_REQUEST =
	"CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_REQUEST"
export const CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_SUCCESS =
	"CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_SUCCESS"
export const CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_FAILURE =
	"CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_FAILURE"

export const FETCHCUSTOMERS = "FETCHCUSTOMERS"
export const FETCHCUSTOMERS_REQUEST = "FETCHCUSTOMERS_REQUEST"
export const FETCHCUSTOMERS_SUCCESS = "FETCHCUSTOMERS_SUCCESS"
export const FETCHCUSTOMERS_FAILURE = "FETCHCUSTOMERS_FAILURE"

export const FETCHCUSTOMER_REQUEST = "FETCHCUSTOMER_REQUEST"
export const FETCHCUSTOMER_SUCCESS = "FETCHCUSTOMER_SUCCESS"
export const FETCHCUSTOMER_FAILURE = "FETCHCUSTOMER_FAILURE"

export const SUBMITNEWCUSTOMER_REQUEST = "SUBMITNEWCUSTOMER_REQUEST"
export const SUBMITNEWCUSTOMER_SUCCESS = "SUBMITNEWCUSTOMER_SUCCESS"
export const SUBMITNEWCUSTOMER_FAILURE = "SUBMITNEWCUSTOMER_FAILURE"

export const CUSTOMERSUGGESTION_REQUEST = "CUSTOMERSUGGESTION_REQUEST"
export const CUSTOMERSUGGESTION_SUCCESS = "CUSTOMERSUGGESTION_SUCCESS"
export const CUSTOMERSUGGESTION_FAILURE = "CUSTOMERSUGGESTION_FAILURE"

export const DELETECUSTOMERCONTRACT_REQUEST = "DELETECUSTOMERCONTRACT_REQUEST"
export const DELETECUSTOMERCONTRACT_SUCCESS = "DELETECUSTOMERCONTRACT_SUCCESS"
export const DELETECUSTOMERCONTRACT_FAILURE = "DELETECUSTOMERCONTRACT_FAILURE"

export const INVITECUSTOMER_REQUEST = "INVITECUSTOMER_REQUEST"
export const INVITECUSTOMER_SUCCESS = "INVITECUSTOMER_SUCCESS"
export const INVITECUSTOMER_FAILURE = "INVITECUSTOMER_FAILURE"

export const ENDCUSTOMERCONTRACT_REQUEST = "ENDCUSTOMERCONTRACT_REQUEST"
export const ENDCUSTOMERCONTRACT_SUCCESS = "ENDCUSTOMERCONTRACT_SUCCESS"
export const ENDCUSTOMERCONTRACT_FAILURE = "ENDCUSTOMERCONTRACT_FAILURE"

export const FETCHCUSTOMERMESSAGES = "FETCHCUSTOMERMESSAGES"
export const FETCHCUSTOMERMESSAGES_REQUEST = "FETCHCUSTOMERMESSAGES_REQUEST"
export const FETCHCUSTOMERMESSAGES_SUCCESS = "FETCHCUSTOMERMESSAGES_SUCCESS"
export const FETCHCUSTOMERMESSAGES_FAILURE = "FETCHCUSTOMERMESSAGES_FAILURE"
export const fetchCustomerMessages = id => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/customers/messages?customer_contract_id=" + id
	dispatch(simpleFetch(url, init, "FETCHCUSTOMERMESSAGES"))
}

export const SUBMITCUSTOMERMESSAGE = "SUBMITCUSTOMERMESSAGE"
export const SUBMITCUSTOMERMESSAGE_REQUEST = "SUBMITCUSTOMERMESSAGE_REQUEST"
export const SUBMITCUSTOMERMESSAGE_SUCCESS = "SUBMITCUSTOMERMESSAGE_SUCCESS"
export const SUBMITCUSTOMERMESSAGE_FAILURE = "SUBMITCUSTOMERMESSAGE_FAILURE"
export const submitCustomerMessage = (customerContractId, currMess, chatImg) => (
	dispatch,
	getState
) => {
	if (currMess === "" && chatImg === "") {
		return
	}
	let data = {
		customer_contract_id: customerContractId,
		content: currMess,
	}
	if (chatImg) {
		data.images = [{ base64_content: chatImg.file, filename: chatImg.filename }]
	}
	let init = { method: "POST", body: JSON.stringify(data) }
	dispatch(simpleFetch("/front/affiliates/customers/messages", init, "SUBMITCUSTOMERMESSAGE"))
}

export const FETCH_LINKED_CUSTOMER_REQUEST = "FETCH_LINKED_CUSTOMER_REQUEST"
export const FETCH_LINKED_CUSTOMER_SUCCESS = "FETCH_LINKED_CUSTOMER_SUCCESS"
export const FETCH_LINKED_CUSTOMER_FAILURE = "FETCH_LINKED_CUSTOMER_FAILURE"

export const SUBMIT_LINK_CUSTOMER_REQUEST = "SUBMIT_LINK_CUSTOMER_REQUEST"
export const SUBMIT_LINK_CUSTOMER_SUCCESS = "SUBMIT_LINK_CUSTOMER_SUCCESS"
export const SUBMIT_LINK_CUSTOMER_FAILURE = "SUBMIT_LINK_CUSTOMER_FAILURE"

export const SUBMIT_REMOVE_LINK_CUSTOMER_REQUEST = "SUBMIT_REMOVE_LINK_CUSTOMER_REQUEST"
export const SUBMIT_REMOVE_LINK_CUSTOMER_SUCCESS = "SUBMIT_REMOVE_LINK_CUSTOMER_SUCCESS"
export const SUBMIT_REMOVE_LINK_CUSTOMER_FAILURE = "SUBMIT_REMOVE_LINK_CUSTOMER_FAILURE"

export const FETCH_CUSTOMER_PAYMENTS = "FETCH_CUSTOMER_PAYMENTS"
export const FETCH_CUSTOMER_PAYMENTS_REQUEST = "FETCH_CUSTOMER_PAYMENTS_REQUEST"
export const FETCH_CUSTOMER_PAYMENTS_SUCCESS = "FETCH_CUSTOMER_PAYMENTS_SUCCESS"
export const FETCH_CUSTOMER_PAYMENTS_FAILURE = "FETCH_CUSTOMER_PAYMENTS_FAILURE"

export const FETCH_CUSTOMER_INCOMPATIBILITIES_REQUEST = "FETCH_CUSTOMER_INCOMPATIBILITIES_REQUEST"
export const FETCH_CUSTOMER_INCOMPATIBILITIES_SUCCESS = "FETCH_CUSTOMER_INCOMPATIBILITIES_SUCCESS"
export const FETCH_CUSTOMER_INCOMPATIBILITIES_FAILURE = "FETCH_CUSTOMER_INCOMPATIBILITIES_FAILURE"

export const SUBMIT_CUSTOMER_INCOMPATIBILITY_REQUEST = "SUBMIT_CUSTOMER_INCOMPATIBILITY_REQUEST"
export const SUBMIT_CUSTOMER_INCOMPATIBILITY_SUCCESS = "SUBMIT_CUSTOMER_INCOMPATIBILITY_SUCCESS"
export const SUBMIT_CUSTOMER_INCOMPATIBILITY_FAILURE = "SUBMIT_CUSTOMER_INCOMPATIBILITy_FAILURE"

export const SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_REQUEST =
	"SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_REQUEST"
export const SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_SUCCESS =
	"SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_SUCCESS"
export const SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_FAILURE =
	"SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_FAILURE"

export const FETCH_CUSTOMER_ABSENCES_REQUEST = "FETCH_CUSTOMER_ABSENCES_REQUEST"
export const FETCH_CUSTOMER_ABSENCES_SUCCESS = "FETCH_CUSTOMER_ABSENCES_SUCCESS"
export const FETCH_CUSTOMER_ABSENCES_FAILURE = "FETCH_CUSTOMER_ABSENCES_FAILURE"

export const CUSTOMERS_LIST_EXPORT_REQUEST = "CUSTOMERS_LIST_EXPORT_REQUEST"
export const CUSTOMERS_LIST_EXPORT_SUCCESS = "CUSTOMERS_LIST_EXPORT_SUCCESS"
export const CUSTOMERS_LIST_EXPORT_FAILURE = "CUSTOMERS_LIST_EXPORT_FAILURE"

export const FORGET_CUSTOMER_CONTRACT_REQUEST = "FORGET_CUSTOMER_CONTRACT_REQUEST"
export const FORGET_CUSTOMER_CONTRACT_SUCCESS = "FORGET_CUSTOMER_CONTRACT_SUCCESS"
export const FORGET_CUSTOMER_CONTRACT_FAILURE = "FORGET_CUSTOMER_CONTRACT_FAILURE"

export const SET_CUSTOMER_CONTRACT_INACTIVE_REQUEST = "SET_CUSTOMER_CONTRACT_INACTIVE_REQUEST"
export const SET_CUSTOMER_CONTRACT_INACTIVE_SUCCESS = "SET_CUSTOMER_CONTRACT_INACTIVE_SUCCESS"
export const SET_CUSTOMER_CONTRACT_INACTIVE_FAILURE = "SET_CUSTOMER_CONTRACT_INACTIVE_FAILURE"

export const REACTIVATE_CUSTOMER_CONTRACT = "REACTIVATE_CUSTOMER_CONTRACT"
export const REACTIVATE_CUSTOMER_CONTRACT_REQUEST = "REACTIVATE_CUSTOMER_CONTRACT_REQUEST"
export const REACTIVATE_CUSTOMER_CONTRACT_SUCCESS = "REACTIVATE_CUSTOMER_CONTRACT_SUCCESS"
export const REACTIVATE_CUSTOMER_CONTRACT_FAILURE = "REACTIVATE_CUSTOMER_CONTRACT_FAILURE"

export const FETCH_PAYMENT_REMINDERS = "FETCH_PAYMENT_REMINDERS"
export const FETCH_PAYMENT_REMINDERS_REQUEST = "FETCH_PAYMENT_REMINDERS_REQUEST"
export const FETCH_PAYMENT_REMINDERS_SUCCESS = "FETCH_PAYMENT_REMINDERS_SUCCESS"
export const FETCH_PAYMENT_REMINDERS_FAILURE = "FETCH_PAYMENT_REMINDERS_FAILURE"
export const fetchPaymentReminders = () => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let init = { method: "GET" }
	let url = "/front/affiliates/payment_reminders" + stringifyQuery(realQuery)
	dispatch(simpleFetch(url, init, FETCH_PAYMENT_REMINDERS))
}

export const FETCH_PAYMENT_REMINDER = "FETCH_PAYMENT_REMINDER"
export const FETCH_PAYMENT_REMINDER_REQUEST = "FETCH_PAYMENT_REMINDER_REQUEST"
export const FETCH_PAYMENT_REMINDER_SUCCESS = "FETCH_PAYMENT_REMINDER_SUCCESS"
export const FETCH_PAYMENT_REMINDER_FAILURE = "FETCH_PAYMENT_REMINDER_FAILURE"
export const fetchPaymentReminder = paymentReminderId => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/payment_reminders/" + paymentReminderId
	dispatch(simpleFetch(url, init, "FETCH_PAYMENT_REMINDER"))
}

export const fetchCustomers = () => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let init = { method: "GET" }
	let url = "/front/affiliates/customer_contracts" + stringifyQuery(realQuery)
	dispatch(simpleFetch(url, init, FETCHCUSTOMERS))
}

export const fetchCustomerPayments = customerId => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let init = { method: "GET" }
	let url =
		"/front/affiliates/customer_contracts/" +
		customerId +
		"/payments" +
		stringifyQuery(realQuery)
	dispatch(simpleFetch(url, init, FETCH_CUSTOMER_PAYMENTS))
}

export const fetchCustomer = id => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/customer_contracts/" + id
	dispatch(simpleFetch(url, init, "FETCHCUSTOMER"))
}

export const fetchCustomerLinkedCustomerContracts = id => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/customer_contracts/" + id + "/linked_customer_contracts"
	dispatch(simpleFetch(url, init, "FETCH_LINKED_CUSTOMER"))
}

export const fetchCustomerWorkerIncompatibilities = id => (dispatch, getState) => {
	let init = { method: "GET" }

	let url = `/front/affiliates/customer_contracts/${id}/worker_incompatibilities`
	dispatch(simpleFetch(url, init, "FETCH_CUSTOMER_INCOMPATIBILITIES"))
}

export const FETCH_ALL_CUSTOMERS = "FETCH_ALL_CUSTOMERS"
export const FETCH_ALL_CUSTOMERS_REQUEST = "FETCH_ALL_CUSTOMERS_REQUEST"
export const FETCH_ALL_CUSTOMERS_SUCCESS = "FETCH_ALL_CUSTOMERS_SUCCESS"
export const FETCH_ALL_CUSTOMERS_FAILURE = "FETCH_ALL_CUSTOMERS_FAILURE"
export const createCustomersListPDF = translate => async (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let url = "/front/affiliates/lists/customers_list" + stringifyQuery(realQuery)
	let init = { method: "GET" }
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCH_ALL_CUSTOMERS))
	if (actionType === FETCH_ALL_CUSTOMERS_SUCCESS) {
		let customersListForPDF = {
			showHead: "firstPage",
			margin: { top: 15, left: 5, bottom: 5, right: 5 },
			styles: {
				fontSize: 9,
				cellPadding: 1,
				halign: "left",
				valign: "middle",
			},
			columnStyles: {
				id: { cellWidth: "wrap" },
				language: { cellWidth: "wrap" },
				phone: { cellWidth: "wrap" },
			},
			columns: [
				{ header: translate("id"), dataKey: "id" },
				{ header: translate("first_name"), dataKey: "first_name" },
				{ header: translate("last_name"), dataKey: "last_name" },
				{ header: translate("address"), dataKey: "address" },
				{ header: translate("email"), dataKey: "email" },
				{ header: translate("language_abbrev"), dataKey: "language" },
				{ header: translate("phone"), dataKey: "phone" },
				{ header: translate("customer_sodexo_reference"), dataKey: "sodexo_reference" },
				{ header: translate("customer_sodexo_region"), dataKey: "sodexo_region" },
				{
					header: translate("voucher_type_preference"),
					dataKey: "voucher_type_preference",
				},
			],
			body: data.data.map(entry => ({
				id: entry.id,
				first_name: entry.attributes.first_name,
				last_name: entry.attributes.last_name,
				address: entry.attributes.address,
				email: entry.attributes.email,
				language: entry.attributes.default_language,
				phone: entry.attributes.telephone,
				sodexo_reference: entry.attributes.sodexo_reference,
				sodexo_region: entry.attributes.sodexo_region,
				voucher_type_preference: entry.attributes.voucher_type_preference,
			})),
		}
		let doc = new jsPDF("landscape")
		doc.text(`${moment().format("DD/MM/YY")} ${translate("customers_lists_pdf_header")}`, 5, 10)
		doc.autoTable(customersListForPDF)
		doc.save(`${translate("customers_lists")}.pdf`)
	}
}

export const processCustomerTemplatesStateChange = changes => async (dispatch, getState) => {
	dispatch(customerTemplatesStateChange(changes))
	let {
		redData: { currentCustomer },
		redComponents: {
			customerTemplatesComponent: { selectedTemplate, selectedLang },
		},
	} = getState()
	if (!selectedTemplate || !selectedLang) {
		return
	}

	let { data, actionType } = await dispatch(
		retrieveTemplateVariables({
			templateID: selectedTemplate,
			lang: selectedLang,
			customParams: { customer_contract_id: currentCustomer.id },
		})
	)

	if (actionType === RETRIEVE_TEMPLATE_VARIABLES_SUCCESS) {
		dispatch(
			customerTemplatesStateChange({
				renderableTemplate: normalizeDocumentTemplate(data.data),
			})
		)
	}
}
