import moment from "moment"
import { formatMinutes } from "../helpers/timeFormatting"

export const normalizeAllAreas = data => {
	return data
		.map(entry => ({
			id: entry.id,
			value: entry.attributes.zip_code,
			label: entry.attributes.zip_code,
		}))
		.sort(function(a, b) {
			let nameA = a.value
			let nameB = b.value
			if (nameA < nameB) {
				return -1
			}
			if (nameA > nameB) {
				return 1
			}
			return 0
		})
}

export const normalizeWorkshop = workshop => ({
	id: workshop.id,
	workshopName: workshop.attributes.workshop_name,
	SSCode: workshop.attributes.ss_code,
	address: workshop.attributes.address,
})

export const normalizeAffiliateUser = affiliateUser => ({
	...affiliateUser.attributes,
	id: affiliateUser.id,
})

export const normalizeAffiliate = affiliate => {
	let {
		commercial_address,
		invoicing_address,
		invoicing_address_inline,
		commercial_address_inline,
	} = affiliate
	let affiliateHolidayTimeRanges = affiliate.holidays.effective.map(entry => {
		let start = moment(entry.start_date).hour(8)
		let end = moment(entry.end_date).hour(20)
		let duration = end.diff(start, "minutes")
		return {
			start: start,
			end: end,
			duration: duration,
			type: "affiliateHoliday",
			customSsCode: entry.custom_ss_code,
		}
	})
	return {
		id: affiliate.id,
		VatNumber: affiliate.vat_number,
		supportEmail: affiliate.customer_support_email,
		administrativeEmail: affiliate.administrative_email,
		defaultLanguage: affiliate.default_language,
		phone: affiliate.telephone,
		activeAreas: affiliate.affiliate_areas
			.sort((a, b) => a - b)
			.map(entry => ({ value: entry, label: entry })),
		commercialName: affiliate.commercial_name,
		companyName: affiliate.company_name,
		minBookingMins: affiliate.min_booking_mins,
		minIntraBookingMins: affiliate.min_inter_bookings_minutes,
		workAllowedSaturday: affiliate.allowed_work_on_saturday,
		workAllowedSunday: affiliate.allowed_work_on_sunday,
		logoUrl: affiliate.logo.thumb.url,
		fullSizeLogoUrl: affiliate.logo.url,
		coverPictureUrl: affiliate.cover_picture.thumb.url,
		hasConventions: affiliate.has_conventions,
		conventions: {
			en: affiliate.convention_en,
			fr: affiliate.convention_fr,
			nl: affiliate.convention_nl,
		},
		visibleWorkerCount: affiliate.workers_count,
		tariffPlan: affiliate.tariff,
		users: affiliate.users.data.map(normalizeAffiliateUser),
		currYearHolidaysTimeRanges: affiliateHolidayTimeRanges,
		publicHolidaysOfCurrentYear: Object.keys(affiliate.holidays.public).map(key => {
			let holiday = affiliate.holidays.public[key][0]
			return { date: holiday.date, name: holiday.name }
		}),
		socialSecretaryID: affiliate.social_secretary_id,
		acceptsPonctualBookings: affiliate.accepts_ponctual_bookings,
		secSocSpecificFields: [],
		secSocName: affiliate.social_secretary_name,
		minDaysBeforeNewBooking: affiliate.days_before_new_booking,
		minDaysBeforeBookingCancel: affiliate.days_before_booking_cancellation,
		minDaysBeforeEndingCustomerContract: affiliate.days_before_customer_contract_cancellation,
		sodexoAffiliationNb: affiliate.sodexo_affiliation_number,
		sodexoAgreementNbBrussels: affiliate.sodexo_agreement_number_brussels,
		sodexoAgreementNbFlanders: affiliate.sodexo_agreement_number_vlaanderen,
		sodexoAgreementNbWallonia: affiliate.sodexo_agreement_number_wallonie,
		commercialAddress: {
			display: commercial_address_inline,
			country: commercial_address.country,
			street: commercial_address.street_name,
			streetNumber: commercial_address.street_number,
			box: commercial_address.street_box,
			city: commercial_address.town,
			zip: commercial_address.zip_code,
		},
		invoicingAddress: {
			display: invoicing_address_inline,
			country: invoicing_address.country,
			street: invoicing_address.street_name,
			streetNumber: invoicing_address.street_number,
			box: invoicing_address.street_box,
			city: invoicing_address.town,
			zip: invoicing_address.zip_code,
		},
		bio: affiliate.bio || "",
		slogan: affiliate.slogan || "",
		openingHours: affiliate.opening_hours.map(oh => ({
			id: oh.id.toString(),
			day: oh.day_of_week.toString(),
			start: moment(oh.start_time),
			end: moment(oh.end_time),
		})),
		secSocEvents: {
			categories: affiliate.social_secretary_metadata.social_secretary_code_categories || [],
			codes: affiliate.social_secretary_metadata.social_secretary_codes || [],
		},
		mealVoucherConfig: affiliate.meal_vouchers_config,
		travelExpConfig: affiliate.travel_expenses_config,
		clothesExpConfig: affiliate.clothes_expenses_config,
		secSocPrefillWorkerContract: affiliate.sec_soc_prefill_worker_contract || {},
		sodexoConnectionSettings: affiliate.sodexo_connection_settings,
		sodexoConnectionStatus: {
			authToken: affiliate.sodexo_connection_status.auth_token,
			lastRequestedSync: moment(affiliate.sodexo_connection_status.last_requested_sync),
			lastSucceededSync: moment(affiliate.sodexo_connection_status.last_succeeded_sync),
			lastFailedSync: moment(affiliate.sodexo_connection_status.last_failed_sync),
		},
		dimonaSettings: affiliate.dimona_settings,
		ecoUnemploymentSettings: affiliate.economic_unemployment_settings,
		onssReference: affiliate.onss_reference || "",
		bceReference: affiliate.bce_reference || "",
		commission: affiliate.commission || "",
		languageONSS: affiliate.language_onss || "",
		reasonSmallEcoUnemployment: affiliate.reason_small_eco_unemployment || "",
		reasonBigEcoUnemployment: affiliate.reason_big_eco_unemployment || "",
		operatingHeadquarters: affiliate.operating_headquarters || {},
		onssContactFirstName: affiliate.onss_contact_first_name || "",
		onssContactLastName: affiliate.onss_contact_last_name || "",
		onssContactTelephone: affiliate.onss_contact_telephone || "",
		squads: affiliate.squads || [],
		workshopSettings: affiliate.workshop_settings,
		sodexoAddOn: affiliate.sodexo_add_on,
		secSocAddOn: affiliate.social_secretary_add_on,
		dimonaAddOn: affiliate.dimona_add_on,
		workshopAddOn: affiliate.workshop_add_on,
		hideUnfulfillingTimeslotsToCustomers: affiliate.hide_unfulfilling_timeslots_to_customers,
		workshops: affiliate.workshops.data.map(normalizeWorkshop),
		workshopNeedsMapping: affiliate.workshop_needs_mapping,
		availableTasksCategories: affiliate.available_tasks_categories,
		euSSCode: affiliate.eu_social_secretary_code,
		hasONSSSettings: affiliate.has_all_onss_settings,
		allAffiliateWorkers: affiliate.active_affiliate_workers.map(entry => ({
			id: entry.id,
			attributes: { display_name: entry.display_name },
		})),
	}
}

export const normalizeBookingLine = data => {
	return {
		id: data.id,
		deliveryDate: data.attributes.delivery_date,
		customerName: data.attributes.customer_display_name,
		workerName: data.attributes.worker_display_name,
		startTime: data.attributes.start_time,
		endTime: data.attributes.end_time,
		fmtStartTime: data.attributes.formated_start_time,
		fmtEndTime: data.attributes.formated_end_time,
		sdStartTime: data.attributes.service_delivery_start_time,
		sdEndTime: data.attributes.service_delivery_end_time,
		serviceDeliveryStatus: data.attributes.service_delivery_status,
		voucherType: data.attributes.voucher_type,
		voucherCount: data.attributes.voucher_count,
		recurrence: data.attributes.recurrence,
		pServiceVouchers: data.attributes.paper_service_vouchers,
		pServiceVouchersAffiliate: data.attributes.paper_service_vouchers_affiliate || [],
		sodexoStatus: data.attributes.sodexo_status,
		sodexoDuration: data.attributes.sodexo_duration,
		customerSodexoRef: data.attributes.customer_sodexo_reference,
		workerSodexoRef: data.attributes.worker_sodexo_reference,
	}
}

export const normalizeSodexoBooking = data => {
	return {
		id: data.id,
		customer: data.attributes.customer,
		worker: data.attributes.worker,
		voucherCount: data.attributes.hours_quantity,
		deliveryDate: data.attributes.date,
		customerName: data.attributes.customer_name,
		customerIdentifier: data.attributes.customer_identifier,
		workerName: data.attributes.worker_name,
		workerIdentifier: data.attributes.worker_identifier,
		customerSodexoRef: data.attributes.customer_sodexo_reference,
		workerSodexoRef: data.attributes.worker_sodexo_reference,
		sodexoStatus: data.attributes.status,
	}
}

export const normalizeSodexoPaperServiceVouchers = data => {
	return {
		id: data.id,
		deliveryDate: data.scheduled_date,
		workerSodexoRef: data.worker_sodexo_reference,
		customerSodexoRef: data.customer_sodexo_reference,
		customerName: data.customer_identifier,
		workerName: data.worker_identifier,
		paperServiceVouchers: data.paper_service_vouchers,
	}
}

export const normalizeDimonaLine = data => {
	let workerContractId = null
	if (data.attributes.worker_contract) {
		workerContractId = data.attributes.worker_contract.id
	} else {
		workerContractId = null
	}
	return {
		id: data.id,
		status: data.attributes.status,
		errors: data.attributes.errors,
		externalPeriodId: data.attributes.external_period_id,
		onssRefNumber: data.attributes.onss_ref_number,
		dateIn: moment(data.attributes.date_in),
		dateOut: moment(data.attributes.date_out),
		creationDate: moment(data.attributes.created_at),
		workerFirstName: data.attributes.first_name,
		workerLastName: data.attributes.last_name,
		workerDisplayName: `${data.attributes.first_name} ${data.attributes.last_name}`,
		workerNiss: data.attributes.niss,
		dimonaType: data.attributes.dimona_type,
		workerContractId: workerContractId,
		affiliateWorkerId: data.attributes.affiliate_worker_id,
		errorTradKeys: data.attributes.error_translation_keys,
	}
}

export const normalizeCurrentBooking = data => {
	return {
		id: data.id,
		customer: data.attributes.customer,
		worker: data.attributes.worker,
		address: data.attributes.address,
		deliveryDate: data.attributes.delivery_date,
		endDate: data.attributes.end_date,
		startTime: data.attributes.start_time,
		endTime: data.attributes.end_time,
		dayPeriod: data.attributes.day_period,
		note: data.attributes.note || "",
		fmtStartTime: data.attributes.formated_start_time,
		fmtEndTime: data.attributes.formated_end_time,
		sdStartTime: data.attributes.service_delivery_start_time,
		sdEndTime: data.attributes.service_delivery_end_time,
		voucherType: data.attributes.voucher_type,
		voucherCount: data.attributes.billable_hours,
		recurrence: data.attributes.recurrence,
		status: data.attributes.status,
		serviceDeliveryStatus: data.attributes.service_delivery_status,
		payStatus: data.attributes.pay_status,
		activities: data.attributes.activities,
		evaluation: data.attributes.evaluation,
		workerLanguage: data.attributes.worker_language,
		pServiceVouchers: data.attributes.paper_service_vouchers,
		pServiceVouchersAffiliate: data.attributes.paper_service_vouchers_affiliate || [],
		sodexoStatus: data.attributes.sodexo_status,
		sodexoDuration: data.attributes.sodexo_duration,
		paymentReminders: data.attributes.payment_reminders || [],
		cancelReason: data.attributes.cancel_reason,
		parentEvent: data.attributes.parent_event,
		sodexoWorkId: data.attributes.sodexo_booking_id,
	}
}

const normalizeTimeRange = timeRange => {
	let start = moment(timeRange.start_time)
	let end = moment(timeRange.end_time)
	let duration = end.diff(start, "minutes")
	return {
		id: timeRange.id,
		dayPeriod: parseInt(timeRange.day_period, 10),
		startDate: moment(timeRange.start_date),
		start: start.set({ year: 2000, month: 0, date: 1, seconds: 0 }),
		end: end.set({ year: 2000, month: 0, date: 1, seconds: 0 }),
		duration: duration,
	}
}

export const normalizeCurrentWorker = data => {
	let {
		sodexo_reference,
		niss,
		title,
		first_name,
		last_name,
		email,
		telephone,
		default_language,
		languages,
		dimonas,
		address,
		start_date,
		end_date,
		// weekly_schedules,
		current_week_schedule,
		contract_start_weekly_schedule,
		evaluation,
		// next_bookings, prev_bookings,
		affiliate_note,
		social_secretary_identifier,
		already_connected,
		sodexo_pin,
		recurrent_bookings,
		allergies,
		needs_upfront_payment,
		external_partner_id,
	} = data.attributes

	return {
		dimonas: dimonas,
		id: data.id,
		externalPartnerId: external_partner_id || "",
		sodexoNumber: sodexo_reference,
		sodexoNumberIsRecorded: !!sodexo_reference,
		allergies: allergies || "",
		nissNumber: niss || "",
		title: title || "",
		firstName: first_name || "",
		lastName: last_name || "",
		displayName: first_name + " " + last_name,
		initials: first_name.substring(0, 1) + last_name.substring(0, 1),
		email: email || "",
		mobilePhone: telephone || "",
		rating: evaluation || "",
		languages: languages || "",
		mainLanguage: default_language || "",
		...languages.reduce((acc, e) => {
			acc[e.reference] = true
			return acc
		}, {}),
		street: address.street_name || "",
		streetNumber: address.street_number || "",
		box: address.street_box || "",
		city: address.town || "",
		zip: address.zip_code || "",
		country: address.country || "be",
		contractStartDate: moment(start_date),
		contractEndDate: moment(end_date),
		contractStartWeeklySchedule: contract_start_weekly_schedule,
		currentWeekSchedule: current_week_schedule,
		// nextBookings: next_bookings, prevBookings: prev_bookings,
		affiliateWorkerNote: affiliate_note || "",
		secSocIdentifier: social_secretary_identifier || "",
		already_connected: already_connected,
		sodexoPin: sodexo_pin || "",
		sixtyPercentRuleEligible: data.attributes.sixty_percent_rule_eligible,
		region: data.attributes.region || "",
		identityCardNumber: data.attributes.identity_card_number || "",
		excludedFromTSS: (data.attributes.excluded_from_tss === true).toString(),
		canWorkWithAnimals: (data.attributes.can_work_with_animals === true).toString(),
		comment: data.attributes.comment || "",
		squadIds: data.attributes.squad_ids || [],
		needsUpfrontPayment: !!needs_upfront_payment,
		lastEuAbsenceDate: data.attributes.last_eu_absence_date
			? moment(data.attributes.last_eu_absence_date)
			: moment.invalid(),
		recurrentBookings: recurrent_bookings.map(entry => {
			return {
				type: "recurrent-booking",
				...normalizeTimeRange(entry),
				allowDeletion: false,
				allowEdition: false,
				fitWhenOverlap: true,
				extraInfo: [
					{ label: "customer", value: entry.customer },
					{
						label: "recurrence",
						value: { 7: "every_week", 14: "every_two_weeks", 28: "every_four_weeks" }[
							entry.day_period
						],
					},
				],
				customerAddress: entry.address,
			}
		}),
	}
}

export const normalizeWorkerContract = data => {
	let contract = data.attributes
	let theoreticalHours =
		contract.contract_variability === "variable_variable"
			? []
			: contract.theoretical_hours.map(entry => {
					return {
						type: "theo-hour",
						allowDeletion: true,
						allowEdition: true,
						...normalizeTimeRange(entry),
					}
			  })
	let availabilities = contract.availabilities.map(entry => {
		return {
			type: "avail",
			allowDeletion: true,
			allowEdition: true,
			isExtraAvailability: entry.is_extra_availability,
			...normalizeTimeRange(entry),
		}
	})
	let otherActivities = contract.contractual_other_activities.map(entry => {
		return {
			type: "other-act",
			customClass: entry.is_workshop_hour ? "workshop-hour" : "",
			allowDeletion: true,
			allowEdition: true,
			activityCode: entry.activity_code,
			activityCategory: entry.activity_category,
			isWorkshopHour: entry.is_workshop_hour,
			workshopId: entry.workshop_id,
			...normalizeTimeRange(entry),
			extraInfo: [
				{ label: "start_date", value: moment(entry.start_date).format("ddd DD/MM/YY") },
				{
					label: "recurrence",
					value: {
						0: "once",
						7: "every_week",
						14: "every_two_weeks",
						28: "every_four_weeks",
					}[entry.day_period],
				},
				{ label: "activity_code", value: entry.activity_code },
			],
		}
	})
	let startDate = moment(contract.start_date)
	let endDate = moment(contract.end_date)
	let temporalStatus = "active"
	let today = moment()
	if (startDate.isAfter(today)) {
		temporalStatus = "future"
	} else if (endDate.isValid() && endDate.isBefore(today)) {
		temporalStatus = "past"
	}
	return {
		id: parseInt(data.id, 10),
		startDate,
		endDate,
		signingDate: moment(contract.signing_date),
		activeContract: contract.status === "active",
		temporalStatus,
		dimonas: contract.dimonas,
		hoursPerWeek: contract.work_hours_per_week || "",
		comment: contract.comment || "",
		fixedTerm: contract.fixed_term || "",
		vehicleType: contract.vehicle_type || "",
		sponsor: String(contract.sponsor) || "",
		contractVariability: contract.contract_variability || "",
		coveredAreas: contract.covered_areas.map(entry => entry.zip_code),
		refPeriodWeekCount: contract.reference_period,
		refPeriodsStartDate: moment(contract.ref_periods_start_date),
		avgHoursPerWeekForRefPeriod: contract.varvar_ref_period_avg_minutes_per_week / 60,
		varvarMinWorkHoursPerWeek: contract.varvar_min_work_minutes_per_week / 60,
		varvarMaxWorkHoursPerWeek: contract.varvar_max_work_minutes_per_week / 60,
		schedulePeriods: contract.schedule_periods
			.map(entry => ({
				id: entry.id,
				startDate: moment(entry.start_date),
				hasMaxHoursPerDay: entry.has_max_mins_per_day,
				dayPeriod: parseInt(entry.day_period, 10),
				maxWorkHours: entry.max_work_mins / 60,
				maxExtraWorkHours: entry.max_extra_work_mins / 60,
				daysMaxHours: [0, 1, 2, 3, 4, 5, 6].map(
					nb => entry[`day_${nb}_max_mins`] / 60 || 0
				),
				managedAvails: entry.managed_availabilities_ids,
				managedTheoHours: entry.managed_theoretical_hours_ids,
				managedOtherActivities: entry.managed_other_activities_ids,
			}))
			.sort((a, b) => (b.startDate.isBefore(a.startDate) ? 1 : -1)),
		theoreticalHours: theoreticalHours,
		availabilities: availabilities,
		otherActivities: otherActivities,
		unsavedBookings: [],
		secSocSpecificData: contract.worker_contract_specific_data || {},
		documents: contract.documents,
		addendum: contract.addendum,
		parentID: contract.parent_id,
		scheduledJobs: contract.scheduled_jobs,
		operatingHeadquarter: contract.operating_headquarter || {},
		socSecId: contract.soc_sec_id,
	}
}

export const normalizeCurrentCustomer = data => {
	let {
		id,
		attributes: {
			sodexo_reference,
			sodexo_region,
			start_date,
			end_date,
			title,
			first_name,
			last_name,
			display_name,
			invited,
			already_connected,
			email,
			customer_email,
			telephone,
			secondary_phone,
			landline_phone,
			affiliate_note,
			voucher_type_preference,
			default_language,
			address,
			residences,
			next_bookings,
			prev_bookings,
			known_workers,
			notification_preferences,
			scheduled_jobs,
			origin,
			niss,
			workshop_balance,
			linked_customer_contracts,
			workshop_balance_initial_order_minutes,
			last_workshop_used,
			external_partner_id,
		},
	} = data

	return {
		id: id,
		contractStartDate: start_date,
		contractEndDate: end_date,
		sodexoNumber: sodexo_reference || "",
		region: sodexo_region,
		customerTitle: title,
		firstName: first_name,
		lastName: last_name,
		displayName: display_name,
		alreadyConnected: already_connected,
		email: email,
		customerEmail: customer_email,
		defaultLanguage: default_language,
		invited: invited,
		voucherTypePreference: voucher_type_preference,
		mobilePhone: telephone || "",
		secondaryPhone: secondary_phone || "",
		landlinePhone: landline_phone || "",
		mainAddress: { ...address },
		allResidences: residences.data || [],
		nextBookings: next_bookings,
		prevBookings: prev_bookings,
		affiliateCustomerNote: affiliate_note || "",
		knownWorkers: known_workers,
		linkedCustomers: linked_customer_contracts.data,
		notificationPreferences: notification_preferences,
		scheduledJobs: scheduled_jobs.map(entry => ({
			scheduledDate: moment(entry.scheduled_date),
			jobType: entry.job_type,
		})),
		origin: origin || "",
		niss: niss,
		workshopBalance: workshop_balance,
		workshopBalanceInitialValue: workshop_balance_initial_order_minutes || 0,
		lastWorkshopUsed: last_workshop_used,
		externalPartnerId: external_partner_id,
	}
}

export const normalizeTimeslots = data => {
	let { time_slot_collections } = data
	return time_slot_collections.reduce((acc, entry) => {
		acc[entry.delivery_date] = entry.time_slots.reduce((acc, entry) => {
			let worker = {
				contractId: entry.affiliate_worker.worker_contract_id,
				displayName:
					entry.affiliate_worker.first_name + " " + entry.affiliate_worker.last_name,
				comment: entry.affiliate_worker.comment || "-",
				distanceFromLast: entry.distance_from_last_location,
				lastLocationIsHome: entry.last_location_is_home,
				fillsThEntirely: entry.fills_th_entirely,
				containedByTh: entry.contained_by_th,
				isExtraHour: entry.is_extra_hour,
				allergies: entry.affiliate_worker.allergies || "-",
			}
			acc[entry.label] ? acc[entry.label].push(worker) : (acc[entry.label] = [worker])
			return acc
		}, {})
		return acc
	}, {})
}

export const normalizeTimesheetsData = data => {
	let schedules = data.map(workerTimesheet => {
		let needsMapping = false
		let eventsForMonth = Object.keys(workerTimesheet.events_for_month).reduce((acc, day) => {
			let eventsForDay = workerTimesheet.events_for_month[day]

			acc[day] = eventsForDay.map(event => {
				if (
					!event.affiliate_deleted &&
					!event.social_secretary_code &&
					!(event.event_type === "other_activity")
				) {
					needsMapping = true
				}
				let start = moment(day + " " + event.start_time),
					end = moment(day + " " + event.end_time)
				if (!start.isValid()) {
					start = moment(event.start_time)
					end = moment(event.end_time)
				}
				let duration = moment.duration(end.diff(start))
				let displayDuration = `${duration.hours()}:${
					duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()
				}`
				return {
					...event,
					start_time: start,
					end_time: end,
					duration: duration.asMinutes(),
					displayDuration,
				}
			}, {})

			return acc
		}, {})

		let timesheetWorkerContract = workerTimesheet.worker.worker_contracts.find(
			entry => entry.id === workerTimesheet.worker.id
		)

		if (
			["variable", "variable_variable"].includes(workerTimesheet.worker.contract_variability)
		) {
			workerTimesheet.codes_stats.monthlyTotalHoursTranslateArgs = hoursShorthand => {
				return [
					"timesheet_monthly_total_durations_no_th",
					{
						allCodesTotal: formatMinutes(
							Math.round(workerTimesheet.codes_stats.month.sum_all_codes * 100) / 100,
							hoursShorthand
						),
					},
				]
			}
		} else {
			workerTimesheet.codes_stats.monthlyTotalHoursTranslateArgs = hoursShorthand => {
				return [
					"timesheet_monthly_total_durations",
					{
						allCodesTotal: formatMinutes(
							Math.round(workerTimesheet.codes_stats.month.sum_all_codes * 100) / 100,
							hoursShorthand
						),
						thTotal: formatMinutes(
							Math.round(workerTimesheet.codes_stats.month.sum_ths * 100) / 100,
							hoursShorthand
						),
					},
				]
			}
		}
		return {
			worker: {
				id: workerTimesheet.worker.id,
				affiliateWorkerID: workerTimesheet.worker.affiliate_worker_id,
				displayName: workerTimesheet.worker.display_name,
				address: workerTimesheet.worker.address,
				phone: workerTimesheet.worker.telephone,
				email: workerTimesheet.worker.email,
				nissNumber: workerTimesheet.worker.niss,
				sodexoNumber: workerTimesheet.worker.sodexo_reference,
				sodexoPin: workerTimesheet.worker.sodexo_pin,
				contractVariability: workerTimesheet.worker.contract_variability,
				schedulePeriods: workerTimesheet.worker.schedule_periods,
				minutesPlannedPerWeek: workerTimesheet.worker.minutes_planned_per_week,
				needsUpfrontPayment: workerTimesheet.worker.needs_upfront_payment,
				workerContracts: workerTimesheet.worker.worker_contracts,
			},
			timesheetInfo: {
				timesheetID: workerTimesheet.timesheet_id,
				closureDate: workerTimesheet.closure_date,
				lastExportDate: workerTimesheet.last_exported_at,
				workerComment: workerTimesheet.worker_comment,
				secSocComment: workerTimesheet.social_secretary_comment,
				tscs: workerTimesheet.timesheet_stalled_corrections || [],
				timesheetWorkerContractEndDate: timesheetWorkerContract.end_date
					? moment(timesheetWorkerContract.end_date)
					: undefined,
				intermediateClosureDates: workerTimesheet.intermediate_closure_dates
					? workerTimesheet.intermediate_closure_dates.map(entry => moment(entry))
					: [],
				needsMapping,
			},
			eventsForMonth,
			codesStats: workerTimesheet.codes_stats,
			workInterruptionWarnings: workerTimesheet.work_interruption_warnings,
		}
	})
	return schedules
}

export const normalizeDocumentTemplate = data => {
	let parsed = {
		id: Number(data.id),
		templateName: data.attributes.template_name,
		templateTarget: data.attributes.target,
	}
	if (data.attributes.template_body) {
		parsed.templateBody = data.attributes.template_body
	}
	if (data.attributes.variables) {
		parsed.variables = data.attributes.variables
	}
	return parsed
}

const typeMap = {
	OTHER: "other",
	AVAILABILITY: "avail",
	BOOKING: "booking",
	THEORETICAL_HOUR: "theo-hour",
	ABSENCE: "absence",
}
export const normalizeWorkerWeek = data => {
	let agendaObject = data.events[0]
	let weekStart = moment(data.start_time)
	let weekEnd = moment(weekStart).add(6, "days")
	return Object.entries(agendaObject.events_by_date).reduce((acc, [date, events]) => {
		if (!moment(date).isBetween(weekStart, weekEnd, "day", "[]")) {
			return acc
		}
		events.forEach(event => {
			// let start = moment(event.start_time).set({ year: 2000, month: 0, date: 1 })
			// let end = moment(event.end_time).set({ year: 2000, month: 0, date: 1 })
			if (event.event.event_type === "UNPLANNED_WORK") {
				return
			}
			let start = moment(date).set({
				hour: event.start_time.hour,
				minute: event.start_time.minute,
			})
			let end = moment(date).set({
				hour: event.end_time.hour,
				minute: event.end_time.minute,
			})
			let duration = end.diff(start, "minutes")
			let startDate = moment(date)
			let timeRange = {
				id: event.real_event_id,
				type: typeMap[event.event.event_type],
				dayPeriod: event.event.day_period,
				startDate,
				start,
				end,
				duration,
			}
			if (timeRange.type === "booking") {
				timeRange.extraInfo = [
					{ label: "customer", value: event.customer_display_name },
					{
						label: "recurrence",
						value: { 7: "every_week", 14: "every_two_weeks", 28: "every_four_weeks" }[
							event.event.day_period
						],
					},
				]
			}
			acc.push(timeRange)
		})
		return acc
	}, [])
}

export const normalizeSecSocTermMapping = mapping => ({
	id: parseInt(mapping.id, 10),
	ssCode: mapping.attributes.social_secretary_code,
	pEvent: mapping.attributes.event,
})

export const normalizeAvailableLaundryOrderItem = avOrderItem => ({
	id: Number(avOrderItem.id),
	name: avOrderItem.attributes.name,
	longName: avOrderItem.attributes.long_name,
	categoryName: avOrderItem.attributes.category,
	price: avOrderItem.attributes.price,
	unit: avOrderItem.attributes.unit,
	unitSymbol: avOrderItem.attributes.unit_symbol,
})

const normalizeLaundryOrderItem = item => ({
	id: item.id,
	avItemID: item.av_item_id,
	category: item.category,
	cost: item.cost,
	name: item.name,
	longName: item.long_name,
	price: item.price,
	quantity: item.quantity,
	unit: item.unit,
	unitSymbol: item.unit_symbol,
})

export const normalizeWorkshopOrderQuote = quote => ({
	estimatedDuration: quote.estimated_duration_minutes,
	estimatedPrice: quote.estimated_price,
	customerBalance: quote.customer_balance,
	details: quote.details.map(entry => ({
		categoryName: entry.category_name,
		items: entry.items.map(normalizeLaundryOrderItem),
		unit: entry.unit,
		unitSymbol: entry.unit_symbol,
		totalDetails: entry.total_details,
	})),
})

export const normalizeWorkshopOrderLine = data => {
	return {
		id: data.id,
		displayId: data.attributes.affiliate_sequence_number,
		dropOffDate: moment(data.attributes.drop_off_date),
		estimatedPrice: data.attributes.estimated_price,
		estimatedDurationMinutes: data.attributes.estimated_duration_minutes,
		estimatedBillableSV: data.attributes.estimated_billable_service_voucher,
		billableSV: data.attributes.billable_service_voucher,
		sodexoBilledHours: data.attributes.sodexo_billed_hours,
		bestKnownBillableSV: data.attributes.best_known_billable_voucher,
		priority: data.attributes.priority,
		workerName: data.attributes.worker_name,
		customerName: data.attributes.customer_name,
		svType: data.attributes.sv_type,
		executionDate: moment(data.attributes.execution_date),
		status: data.attributes.display_status,
		paymentStatus: data.attributes.payment_status,
		executedDurationMinutes: data.attributes.executed_duration_minutes,
		pickUpDate: moment(data.attributes.scheduled_pick_up_date),
		cashReceived: data.attributes.cash_received,
		sodexoStatus: data.attributes.sodexo_status,
		customerSodexoRef: data.attributes.customer_sodexo_reference,
		workerSodexoRef: data.attributes.worker_sodexo_reference,
		forcedAffiliateWorkerId: data.attributes.forced_affiliate_worker_id,
		psvs: data.attributes.psvs,
		billableHoursOverride: data.attributes.billable_hours_override,
		quote: data.attributes.quote,
	}
}

export const normalizeWorkshopOrder = wo => {
	let worker = wo.attributes.worker || {}
	let customer = wo.attributes.customer_contract || {}
	return {
		id: wo.id,
		displayId: wo.attributes.affiliate_sequence_number,
		dropOffDate: moment(wo.attributes.drop_off_date),
		priority: wo.attributes.priority,
		svType: wo.attributes.sv_type,
		executionDate: moment(wo.attributes.execution_date),
		startTime: wo.attributes.start_execution_datetime
			? moment(wo.attributes.start_execution_datetime)
			: null,
		endTime: wo.attributes.end_execution_datetime
			? moment(wo.attributes.end_execution_datetime)
			: null,
		scheduledPickupDate: moment(wo.attributes.scheduled_pick_up_date),
		effectivePickUpDate: moment(wo.attributes.effective_pick_up_date),
		status: wo.attributes.display_status,
		executedDurationMinutes: wo.attributes.executed_duration_minutes,
		workshopOrderBillingBasis: "estimated",
		items: wo.attributes.items.map(normalizeLaundryOrderItem),
		quote: normalizeWorkshopOrderQuote(wo.attributes.quote),
		cashReceived: wo.attributes.cash_received,
		billableMinutes: wo.attributes.billable_minutes,
		billableSV: wo.attributes.billable_service_voucher,
		customerName: `${customer.first_name} ${customer.last_name}`,
		workerName: `${worker.first_name} ${worker.last_name}`,
		balanceMinutesUsed: wo.attributes.balance_minutes_used,
		estimatedPrice: wo.attributes.estimated_price,
		paymentMethod: wo.attributes.payment_method,
		paymentStatus: wo.attributes.payment_status,
		linkedPSVs: wo.attributes.paper_service_vouchers,
		workshopId: wo.attributes.workshop_id,
		sodexoStatus: wo.attributes.sodexo_status,
		sodexoWorkId: wo.attributes.sodexo_work_id,
		billableHoursOverride: wo.attributes.billable_hours_override,
		worker: {
			id: worker.id,
			title: worker.title,
			firstName: worker.first_name,
			lastName: worker.last_name,
			telephone: worker.telephone,
			email: worker.email,
			sodexoPin: worker.sodexo_pin,
			sodexoRef: worker.sodexo_reference,
		},
		customer: {
			id: customer.id,
			firstName: customer.first_name,
			lastName: customer.last_name,
			telephone: customer.telephone,
			email: customer.email,
			sodexoRef: customer.sodexo_reference,
			voucherTypePrefence: customer.voucher_type_preference,
			lang: customer.default_language,
			balance: wo.attributes.customer_balance,
		},
	}
}

export const normalizeImpactedBookings = (impactedBookings, allowedOptions) => {
	let groupedByBookings = impactedBookings.reduce((acc, impactedOccurrence) => {
		let bookingID = impactedOccurrence.parent_booking_id
		impactedOccurrence.action = "create_pending_replacement" // default to replacement creation
		if (acc[bookingID]) {
			acc[bookingID].impactedOccurrences.push(impactedOccurrence)
		} else {
			acc[bookingID] = {
				workerName: impactedOccurrence.worker_display_name,
				customer: impactedOccurrence.customer_display_name,
				recurrenceDelay: impactedOccurrence.recurrence_delay,
				manualOccurrencesProcess: allowedOptions.includes("impacted_occurrences"), // default to manual oc process if it's allowed
				impactedOccurrences: [impactedOccurrence],
			}
		}
		return acc
	}, {})

	return groupedByBookings
}

export const normalizeNotification = notif => {
	return {
		id: notif.id,
		event: notif.attributes.event,
		author: notif.attributes.author,
		subject: notif.attributes.subject,
		createdAt: moment(notif.attributes.created_at),
		isRead: notif.attributes.read,
		message: notif.attributes.message,
	}
}

export const normalizeTask = task => {
	return {
		id: Number(task.id),
		taskTitle: task.attributes.title,
		taskCategory: task.attributes.category,
		taskScheduledDate: moment(task.attributes.scheduled_date),
		done: task.attributes.done,
		taskAuthor: task.attributes.author,
		taskAssignees: task.attributes.assignees.map(entry => ({
			label: entry.display_name,
			value: entry.affiliate_user_id,
		})),
		taskBody: task.attributes.task_body,
		taskCreatedAt: moment(task.attributes.created_at),
	}
}

export const normalizeTimesheetMetaInfos = timesheetMetaInfos => {
	return {
		timesheet_id: timesheetMetaInfos.attributes.id,
		needsUpfrontPayment: timesheetMetaInfos.attributes.needs_upfront_payment,
		needsCorrectionWarning: timesheetMetaInfos.attributes.needs_correction_warning,
		exportedAt: timesheetMetaInfos.attributes.exported_at,
		closedAfterLastTsc: timesheetMetaInfos.attributes.closed_after_last_tsc,
		exportTypes: timesheetMetaInfos.attributes.social_secretary_exports_types,
		worker_contract_id: timesheetMetaInfos.attributes.worker_contract_id,
		worker_name: timesheetMetaInfos.attributes.worker_name,
		contractStart: moment(timesheetMetaInfos.attributes.contract_start),
		contractEnd: timesheetMetaInfos.attributes.contract_end
			? moment(timesheetMetaInfos.attributes.contract_end)
			: "",
		contractFamilyStart: moment(timesheetMetaInfos.attributes.contract_family_start),
		contractFamilyEnd: timesheetMetaInfos.attributes.contract_family_end
			? moment(timesheetMetaInfos.attributes.contract_family_end)
			: "",
		displayContractDates: false,
		squad_id: timesheetMetaInfos.attributes.squad_id || "no_squad",
		isClosed: timesheetMetaInfos.attributes.timesheet_is_closed,
		intermediateClosureDates: timesheetMetaInfos.attributes.intermediate_closure_dates.map(
			entry => moment(entry)
		),
	}
}

export const normalizeTimesheetMeta = timesheetsMeta => {
	let allTimesheets = []
	let closedTimesheets = []
	let openedTimesheets = []
	let upfrontPaymentTimesheets = []
	let timesheetsNeedingCorrection = []
	let timesheetsClosedAfterLastTsc = []
	Object.entries(timesheetsMeta).forEach(([awId, awTimesheets]) => {
		awTimesheets.forEach(timesheet => {
			let normTimesheet = normalizeTimesheetMetaInfos(timesheet)
			if (awTimesheets.length > 1) {
				normTimesheet.displayContractDates = true
			}
			allTimesheets.push(normTimesheet)
			if (normTimesheet.needsUpfrontPayment) {
				upfrontPaymentTimesheets.push(normTimesheet)
			}
			if (normTimesheet.closedAfterLastTsc) {
				timesheetsClosedAfterLastTsc.push(normTimesheet)
			}
			if (normTimesheet.needsCorrectionWarning) {
				timesheetsNeedingCorrection.push(normTimesheet)
			}
			if (normTimesheet.isClosed) {
				closedTimesheets.push(normTimesheet)
			} else {
				openedTimesheets.push(normTimesheet)
			}
		})
	})

	return {
		allTimesheets,
		closedTimesheets,
		openedTimesheets,
		upfrontPaymentTimesheets,
		timesheetsNeedingCorrection,
		timesheetsClosedAfterLastTsc,
	}
}

export const normalizeBookingWish = bookingWish => {
	let bw = {
		id: bookingWish.id,
		groupId: bookingWish.attributes.wishes_group_id,
		customer: bookingWish.attributes.customer_contract,
		worker: bookingWish.attributes.worker_to_replace,
		status: bookingWish.attributes.status,
		note: bookingWish.attributes.note,
		occurrenceToReplace: {
			id: bookingWish.attributes.replaced_occurrence_id,
			startTime: moment(bookingWish.attributes.occurrence_to_replace.start_time),
			endTime: moment(bookingWish.attributes.occurrence_to_replace.end_time),
			voucherType: bookingWish.attributes.occurrence_to_replace.voucher_type,
			date: moment(bookingWish.attributes.occurrence_to_replace.date),
			workerName: bookingWish.attributes.occurrence_to_replace.worker_name,
			address: bookingWish.attributes.occurrence_to_replace.address,
		},
		wishedDateranges: bookingWish.attributes.wished_dateranges.map(entry => ({
			id: entry.id,
			from: moment(entry.from),
			to: moment(entry.to),
			dayOfWeek: entry.day_of_week,
			fromTime: moment(entry.from_time),
			toTime: moment(entry.to_time),
			isRecurrent: entry.is_recurrent,
		})),
	}
	return bw
}

export const normalizeBookingWishes = ({ bookingWishes, triggers }) => {
	let bookingWishGroups = bookingWishes.reduce((acc, bookingWish) => {
		let groupId = bookingWish.attributes.wishes_group_id
		let normalizedBw = normalizeBookingWish(bookingWish)
		if (acc[groupId]) {
			acc[groupId].bookingWishes.push(normalizedBw)
			acc[groupId].allWishedDateranges.concat(normalizedBw.wishedDateranges)
			if (normalizedBw.occurrenceToReplace.date) {
				acc[groupId].replacedOccurrencesDates.push({
					selected: true,
					date: normalizedBw.occurrenceToReplace.date,
					id: normalizedBw.occurrenceToReplace.id,
				})
			}
		} else {
			acc[groupId] = {
				id: groupId,
				triggerType: bookingWish.attributes.trigger_type,
				bookingWishes: [normalizedBw],
				trigger: triggers[groupId],
				allWishedDateranges: normalizedBw.wishedDateranges,
				replacedOccurrencesDates: [],
				isHighlighted: false,
				color: null,
			}
			if (normalizedBw.occurrenceToReplace.date) {
				acc[groupId].replacedOccurrencesDates.push({
					selected: true,
					date: normalizedBw.occurrenceToReplace.date,
					id: normalizedBw.occurrenceToReplace.id,
				})
			}
		}
		return acc
	}, {})

	Object.entries(bookingWishGroups).forEach(([bwgId, entry]) => {
		let earliestOccurrenceToReplace = entry.bookingWishes
			.filter(bw => bw.status === "PENDING")
			.reduce((acc, bw) => {
				if (!acc) {
					acc = bw
				} else {
					acc = acc.occurrenceToReplace.date.isBefore(bw.occurrenceToReplace.date)
						? acc
						: bw
				}
				return acc
			}, undefined)

		if (earliestOccurrenceToReplace) {
			entry.singleOccurrenceStagedForReplacement =
				earliestOccurrenceToReplace.occurrenceToReplace.id
		}
	})

	return bookingWishGroups
}

export const normalizeSodexoWork = sw => {
	let subject = null
	if (sw.attributes.subject) {
		subject =
			sw.attributes.subject_type === "LaundryOrder"
				? normalizeWorkshopOrderLine(sw.attributes.subject.data)
				: normalizeBookingLine(sw.attributes.subject.data)
	}
	return {
		id: sw.id,
		date: moment(sw.attributes.date),
		scheduledDate: moment(sw.attributes.scheduled_date),
		affiliateId: sw.attributes.affiliate_id,
		customerIdentifier: sw.attributes.customer_identifier,
		workerIdentifier: sw.attributes.worker_identifier,
		workerSodexoRef: sw.attributes.worker_sodexo_reference,
		workerName: sw.attributes.worker_name,
		customerName: sw.attributes.customer_name,
		subject: subject,
		subjectType: sw.attributes.subject_type,
		status: sw.attributes.status,
		type: sw.attributes.subject_type,
		isLinked: !!sw.attributes.subject_id,
		sodexoBookingId: sw.attributes.sodexo_booking_id,
	}
}

export const normalizeError = (obj, parent, res = {}) => {
	for (let key in obj) {
		let propName = parent ? parent + "_" + key : key
		if (typeof obj[key] == "object") {
			normalizeError(obj[key], propName, res)
		} else {
			res[propName] = obj[key]
		}
	}
	return res
}
