import { combineReducers } from "redux"
import * as ActionTypes from "../actions"
import moment from "moment"
import {
	normalizeBookingLine,
	normalizeDocumentTemplate,
	normalizeTimeslots,
	normalizeWorkshopOrderQuote,
	normalizeTimesheetMeta,
	normalizeDimonaLine,
	normalizeSodexoWork,
} from "../normalizers/Normalizers"
import { ALL_BADGES } from "../constants/agendaBadges"

const workerPageInit = {
	activityCode: "",
	activityCategory: "",
	absenceNote: "",
	absenceStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
	absenceEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
	formC32ANumber: "",
	mustCallOnss: true,
	showSaveButton: false,
	showNewAbsenceModal: false,
	areButtonsCollapsed: false,
	showDataExportExplanationModal: false,
}
const workerPage = (state = workerPageInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKER_PAGE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.CREATEABSENCE_SUCCESS:
			return {
				...state,
				activityCode: "",
				activityCategory: "",
				absenceNote: "",
				absenceStart: moment(),
				absenceEnd: moment(),
			}
		case ActionTypes.LOGOUT:
			return workerPageInit
		default:
			return state
	}
}

const trainingsPageInit = {
	activityCode: "",
	activityCategory: "",
	trainingNote: "",
	selectedWorkers: [],
	trainingStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
	trainingEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
	showSaveButton: false,
	showNewTrainingModal: false,
	areButtonsCollapsed: false,
	showDataExportExplanationModal: false,
	workerIndex: 0,
	impactedBookings: [],
}
const trainingsPage = (state = trainingsPageInit, action) => {
	switch (action.type) {
		case ActionTypes.TRAININGS_PAGE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.CREATETRAINING_SUCCESS:
			return {
				...state,
			}
		case ActionTypes.LOGOUT:
			return trainingsPageInit
		default:
			return state
	}
}

const singularTrainingsPageInit = {
	showEditTrainingModal: false,
	showDeleteWarning: false,
	currentDeletedTraining: -1,
	activeCommentBoxIndex: -1,
	currentEditedTraining: -1,
	activityCode: "",
	activityCategory: "",
	trainingNote: "",
	trainingStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
	trainingEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
	trainingType: "",
	showRequired: {},
	currentPage: 1,
	pagination: {},
	rangeStart: moment(),
	rangeEnd: moment(),
	selectedCode: null,
	searchedAffiliateWorker: {},
	searchedAffiliateWorkerId: null,
}
const singularTrainingsPage = (state = singularTrainingsPageInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHSINGULARTRAININGS_SUCCESS:
			return { ...state, pagination: { ...action.meta.pagination } }
		case ActionTypes.SINGULAR_TRAININGS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return trainingsPageInit
		default:
			return state
	}
}

const groupTrainingsPageInit = {
	showEditTrainingModal: false,
	showDeleteWarning: false,
	currentDeletedTraining: -1,
	activeCommentBoxIndex: -1,
	currentEditedTraining: -1,
	activityCode: "",
	activityCategory: "",
	trainingNote: "",
	trainingStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
	trainingEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
	trainingType: "",
	showRequired: {},
	currentPage: 1,
	pagination: {},
	rangeStart: moment(),
	rangeEnd: moment(),
	selectedCode: null,
	searchedAffiliateWorker: {},
	searchedAffiliateWorkerId: null,
}
const groupTrainingsPage = (state = groupTrainingsPageInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHGROUPTRAININGS_SUCCESS:
			return { ...state, pagination: { ...action.meta.pagination } }
		case ActionTypes.GROUP_TRAININGS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return trainingsPageInit
		default:
			return state
	}
}

const workerAbsencesInit = {
	showCancelWarning: false,
	absencePendingCancel: -1,
	showEditAbsenceModal: false,
	showRefusalReasonModal: false,
	showActivityCodeSelectionModal: false,
	showImpactedBookingsForEditedAbsence: false,
	showImpactedBookingsModalForAbsenceApproval: false,
	pagination: {},
}
const workerAbsences = (state = workerAbsencesInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERABSENCES_SUCCESS:
			return { state, pagination: { ...action.meta.pagination } }
		case ActionTypes.WORKER_ABSENCES_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.WORKER_ABSENCES_CANCEL_WARNING:
			return {
				...state,
				absencePendingCancel: action.absenceIndex,
				showCancelWarning: true,
			}
		case ActionTypes.REFUSEABSENCE_SUCCESS:
		case ActionTypes.REFUSEABSENCE_FAILURE:
			return { ...state, showRefusalReasonModal: false }
		case ActionTypes.APPROVEABSENCE_SUCCESS:
			return { ...state, showActivityCodeSelectionModal: false }
		case ActionTypes.LOGOUT:
			return workerAbsencesInit
		default:
			return state
	}
}

let yearMonths = []
let oneYearAgo = moment()
	.subtract(12, "month")
	.startOf("month")
for (let i = 0; i < 14; i++) {
	yearMonths.push(
		moment(oneYearAgo)
			.add(i, "month")
			.startOf("month")
	)
}
let currentMonthStart = moment().startOf("month")
const timesheetsInitialState = {
	selectedMonth: currentMonthStart,
	activeWorkersForMonth: [],
	selectedWorker: "0",
	selectedTab: 0,
	editMode: false,
	showNewEventModal: false,
	showPostClosureModificationWarningModal: false,
	showUnclosableTimesheetWarningModal: false,
	showPDFModal: false,
	closedTimesheets: [],
	openedTimesheets: [],
	upfrontPaymentTimesheets: [],
	timesheetsNeedingCorrection: [],
	timesheetsClosedAfterLastTsc: [],
	allTimesheets: [],
	showCustomizePDFModal: false,
	showIntermediateClosureModal: false,
	showTimesheetExportModal: false,
	showRefreshCompareResults: false,
	customizePDFModalOrigin: "",
	yearMonths: yearMonths,
	selectedSquads: [],
	refreshCompareResults: {},
}
const timesheetsComponent = (state = timesheetsInitialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCHSCHEDULES_SUCCESS:
		case ActionTypes.CLOSE_TIMESHEET_MONTH_SUCCESS:
			return {
				...state,
				selectedWorker:
					action.data.merged_timesheets.length > 0
						? action.data.merged_timesheets[0].worker.id
						: "0",
				...normalizeTimesheetMeta(action.data.timesheets_list),
				activeWorkersForMonth: Object.entries(action.data.timesheets_list).reduce(
					(acc, [awId, timesheets]) => {
						timesheets.forEach(entry => {
							acc.push({
								worker_contract_id: entry.attributes.worker_contract_id,
								affiliate_worker_id: entry.attributes.affiliate_worker_id,
								display_name: entry.attributes.worker_name,
								contractStart: moment(entry.attributes.contract_start),
								contractEnd: entry.attributes.contract_end,
							})
						})
						return acc
					},
					[]
				),
			}
		case ActionTypes.TIMESHEETS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_TIMESHEETS_COMPONENT:
			return timesheetsInitialState
		default:
			return state
	}
}

const agendaInitialState = {
	customInitState: {},
	currentPage: 1,
	fetching: false,
	pagesCount: undefined,
	pagesFetched: [],
	showPreviousRowsSpinner: false,
	showNextRowsSpinner: false,
	now: moment(),
	days: [],
	hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
	workersFilters: [],
	customersFilters: [],
	filterOnlyAvailbleThs: false,
	economicUnemploymentFilters: [],
	zipFilters: [],
	showEditBookingModal: false,
	showDirectBookingModal: false,
	showAbsenceModal: false,
	showDeleteAbsenceModal: false,
	showDeleteHolidayModal: false,
	showRestoreHolidayModal: false,
	showDeleteAbsenceWarningModal: false,
	absencePendingCancel: -1,
	showExtraAvailabilityModal: false,
	showDeleteExtraAvailModal: false,
	showDeleteBookingModal: false,
	showCancelBookingModal: false,
	showWeekPicker: false,
	showOtherActivityModal: false,
	showWorkshopHourModal: false,
	showEditWorkshopHourModal: false,
	showDirectBookingModificationModal: false,
	showMissingLaundryOrderModal: false,
	showImpactedBookingsModalForAbsence: false,
	showImpactedBookingsModalForOA: false,
	filtersDropdownActive: false,
	hoursWidth: 0,
	minutesWidth: 0,
	activeWorkerDrop: -1,
	activeTooltipRange: { ti: -1, ci: -1, awi: -1 },
	lastActiveRange: { ti: -1, ci: -1, awi: -1 },
	otherActivityInit: { start: moment.invalid() },
	fullMonthAffiliateWorkerId: undefined,
	postFetchAction: undefined,
	timerangeDropdownWhitelist: [],
	customFullMonthRequest: undefined,
}
const agenda = (state = agendaInitialState, action) => {
	let days = []
	let weekStart
	switch (action.type) {
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST:
		case ActionTypes.EVENT_AGENDA_REQUEST:
			return { ...state, fetching: true }
		case ActionTypes.AGENDA_REQUEST:
		case ActionTypes.ADD_AGENDA_ROWS_REQUEST:
		case ActionTypes.ADD_PREVIOUS_EVENT_AGENDA_ROWS_REQUEST:
			return { ...state, showPreviousRowsSpinner: true, fetching: true }
		case ActionTypes.ADD_NEXT_EVENT_AGENDA_ROWS_REQUEST:
			return { ...state, showNextRowsSpinner: true, fetching: true }
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_FAILURE:
			return { ...state, showNextRowsSpinner: false, fetching: false }
		case ActionTypes.AGENDA_SUCCESS:
		case ActionTypes.EVENT_AGENDA_SUCCESS:
		case ActionTypes.ADD_AGENDA_ROWS_SUCCESS:
		case ActionTypes.ADD_PREVIOUS_EVENT_AGENDA_ROWS_SUCCESS:
		case ActionTypes.ADD_NEXT_EVENT_AGENDA_ROWS_SUCCESS:
			days = []
			weekStart = moment(action.start_time)
			for (let m = moment(weekStart); m.isSame(weekStart, "isoweek"); m.add(1, "day")) {
				days.push(moment(m))
			}
			return {
				...state,
				days,
				pagesCount: action.pages_count,
				currentPage: action.current_page,
				pagesFetched: [...state.pagesFetched, action.current_page],
				showPreviousRowsSpinner: false,
				showNextRowsSpinner: false,
				fetching: false,
			}
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS:
			days = []
			weekStart = moment(action.agendas.start_time)
			for (let m = moment(weekStart); m.isSame(weekStart, "isoweek"); m.add(1, "day")) {
				days.push(moment(m))
			}
			return {
				...state,
				days,
				pagesCount: action.pages_count,
				currentPage: action.current_page,
				pagesFetched: [...state.pagesFetched, action.current_page],
				showPreviousRowsSpinner: false,
				showNextRowsSpinner: false,
				fetching: false,
			}
		case ActionTypes.ADD_PREVIOUS_EVENT_AGENDA_ROWS_FAILURE:
		case ActionTypes.ADD_NEXT_EVENT_AGENDA_ROWS_FAILURE:
			return {
				...state,
				showPreviousRowsSpinner: false,
				showNextRowsSpinner: false,
				fetching: false,
			}
		case ActionTypes.AGENDA_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.DELETEEXTRAAVAIL_SUCCESS:
			return { ...state, showDeleteExtraAvailModal: false }
		case ActionTypes.SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS:
			return { ...state, showDirectBookingModal: false }
		case ActionTypes.SUBMIT_WORKSHOP_HOUR_SUCCESS:
			return { ...state, showWorkshopHourModal: false }
		case ActionTypes.SUBMIT_EDIT_WORKSHOP_HOUR_SUCCESS:
			return { ...state, showEditWorkshopHourModal: false }
		case ActionTypes.AGENDA_ABSENCES_DELETE_WARNING:
			return {
				...state,
				absencePendingCancel: action.absenceId,
				showDeleteAbsenceModal: false,
				showDeleteAbsenceWarningModal: true,
			}
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_SUCCESS:
			return { ...state, showDirectBookingModificationModal: false }
		case ActionTypes.RESET_AGENDA_COMPONENT_FILTERS:
			return {
				...state,
				workersFilters: [],
				customersFilters: [],
				economicUnemploymentFilters: [],
				zipFilters: [],
			}
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_AGENDA:
			return agendaInitialState
		default:
			return state
	}
}

const deleteBookingModalInitialState = {
	recurrentBooking: false,
	bookingID: "",
	deliveryDate: "",
	requestMeta: {},
}
const deleteBookingModal = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.DELETEBOOKINGMODAL_INIT:
			return { ...action.init }
		case ActionTypes.LOGOUT:
			return deleteBookingModalInitialState
		default:
			return state
	}
}

const deleteOtherActivityModalInitialState = { otherActivity: {}, requestMeta: {} }
const deleteOtherActivityModal = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.DELETE_OTHERACTIVITY_MODAL_INIT:
			return { otherActivity: action.otherActivity }
		case ActionTypes.LOGOUT:
			return deleteOtherActivityModalInitialState
		default:
			return state
	}
}

const cancelBookingModalInitialState = {
	recurrentBooking: false,
	bookingID: "",
	deliveryDate: "",
	requestMeta: {},
	createPendingBookingWishes: false,
	showRequired: { reach: false },
	reach: "",
}
const cancelBookingModal = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.CANCELBOOKINGMODAL_INIT:
			return { ...action.init }
		case ActionTypes.CANCELBOOKINGMODAL_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return cancelBookingModalInitialState
		default:
			return state
	}
}

const bookingsInitialState = { pagination: {} }
const bookingsComponent = (state = bookingsInitialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERBOOKINGS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
			return bookingsInitialState
		default:
			return state
	}
}

const newBookingInitialState = {
	showNewCustomerModal: false,
	showNewResidenceModal: false,
	makeWorkersBlink: false,
	serverWarnings: [],
	currentStep: 1,
	timeSlotsPerDate: [],
	showTimeSlots: false,
	showTimeslotsScrollHint: false,
	showFeaturedWorker: false,
	showWorkersScrollHint: false,
	editionMode: false,
	layout: "page",
	editedBookingID: "",
	editedBookingDate: "",
}
const newBookingComponent = (state = newBookingInitialState, action) => {
	switch (action.type) {
		case ActionTypes.NEWBOOKING_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.EDITBOOKING_INIT:
			return {
				...newBookingInitialState,
				editionMode: true,
				layout: "modal",
				editedBookingID: action.editedBookingID,
				editedBookingDate: action.editedBookingDate,
			}
		case ActionTypes.NEWBOOKING_GOTOSTEP:
			return { ...state, currentStep: action.step, canSubmit: false }
		case ActionTypes.WORKERSUGGESTIONS_SUCCESS:
			return { ...state, currentStep: 1 }
		case ActionTypes.RESET_TIMESLOTS:
			return { ...state, deliveryDates: [], timeSlotsPerDate: [] }
		case ActionTypes.TIMESLOTS_SUCCESS:
			return {
				...state,
				timeSlotsPerDate: {
					...state.timeSlotsPerDate,
					...normalizeTimeslots(action),
				},
			}
		case ActionTypes.NEWBOOKING_READYFORSUBMIT:
			return { ...state, canSubmit: true }
		case ActionTypes.RESET_NEWBOOKING_FORM:
		case ActionTypes.LOGOUT:
			return newBookingInitialState
		default:
			return state
	}
}

const workersComponent = (
	state = {
		pagination: {},
		filters: {},
		showWorkerLimitReachedModal: false,
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.WORKERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.WORKERSFILTERS_SUCCESS:
			let filterResponse = { ...action }
			delete filterResponse.type
			return { ...state, filters: filterResponse }
		case ActionTypes.LOGOUT:
			return { pagination: {}, filters: {} }
		default:
			return state
	}
}
const communicationComponent = (
	state = {
		pagination: {},
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.FETCHBATCHWORKERMESSAGES_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		default:
			return state
	}
}

const workerComponent = (state = { pagination: {} }, action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
			return { pagination: {} }
		default:
			return state
	}
}

let customersComponentInit = {
	pagination: {},
	filters: {},
}
const customersComponent = (state = customersComponentInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCHCUSTOMERS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.CUSTOMERSFILTERS_SUCCESS:
			let filterResponse = { ...action }
			delete filterResponse.type
			return { ...state, filters: filterResponse }
		case ActionTypes.LOGOUT:
			return { pagination: {}, filters: {} }
		default:
			return state
	}
}

const customerComponent = (state = { pagination: {} }, action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
			return { pagination: {} }
		default:
			return state
	}
}

const workerContractInitialState = {
	selectedContractID: "",
	editedKeys: {},
	errors: [],
	schedErrors: [],
	selectedForgetDate: "",
	showRequired: {},
	selectedSchedulePeriod: 0,
	showforgetAffiliateWorkerModal: false,
	showDeleteWorkerContractModal: false,
	showRecurrentBookings: true,
	showDimonaModal: false,
	showEconomicSuspensionModal: false,
}
const workerContractComponent = (state = workerContractInitialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKER_SUCCESS:
			let id = ""
			action.data.attributes.worker_contracts.data.forEach(entry => {
				if (!id || entry.attributes.status === "active") {
					id = entry.id
				}
			})
			return { ...state, selectedContractID: parseInt(id, 10) }
		case ActionTypes.SUBMIT_NEW_WORKER_CONTRACT_SUCCESS:
			return { ...state, selectedContractID: action.meta.created_worker_contract_id }
		case ActionTypes.SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS:
			return {
				...state,
				editedKeys: { ...state.editedKeys, [action.requestMeta.previousContractID]: [] },
				selectedContractID: action.meta.created_worker_contract_id,
			}
		case ActionTypes.SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS:
			return {
				...state,
				editedKeys: { ...state.editedKeys, [action.meta.updated_worker_contract_id]: [] },
				selectedContractID: action.meta.updated_worker_contract_id,
			}
		case ActionTypes.WORKER_CONTRACT_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.CONTRACT_V2_METACHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.CONTRACT_V2_UI_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCHWORKER_REQUEST:
		case ActionTypes.LOGOUT:
			return workerContractInitialState
		default:
			return state
	}
}

const economicSuspensionsInit = {
	startDate: moment().add(8, "days"),
	searchedAffiliateWorker: { name: "" },
	showOnssErrorModal: false,
	showWorkersSelectionModal: false,
	showEcoSuspModalForBatchCreation: false,
	onssErrors: "",
	status: "",
	squadsFilter: [],
	selectedWorkersForBatchSuspensionCreation: [],
}
const economicSuspensionsComponent = (state = economicSuspensionsInit, action) => {
	switch (action.type) {
		case ActionTypes.ECONOMIC_UNEMPLOYMENT_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_ECONOMIC_SUSPENSIONS_SUCCESS:
			return {
				...state,
				showSmallSuspensionModal: false,
				showBigSuspensionModal: false,
			}
		case ActionTypes.RESET_ECONOMIC_UNEMPLOYMENT_STATE:
			return { ...economicSuspensionsInit }
		case ActionTypes.LOGOUT:
			return { ...economicSuspensionsInit }
		default:
			return state
	}
}

const newEconomicSuspensionsModalComponentInit = {
	chosenWorker: { name: "" },
	startDate: "",
	endDate: "",
	suspensionType: "",
	declarationOnHonour: "",
	jointCommissionLevel: "000",
}
const newEconomicSuspensionsModalComponent = (
	state = newEconomicSuspensionsModalComponentInit,
	action
) => {
	switch (action.type) {
		case ActionTypes.NEW_ECONOMIC_SUSPENSION_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_NEW_ECONOMIC_SUSPENSION_MODAL_STATE:
			return { ...newEconomicSuspensionsModalComponentInit }
		case ActionTypes.LOGOUT:
			return { ...newEconomicSuspensionsModalComponentInit }
		default:
			return state
	}
}

const settingsDimonaInit = {
	new_headquarter: {
		address: {
			city: "",
			zip: "",
			box: "",
			streetNumber: "",
			street: "",
		},
		language: "",
		reference_number: "",
	},
}
const settingsDimona = (state = settingsDimonaInit, action) => {
	switch (action.type) {
		case ActionTypes.SETTINGS_DIMONA_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return settingsDimonaInit
		default:
			return state
	}
}

const impactedBookingsModalInit = {
	endDate: "",
	showRequired: [],
	fetched: false,
	readyForSubmit: false,
	otherActivity: {},
	allowedOptions: [],
	impactedBookingIDSelected: "",
	collapsedRows: [],
	keepImpactedOAs: "no",
	stfib: {},
	showImpactedBookingsModal: false,
	triggerAction: "",
	linkedOccurrences: [],
	forceUnlink: false,
	linkedPvdg: false,
	impactedOas: [],
	createReplacementForAll: false,
	createReplacementForNone: false,
}
const impactedBookingsModal = (state = impactedBookingsModalInit, action) => {
	switch (action.type) {
		case ActionTypes.SHOW_IMPACTED_BOOKINGS_MODAL:
			return {
				...state,
				...action.meta,
				showImpactedBookingsModal: true,
				triggerAction: action.triggerAction,
				linkedOccurrences: action.linkedOccurrences || [],
				linkedWorkshopOrders: action.linkedWorkshopOrders || [],
				linkedPvdg: action.linkedPvdg,
				impactedOas: action.impactedOas,
			}
		case ActionTypes.IMPACTED_BOOKINGS_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_OTHER_ACTIVITY_FAILURE:
			return { ...state, otherActivity: action.requestMeta.otherActivity }
		case ActionTypes.EDIT_IMPACTED_BOOKING:
			if (action.changes.action === "modify") {
				return { ...state, impactedBookingIDSelected: action.id }
			}
			return state
		case ActionTypes.SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_SUCCESS:
		case ActionTypes.FORGET_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.RESET_IMPACTED_BOOKINGS:
		case ActionTypes.LOGOUT:
			return impactedBookingsModalInit
		default:
			return state
	}
}

const bookingDetailsInitialState = {
	showChangeStatusModal: false,
	showNewBookingModal: false,
	showCancelModal: false,
	showDeleteBookingModal: false,
	showAddSVModal: false,
	showDeletePSVModal: false,
	showPSVReminderModal: false,
	showESVReminderModal: false,
	showDirectBookingModificationModal: false,
	customerContractIdStagedForSwitching: null,
	linkedCustomers: [],
}
const bookingDetailsComponent = (state = bookingDetailsInitialState, action) => {
	switch (action.type) {
		case ActionTypes.BOOKINGDETAILS_UICHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_PSV_SUCCESS:
			return { ...state, showAddSVModal: false }
		case ActionTypes.REMOVE_PSV_SUCCESS:
			return { ...state, showDeletePSVModal: false }
		case ActionTypes.FETCH_BOOKING_LINKED_CUSTOMERS_SUCCESS:
			return { ...state, linkedCustomers: action.data }
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_SUCCESS:
			return { ...state, showDirectBookingModificationModal: false }
		case ActionTypes.LOGOUT:
		case ActionTypes.BOOKINGDETAILS_RESETUI:
			return { ...bookingDetailsInitialState }
		default:
			return state
	}
}

const newDirectBookingModalInitialState = {
	showCreationWarning: false,
	showNewResidenceModal: false,
	showNewCustomerModal: false,
	creationWarning: "",
	prefillRange: {},
	prefillState: undefined,
	customSubmit: undefined,
}
const newDirectBookingModal = (state = newDirectBookingModalInitialState, action) => {
	switch (action.type) {
		case ActionTypes.DIRECTBOOKING_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.DIRECTBOOKING_CREATIONGWARNING:
			return { ...state, showCreationWarning: true, creationWarning: action.error }
		case ActionTypes.CREATECUSTOMERFROMNEWDIRECTBOOKING_SUCCESS:
			return { ...state, showNewCustomerModal: false }
		case ActionTypes.RESET_DIRECTBOOKING:
		case ActionTypes.SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS:
		case ActionTypes.LOGOUT:
			return newDirectBookingModalInitialState
		default:
			return state
	}
}

const directBookingModificationModalInitialState = {
	bookingID: "",
	sentToSodexo: false,
	startTime: moment(),
	endTime: moment(),
	dayPeriod: "",
	reach: "",
	voucherType: "",
	showRequired: { reach: false },
	disableSubmission: false,
	customerContractId: "",
	linkedCustomers: [],
	newCustomerContractId: "",
	chosenCustomer: null,
	otherCustomerContractId: "",
	showOtherCustomer: true,
	customerName: "",
	residenceIndex: null,
	addressId: "",
}
const directBookingModificationModal = (
	state = directBookingModificationModalInitialState,
	action
) => {
	switch (action.type) {
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_REQUEST:
			return { ...state, disableSubmission: true }
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_FAILURE:
			return { ...state, disableSubmission: false }
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCHCUSTOMER_SUCCESS:
			return {
				...state,
				chosenCustomer: action.data,
				linkedCustomers: action.data.attributes.linked_customer_contracts.data,
			}
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_SUCCESS:
		case ActionTypes.RESET_DIRECT_BOOKING_MODIFICATION_MODAL:
		case ActionTypes.LOGOUT:
			return directBookingModificationModalInitialState
		default:
			return state
	}
}

const workerProfileInitialState = {
	showResetPasswordModal: false,
	showDeleteAffiliateWorkerModal: false,
	showImpactedBookingsModalForAffiliateWorkerDeletion: false,
}
const workerProfileComponent = (state = workerProfileInitialState, action) => {
	switch (action.type) {
		case ActionTypes.WORKER_PROFILE_UICHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_WORKER_PASSWORD_SUCCESS:
			return { ...state, showResetPasswordModal: false }
		case ActionTypes.DELETE_AFFILIATE_WORKER_SUCCESS:
			return { ...state, showDeleteAffiliateWorkerModal: false }
		default:
			return state
	}
}

const sodexoExportsInitialState = { showReturnSlipModal: false }
const sodexoExportsComponent = (state = sodexoExportsInitialState, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_UICHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return sodexoExportsInitialState
		default:
			return state
	}
}

const settingsInit = {
	showNewAffiliateUserModal: false,
}
const settingsComponent = (state = settingsInit, action) => {
	switch (action.type) {
		case ActionTypes.SETTINGS_UICHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SETTINGS_RMVHOLIDAYWARNING:
			return { ...state, showRmvHolidayWarning: true }
		case ActionTypes.LOGOUT:
			return settingsInit
		default:
			return state
	}
}

const dashboardInitialState = {
	fetchingCustomers: false,
	fetchingQuality: false,
	fetchingSodexo: false,
	fetchingCompanyKPI: false,
}
const dashboardComponent = (state = dashboardInitialState, action) => {
	switch (action.type) {
		case ActionTypes.DASHBOARD_CUSTOMERS_REQUEST:
			return { ...state, fetchingCustomers: true }
		case ActionTypes.DASHBOARD_CUSTOMERS_SUCCESS:
			return { ...state, fetchingCustomers: false }
		case ActionTypes.DASHBOARD_SODEXO_REQUEST:
			return { ...state, fetchingSodexo: true }
		case ActionTypes.DASHBOARD_SODEXO_SUCCESS:
			return { ...state, fetchingSodexo: false }
		case ActionTypes.DASHBOARD_QUALITY_REQUEST:
			return { ...state, fetchingQuality: true }
		case ActionTypes.DASHBOARD_QUALITY_SUCCESS:
			return { ...state, fetchingQuality: false }
		case ActionTypes.DASHBOARD_COMPANY_KPI_REQUEST:
			return { ...state, fetchingCompanyKPI: true }
		case ActionTypes.DASHBOARD_COMPANY_KPI_SUCCESS:
			return { ...state, fetchingCompanyKPI: false }
		case ActionTypes.LOGOUT:
			return dashboardInitialState
		default:
			return state
	}
}

const workerWeekInit = {
	currentWeek: moment().startOf("isoweek"),
	showWeekPicker: false,
	showDirectBookingModal: false,
	directBookingInitialDate: "",
	showOtherActivityModal: false,
	otherActivityInit: {},
	showOtherActivityDeletionConfirmationModal: false,
}
const workerWeekComponent = (state = workerWeekInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKER_WEEK_UICHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return workerWeekInit
		default:
			return state
	}
}

const sodexoPaperDeliveryInit = {
	paperDeliveryGroups: [],
	PSVDelivery: null,
	data_csv: null,
	pagination: {},
}
const PSVDeliveryComponent = (state = sodexoPaperDeliveryInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SODEXO_PSV_DELIVERIES_SUCCESS:
			return {
				...state,
				pagination: action.metadata.pagination,
				paperDeliveryGroups: action.data,
			}
		case ActionTypes.FETCH_SODEXO_PSV_DELIVERY_SUCCESS:
			return { ...state, PSVDelivery: action.data }
		case ActionTypes.PSV_DELIVERY_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.UPDATE_SODEXO_PSV_DELIVERY:
			return { ...state, PSVDelivery: action.data }
		case ActionTypes.CANCEL_SODEXO_PSV_DELIVERY_SUCCESS:
			return { ...state, isCancelling: false, PSVDelivery: action.data }
		case ActionTypes.CLOSE_SODEXO_PSV_DELIVERY_SUCCESS:
			return { ...state, isClosing: false, PSVDelivery: action.data }
		case ActionTypes.REOPEN_SODEXO_PSV_DELIVERY_SUCCESS:
			return { ...state, isReopening: false, PSVDelivery: action.data }
		case ActionTypes.DELETE_PSV_PSV_DELIVERY_SUCCESS:
			return { ...state, isDeleting: false }
		case ActionTypes.LOGOUT:
			return sodexoPaperDeliveryInit
		case ActionTypes.RESET_PSV_DELIVERY:
			return sodexoPaperDeliveryInit
		default:
			return state
	}
}

const newPaperDeliveryInit = {
	newPSVDelivery: {},
	monthStartGroup: moment().startOf("month"),
	subjectType: null,
	monthStartFilter: moment().subtract(2, "weeks"),
	monthEndFilter: moment().subtract(1, "day"),
}
const newPaperDeliveryComponent = (state = newPaperDeliveryInit, action) => {
	switch (action.type) {
		case ActionTypes.NEW_PSV_DELIVERY_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_NEW_PSV_DELIVERY_SUCCESS:
			return { ...state, newPSVDelivery: action.data.table_psv, squads: action.data.squads }
		case ActionTypes.LOGOUT:
			return newPaperDeliveryInit
		default:
			return state
	}
}

const sodexoConflictsInit = {
	today: moment(),
	rangeStart: moment().subtract(7, "days"),
	rangeEnd: moment(),
	selectedSquadId: undefined,
	selectedPootsyServiceTab: "bookings",
	stagedPootsyBookingID: -1,
	stagedPootsyWorkshopOrderID: -1,
	stagedSodexoBookingID: -1,
	stagedLinks: [],
	showPootsyStagedBooking: false,
	showPootsyStagedWorkshopOrder: false,
	showSodexoStagedBooking: false,
	showConflictResolutionModal: false,
	isSmartFiltersActive: true,
	pBookingsPage: 1,
	pBookingsPagination: {},
	pWorkshopOrdersPage: 1,
	pWorkshopOrdersPagination: {},
	sBookingsPagination: {},
	sBookingsPage: 1,
	filterPootsyBookingsCustomerSodexoRef: null,
	filterPootsyBookingsWorkerSodexoRef: null,
	filterSodexoBookingsCustomerSodexoRef: null,
	filterSodexoBookingsWorkerSodexoRef: null,
	filterWorkerOrCustomer: true,
	fetchingBookings: false,
	fetchingSodexoBookings: false,
	specificWorker: { name: "", sodexo: "" },
	specificCustomer: { name: "", sodexo: "" },
}
const sodexoConflictsComponent = (state = sodexoConflictsInit, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_CONFLICTS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_SODEXO_BOOKINGS_SUCCESS:
		case ActionTypes.FETCH_SODEXO_BOOKINGS_FAILURE:
			return {
				...state,
				sBookingsPagination: action.meta.pagination,
				fetchingSodexoBookings: false,
			}
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
		case ActionTypes.FETCHBOOKINGS_FAILURE:
			return {
				...state,
				pBookingsPagination: action.meta.pagination,
				fetchingBookings: false,
			}
		case ActionTypes.FETCH_WORKSHOP_ORDERS_SUCCESS:
			return { ...state, pWorkshopOrdersPagination: action.meta.pagination }
		case ActionTypes.FETCHBOOKINGS_REQUEST:
			return { ...state, fetchingBookings: true, pBookingsPagination: {} }
		case ActionTypes.FETCH_SODEXO_BOOKINGS_REQUEST:
			return { ...state, fetchingSodexoBookings: true, sBookingsPagination: {} }
		case ActionTypes.LOGOUT:
			return sodexoConflictsInit
		default:
			return state
	}
}

const sodexoPSVInit = {
	today: moment(),
	rangeStart: moment().subtract(1, "month"),
	rangeEnd: moment(),
	psvCandidate: undefined,
	bookingCandidate: undefined,
	workshopOrderCandidate: undefined,
	stagedLinks: [],
	showPootsyStagedBooking: false,
	showSodexoStagedPsv: false,
	showPootsyStagedWorkshopOrder: false,
	isSmartFiltersActive: true,
	selectedPootsyServiceTab: "bookings",
	pBookingsPage: 1,
	pBookingsPagination: {},
	sPSVsPage: 1,
	sPSVsPagination: {},
	pWorkshopOrdersPage: 1,
	pWorkshopOrdersPagination: {},
	showFakeLinkModal: false,
	requestingFakePSVLinking: false,
	filterPootsyBookingsCustomerSodexoRef: null,
	filterPootsyBookingsWorkerSodexoRef: null,
	filterSodexoPSVsCustomerSodexoRef: null,
	filterSodexoPSVsWorkerSodexoRef: null,
	specificWorker: { name: "", sodexo: "" },
	fetchingBookings: false,
	fetchingPSVs: false,
	pTotalPSV: -1,
}
const sodexoPsvConflictsComponent = (state = sodexoPSVInit, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_PAPER_SERVICE_VOUCHERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_SODEXO_LINKS_BATCH_REQUEST:
			return { ...state, requestingFakePSVLinking: true }
		case ActionTypes.SUBMIT_SODEXO_LINKS_BATCH_SUCCESS:
			return { ...state, requestingFakePSVLinking: false }
		case ActionTypes.FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_SUCCESS:
		case ActionTypes.FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_FAILURE:
			return {
				...state,
				sPSVsPagination: action.meta.pagination,
				fetchingPSVs: false,
			}
		case ActionTypes.FETCH_WORKSHOP_ORDERS_SUCCESS:
		case ActionTypes.FETCH_WORKSHOP_ORDERS_FAILURE:
			return {
				...state,
				pWorkshopOrdersPagination: action.meta.pagination,
				pTotalPSV: action.meta.total_psv_affected,
				fetchingBookings: false,
			}
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
		case ActionTypes.FETCHBOOKINGS_FAILURE:
			return {
				...state,
				pBookingsPagination: action.meta.pagination,
				pTotalPSV: action.meta.total_psv_affected,
				fetchingBookings: false,
			}
		case ActionTypes.FETCHBOOKINGS_REQUEST:
		case ActionTypes.FETCH_WORKSHOP_ORDERS_REQUEST:
			return {
				...state,
				fetchingBookings: true,
				pBookingsPagination: {},
				pTotalPSV: sodexoPSVInit.pTotalPSV,
			}
		case ActionTypes.FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_REQUEST:
			return { ...state, fetchingPSVs: true, sPSVsPagination: {} }
		case ActionTypes.LOGOUT:
			return sodexoConflictsInit
		default:
			return state
	}
}

const sodexoInsufficientInit = {
	svData: { electronic: {}, paper: {} },
	showEmailModal: false,
	voucherTypeModalReminder: "",
	sendingRemindersFromModal: false,
}
const sodexoInsufficientComponent = (state = sodexoInsufficientInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return {
				...state,
				svData: action.meta.service_voucher_data || sodexoInsufficientInit.svData,
			}
		case ActionTypes.SODEXO_INSUFFICIENT_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SEND_PAYMENT_REMINDER_SUCCESS:
			return { ...state, showEmailModal: false, sendingRemindersFromModal: false }
		case ActionTypes.LOGOUT:
			return sodexoInsufficientInit
		default:
			return state
	}
}

const sodexoValidateInit = {
	svData: { Created_count: 0, ModifiedByProvider_count: 0, CorrectedByProvider_count: 0 },
	validationsStatus: "no_validations_in_progress",
}
const sodexoValidateComponent = (state = sodexoValidateInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return {
				...state,
				svData: action.meta.service_voucher_data || sodexoValidateInit.svData,
			}
		case ActionTypes.BATCH_VALIDATE_SODEXO_BOOKINGS_REQUEST:
			return { ...state, validationsStatus: "validations_in_progress" }
		case ActionTypes.BATCH_VALIDATE_SODEXO_BOOKINGS_FAILURE:
			return { ...state, validationsStatus: "no_validations_in_progress" }
		case ActionTypes.CHECK_VALIDATIONS_STATUS_SUCCESS:
			return { ...state, validationsStatus: action.validations_status }
		case ActionTypes.SODEXO_VALIDATE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return sodexoValidateInit
		default:
			return state
	}
}

const sodexoOpposedInit = {}
const sodexoOpposedComponent = (state = sodexoOpposedInit, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_OPPOSED_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return sodexoOpposedInit
		default:
			return state
	}
}

const bookingFiltersInit = {
	search: "",
	page: 1,
	pagination: {},
	fetched: false,
	bookingsNeedRefetch: false,
}
const bookingFiltersComponent = (state = bookingFiltersInit, action) => {
	switch (action.type) {
		case ActionTypes.BOOKINGFILTERS_SUCCESS:
			return { ...state, fetched: true }
		case ActionTypes.BOOKING_FILTERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_QUERY:
			return bookingFiltersInit
		default:
			return state
	}
}

const pdfEditorInit = {}
const pdfEditorComponent = (state = pdfEditorInit, action) => {
	switch (action.type) {
		case ActionTypes.PDF_EDITOR_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return pdfEditorInit
		default:
			return state
	}
}

const settingsTemplatesInit = {
	templateName: "",
	templateTarget: "",
	variables: { customer: [], worker: [], general: [], secSoc: [] },
}
const settingsTemplatesComponent = (state = settingsTemplatesInit, action) => {
	switch (action.type) {
		case ActionTypes.SETTINGS_TEMPLATE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_DOCUMENT_TEMPLATES_SUCCESS:
			return {
				...state,
				variables: {
					customer: action.meta.customer_variables,
					worker: action.meta.worker_variables,
					general: action.meta.general_variables,
					secSoc: action.meta.ss_fields_available,
				},
			}
		case ActionTypes.LOGOUT:
			return settingsTemplatesInit
		default:
			return state
	}
}

const workerTemplatesInit = {
	selectedTemplate: "",
	selectedLang: "",
	loadingDocumentVariables: false,
	availableTemplates: [],
	renderableTemplate: {},
}
const workerTemplatesComponent = (state = workerTemplatesInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKER_TEMPLATES_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_SUCCESS:
			return {
				...state,
				availableTemplates: action.data
					.filter(entry => entry.attributes.target === "worker")
					.map(normalizeDocumentTemplate),
			}
		case ActionTypes.LOGOUT:
			return workerTemplatesInit
		default:
			return state
	}
}

const workshopInit = {
	workshopId: "",
}
const workshopComponent = (state = workshopInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return workshopInit
		default:
			return state
	}
}

const missingLaundryOrderModalInit = {
	svType: "",
	workshopId: 0,
	chosenOrderItems: [],
	workerDisplayName: "",
	workerContractId: undefined,
	executionDate: undefined,
	executedDurationMinutes: 0,
	chosenCustomer: {},
	currentQuote: { details: [] },
	workshopHours: [],
	fetchingQuote: false,
	billableHoursOverride: "",
}
const missingLaundryOrderModalComponent = (state = missingLaundryOrderModalInit, action) => {
	switch (action.type) {
		case ActionTypes.MISSING_LAUNDRY_ORDER_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_MISSING_LAUNDRY_ORDER_MODAL:
		case ActionTypes.LOGOUT:
			return workshopHourModalInit
		default:
			return state
	}
}

const workshopHourModalInit = {
	showRequired: {},
	startDate: moment.invalid(),
	start: moment().set({ year: 2000, month: 0, date: 1, minutes: 0, second: 0 }),
	end: moment()
		.set({ year: 2000, month: 0, date: 1, minutes: 0, second: 0 })
		.add(3, "hours"),
	dayPeriod: "",
	activityCategory: "",
	activityCode: "",
	chosenWorker: { name: "" },
	warning: "",
	workshopId: "",
	affiliateWorkerId: undefined,
}
const workshopHourModalComponent = (state = workshopHourModalInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_HOUR_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_WORKSHOP_HOUR_MODAL:
		case ActionTypes.LOGOUT:
			return workshopHourModalInit
		default:
			return state
	}
}

const workerWorkshopOrdersInit = {
	totalBillableVouchersForCurrentWorkshopOrders: 0,
}
const workerWorkshopOrdersComponent = (state = workerWorkshopOrdersInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKER_WORKSHOP_ORDERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_WORKSHOP_ORDERS_SUCCESS:
			return {
				...state,
				totalBillableVouchersForCurrentWorkshopOrders: action.meta.total_billable_vouchers,
			}
		case ActionTypes.LOGOUT:
			return workerWorkshopOrdersInit
		default:
			return state
	}
}

const customerWorkshopOrdersInit = {}
const customerWorkshopOrdersComponent = (state = customerWorkshopOrdersInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_WORKSHOP_ORDERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return customerWorkshopOrdersInit
		default:
			return state
	}
}

const documentsComponentInit = {
	showNewDimonaModal: false,
}
const documentsComponent = (state = documentsComponentInit, action) => {
	switch (action.type) {
		case ActionTypes.DOCUMENTS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return documentsComponentInit
		default:
			return state
	}
}

const workshopOrdersFiltersInit = {
	search: "",
	page: 1,
	pagination: {},
	fetched: false,
	workshopOrdersNeedRefetch: false,
}
const workshopOrdersFiltersComponent = (state = workshopOrdersFiltersInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDERS_FILTERS_SUCCESS:
			return { ...state, fetched: true }
		case ActionTypes.WORKSHOP_ORDERS_FILTERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_WORKSHOP_ORDERS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
			return workshopOrdersFiltersInit
		default:
			return state
	}
}

const availableOrderItemCreatorInit = {
	shortName: "",
	longName: "",
	price: "",
	category: "",
}
const availableOrderItemCreatorComponent = (state = availableOrderItemCreatorInit, action) => {
	switch (action.type) {
		case ActionTypes.AVAILABLE_ORDER_ITEM_CREATOR_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_NEW_AVAILABLE_ORDER_ITEM_SUCCESS:
			return availableOrderItemCreatorInit
		default:
			return state
	}
}

const newWorkshopOrderInit = {
	currentStep: 1,
	dropOffDate: moment(),
	svType: "",
	workshopId: 0,
	priorityOrder: false,
	pickUpDate: undefined,
	chosenOrderItems: [],
	chosenWorker: { name: "" },
	chosenCustomer: {},
	readyForStep: 1,
	currentQuote: { details: [] },
	workshopHours: [],
	earliestPossiblePickUpDate: undefined,
	showWorkshopHourModal: false,
	showEditWorkshopHourModal: false,
	showNewCustomerModal: false,
	fetchingQuote: false,
	billableHoursOverride: undefined,
}

const newWorkshopOrderComponent = (state = newWorkshopOrderInit, action) => {
	switch (action.type) {
		case ActionTypes.NEW_WORKSHOP_ORDER_STATE_CHANGE:
			let newQuote = state.currentQuote
			if (action.changes.chosenOrderItems) {
				// the quote doesn't update itself quickly because it's waiting for the call,
				// here lines are removed immediately after clicking on "remove"
				let newChosenItemsIds = action.changes.chosenOrderItems.map(entry => entry.avItemID)
				newQuote = {
					...newQuote,
					details: newQuote.details.map(entry => ({
						...entry,
						items: entry.items.filter(
							item =>
								newChosenItemsIds.includes(item.avItemID) ||
								item.name === "fixed_fee_item"
						),
					})),
				}
			}
			return { ...state, ...action.changes, currentQuote: newQuote }
		case ActionTypes.FETCH_ORDER_QUOTE_REQUEST:
			return {
				...state,
				currentQuote: { ...newWorkshopOrderInit.currentQuote },
				fetchingQuote: true,
			}
		case ActionTypes.FETCH_ORDER_QUOTE_SUCCESS:
			return {
				...state,
				currentQuote: normalizeWorkshopOrderQuote(action),
				fetchingQuote: false,
			}
		case ActionTypes.CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER_SUCCESS:
			return {
				...state,
				showNewCustomerModal: false,
				chosenCustomer: {
					id: Number(action.data.id),
					name: action.data.attributes.display_name,
					sodexo: action.data.attributes.sodexo_reference,
				},
			}
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_NEW_WORKSHOP_ORDER:
			return newWorkshopOrderInit
		default:
			return state
	}
}

const workshopOrdersInit = {}
const workshopOrdersComponent = (state = workshopOrdersInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return workshopOrdersInit
		default:
			return state
	}
}

const workshopCommandCenterInit = {
	dayReport: [],
}
const workshopCommandCenterComponent = (state = workshopCommandCenterInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_COMMAND_CENTER_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LAUNDRY_DAY_REPORT_SUCCESS:
			return { ...state, dayReport: action.data }
		case ActionTypes.LOGOUT:
			return workshopCommandCenterInit
		default:
			return state
	}
}

const workshopOrdersIssuesInit = {
	showDeleteWarningModal: false,
	idStagedForDeletion: 0,
	earliestPossiblePickUpDates: [],
	stagedForReScheduling: [],
	showReschedulingConfirmationModal: false,
	disableReSchedulingButton: false,
	dryRunSuccess: false,
	errorMessage: "",
	errorObject: {},
}
const workshopOrdersIssuesComponent = (state = workshopOrdersIssuesInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDERS_ISSUES_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_DELETE_WORKSHOP_ORDER_SUCCESS:
			return { ...state, showDeleteWarningModal: false }
		case ActionTypes.LOGOUT:
			return workshopOrdersIssuesInit
		default:
			return state
	}
}

const workshopDayOrderInit = {}
const workshopDayOrderComponent = (state = workshopDayOrderInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_DAY_ORDER_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return workshopDayOrderInit
		default:
			return state
	}
}

const workshopOrdersToRetrieveInit = {}
const workshopOrdersToRetrieveComponent = (state = workshopOrdersToRetrieveInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDERS_TO_RETRIEVE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return workshopOrdersToRetrieveInit
		default:
			return state
	}
}

const customerTemplatesInit = {
	selectedTemplate: "",
	selectedLang: "",
	loadingDocumentVariables: false,
	availableTemplates: [],
	renderableTemplate: {},
}
const customerTemplatesComponent = (state = customerTemplatesInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_TEMPLATES_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_SUCCESS:
			return {
				...state,
				availableTemplates: action.data
					.filter(entry => entry.attributes.target === "customer")
					.map(normalizeDocumentTemplate),
			}
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_CUSTOMER_TEMPLATES_STATE:
			return customerTemplatesInit
		default:
			return state
	}
}

const pDFWorkerScheduleInit = {}
const pdfWorkerScheduleComponent = (state = pDFWorkerScheduleInit, action) => {
	switch (action.type) {
		case ActionTypes.PDF_WORKER_SCHEDULE_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return pDFWorkerScheduleInit
		default:
			return state
	}
}

const notificationCenterInit = {
	show: false,
}
const notificationCenterComponent = (state = notificationCenterInit, action) => {
	switch (action.type) {
		case ActionTypes.NOTIFICATIONS_STATE_CHANGE:
			return { ...state, ...action.changes }
		default:
			return state
	}
}

const modalSearchPsvInit = {
	psv: null,
}
const searchPsvComponent = (state = modalSearchPsvInit, action) => {
	switch (action.type) {
		case ActionTypes.MODAL_SEARCH_PSV_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_PSV_SUCCESS:
			return { ...state, psv: action.data }
		case ActionTypes.LOGOUT:
			return modalSearchPsvInit
		default:
			return state
	}
}

const workshopDetailsComponentInitialState = {
	showStartOrderModal: false,
	showRestartOrderModal: false,
	showFinishOrderModal: false,
	showCancelOrderModal: false,
	showDeleteOrderModal: false,
	showUpdateOrderModal: false,
	showAddPSVModal: false,
	showDeletePsvConfirmationModal: false,
	psvStagedForDeletion: undefined,
	pendingUpdatedWorkshopOrder: {},
}
const workshopOrderDetailsComponent = (state = workshopDetailsComponentInitialState, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDER_DETAILS_STATE_CHANGE:
			return { ...state, ...action.changes }
		default:
			return state
	}
}

const updateWorkshopOrderModalInit = {
	workshopId: undefined,
	earliestPossiblePickUpDate: undefined,
	scheduledPickupDate: undefined,
	items: [],
	currentQuote: { details: [] },
	showOrderItems: false,
	fetchingQuote: false,
	billableHoursOverride: undefined,
	linkedCustomers: [],
	newCustomerContractId: undefined,
}
const updateWorkshopOrderModalComponent = (state = updateWorkshopOrderModalInit, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_WORKSHOP_ORDER_MODAL_STATE_CHANGE:
			let newQuote = action.changes.currentQuote || state.currentQuote
			if (action.changes.items) {
				// the quote doesn't update itself quickly because it's waiting for the call,
				// here lines are removed immediately after clicking on "remove"
				let newChosenItemsIds = action.changes.items.map(entry => entry.avItemID)
				newQuote = {
					...newQuote,
					details: newQuote.details.map(entry => ({
						...entry,
						items: entry.items.filter(
							item =>
								newChosenItemsIds.includes(item.avItemID) ||
								item.name === "fixed_fee_item"
						),
					})),
				}
			}
			return { ...state, ...action.changes, currentQuote: newQuote }
		case ActionTypes.FETCH_ORDER_QUOTE_REQUEST:
			return {
				...state,
				currentQuote: { ...newWorkshopOrderInit.currentQuote },
				fetchingQuote: true,
			}
		case ActionTypes.FETCH_ORDER_QUOTE_SUCCESS:
			return {
				...state,
				currentQuote: normalizeWorkshopOrderQuote(action),
				fetchingQuote: false,
			}
		case ActionTypes.FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_SUCCESS:
			return { ...state, linkedCustomers: action.data }
		case ActionTypes.RESET_UPDATE_WORKSHOP_ORDER_MODAL_STATE:
		case ActionTypes.LOGOUT:
			return updateWorkshopOrderModalInit
		default:
			return state
	}
}

const dimonasFiltersComponentInit = {
	search: "",
	page: 1,
	pagination: {},
	fetched: false,
	dimonasNeedRefetch: false,
}
const dimonasFiltersComponent = (state = dimonasFiltersComponentInit, action) => {
	switch (action.type) {
		case ActionTypes.DIMONAS_FILTERS_SUCCESS:
			return { ...state, fetched: true }
		case ActionTypes.DIMONAS_FILTERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_DIMONAS_SUCCESS:
			return { ...state, pagination: action.meta.pagination }
		case ActionTypes.LOGOUT:
			return dimonasFiltersComponentInit
		default:
			return state
	}
}

const finishWorkshopOrderModalInit = {
	showRequired: {},
	items: [],
	currentQuote: { details: [] },
	showOrderItems: false,
	notifyCustomerForFinishedOrder: true,
	fetchingQuote: false,
}
const finishWorkshopOrderModalComponent = (state = finishWorkshopOrderModalInit, action) => {
	switch (action.type) {
		case ActionTypes.FINISH_WORKSHOP_ORDER_MODAL_STATE_CHANGE:
			let newQuote = action.changes.currentQuote || state.currentQuote
			if (action.changes.items) {
				// the quote doesn't update itself quickly because it's waiting for the call,
				// here lines are removed immediately after clicking on "remove"
				let newChosenItemsIds = action.changes.items.map(entry => entry.avItemID)
				newQuote = {
					...newQuote,
					details: newQuote.details.map(entry => ({
						...entry,
						items: entry.items.filter(
							item =>
								newChosenItemsIds.includes(item.avItemID) ||
								item.name === "fixed_fee_item"
						),
					})),
				}
			}
			return { ...state, ...action.changes, currentQuote: newQuote }
		case ActionTypes.FETCH_ORDER_QUOTE_REQUEST:
			return {
				...state,
				currentQuote: { ...newWorkshopOrderInit.currentQuote },
				fetchingQuote: true,
			}
		case ActionTypes.FETCH_ORDER_QUOTE_SUCCESS:
			return {
				...state,
				currentQuote: normalizeWorkshopOrderQuote(action),
				fetchingQuote: false,
			}
		case ActionTypes.RESET_FINISH_WORKSHOP_ORDER_MODAL_STATE:
		case ActionTypes.LOGOUT:
			return finishWorkshopOrderModalInit
		default:
			return state
	}
}

const pSVScannerInit = {
	scannedCodes: [],
	isSendingPSV: false,
	showPSVLinkingReportModal: false,
	PSVLinkingReport: {},
	importFile: "",
}
const PSVScannerComponent = (state = pSVScannerInit, action) => {
	switch (action.type) {
		case ActionTypes.PSV_SCANNER_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SEND_SODEXO_PSV_REQUEST:
			return { ...state, isSendingPSV: true, PSVLinkingReport: {} }
		case ActionTypes.SEND_SODEXO_PSV_SUCCESS:
			return { ...state, isSendingPSV: false, PSVLinkingReport: action.data.report }
		case ActionTypes.SEND_SODEXO_PSV_FAILURE:
			return { ...state, isSendingPSV: false }
		case ActionTypes.RESET_PSV_SCANNER_STATE:
		case ActionTypes.LOGOUT:
			return pSVScannerInit
		default:
			return state
	}
}

const workshopSettingsInit = {
	workshopName: "",
}
export const workshopSettingsComponent = (state = workshopSettingsInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_SETTINGS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_NEW_WORKSHOP_SUCCESS:
			return { ...state, workshopName: "" }
		case ActionTypes.LOGOUT:
			return pSVScannerInit
		default:
			return state
	}
}

const salariesIndexingFormInit = {
	indexingAmount: 0.0,
	dateIndexation: "",
}
const salariesIndexingFormComponent = (state = salariesIndexingFormInit, action) => {
	switch (action.type) {
		case ActionTypes.SALARIES_INDEXING_FORM_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.INDEXING_WORKERS_SALARY_SUCCESS:
			let workers = action.data.map(worker => {
				worker.affected = true
				return worker
			})
			return { ...state, workers: workers }
		case ActionTypes.UPDATE_WORKER_SALARIES_SUCCESS:
			return {
				...state,
				failed_indexation: action.fails,
				success_indexation: action.successes,
				total_update: action.total,
				confirmationModal: false,
			}
		case ActionTypes.FETCH_HISTORY_INDEXATIONS_SUCCESS:
			return { ...state, history_indexations: action.data }
		case ActionTypes.LOGOUT:
			return salariesIndexingFormInit
		default:
			return state
	}
}

const updatePSVdeliveryGroupInit = {
	newPSVDelivery: {},
	squads: [],
	PSVDelivery: {},
}
const updatePSVdeliveryGroupComponent = (state = updatePSVdeliveryGroupInit, action) => {
	switch (action.type) {
		case ActionTypes.ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_SODEXO_PSV_DELIVERY_SUCCESS:
			let monthStartFilter = moment(
				action.data.attributes.month_start_filter || action.data.attributes.month_start
			)
			let monthEndFilter = moment(action.data.attributes.month_end_filter)
			let monthStartGroup = moment(action.data.attributes.month_start)
			return {
				...state,
				PSVDelivery: action.data,
				monthStartFilter,
				monthStartGroup,
				monthEndFilter: monthEndFilter.isValid()
					? monthEndFilter
					: moment(monthStartFilter).endOf("month"),
				squad: action.data.attributes.squad_id,
				subjectType: action.data.attributes.subject_type,
			}
		case ActionTypes.FETCH_NEW_PSV_DELIVERY_SUCCESS:
			return { ...state, newPSVDelivery: action.data.table_psv, squads: action.data.squads }
		case ActionTypes.ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_SUCCESS:
			return { ...state, PSVDelivery: action.data }
		case ActionTypes.LOGOUT:
			return updatePSVdeliveryGroupInit
		default:
			return state
	}
}

const unemploymentDeclarationsInit = {
	chosenWorker: { name: "" },
	searchedAffiliateWorker: { name: "" },
	showOnssErrorModal: false,
	onssErrors: "",
	squadsFilter: [],
	showCancelUDwarningModal: false,
	udStagedForCancelling: 0,
	page: 1,
	pagination: {},
}
const unemploymentDeclarationsComponent = (state = unemploymentDeclarationsInit, action) => {
	switch (action.type) {
		case ActionTypes.UNEMPLOYMENT_DECLARATIONS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_UNEMPLOYMENT_DECLARATIONS_SUCCESS:
			return { ...state, declarations: action.data, pagination: action.meta.pagination }
		case ActionTypes.CANCEL_UNEMPLOYMENT_DECLARATION_SUCCESS:
			return { ...state, showCancelUDwarningModal: false }
		case ActionTypes.LOGOUT:
			return unemploymentDeclarationsInit
		default:
			return state
	}
}
const tasksInit = {
	show: false,
	taskTitle: "",
	taskScheduledDate: "",
	taskCategory: "",
	taskAssignees: [],
	currentTaskId: -1,
	tasksWithSpinner: [],
	taskAuthor: "",
	taskCreatedAt: "",
	currentAssigneesFilter: [],
	currentCategoryFilters: [],
	todoFilterActive: false,
	showDeletePopUp: false,
	scheduledDateFilter: "",
}
const tasksComponent = (state = tasksInit, action) => {
	switch (action.type) {
		case ActionTypes.TASKS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.AUTO_SAVE_TASK_REQUEST:
		case ActionTypes.TASK_DONE_REQUEST:
			return {
				...state,
				tasksWithSpinner: [...state.tasksWithSpinner, action.requestMeta.taskId],
			}
		case ActionTypes.AUTO_SAVE_TASK_SUCCESS:
		case ActionTypes.AUTO_SAVE_TASK_FAILURE:
		case ActionTypes.TASK_DONE_SUCCESS:
		case ActionTypes.TASK_DONE_FAILURE:
			return {
				...state,
				tasksWithSpinner: state.tasksWithSpinner.filter(
					entry => entry !== action.requestMeta.taskId
				),
			}
		case ActionTypes.SUBMIT_DELETE_CURRENT_TASK_REQUEST:
			return {
				...state,
				tasksWithSpinner: [...state.tasksWithSpinner, action.requestMeta.deletedId],
			}
		case ActionTypes.SUBMIT_DELETE_CURRENT_TASK_SUCCESS:
			return {
				...state,
				currentTaskId: -1,
				taskTitle: "",
				taskScheduledDate: "",
				taskCategory: "",
				taskAssignees: [],
				taskAuthor: "",
				taskCreatedAt: "",
				showDeletePopUp: false,
				tasksWithSpinner: state.tasksWithSpinner.filter(
					entry => entry !== action.requestMeta.taskId
				),
			}
		case ActionTypes.RESET_TASK_COMPONENT:
			return {
				...tasksInit,
				// keep filters
				currentAssigneesFilter: state.currentAssigneesFilter,
				currentCategoryFilters: state.currentCategoryFilters,
				todoFilterActive: state.todoFilterActive,
				scheduledDateFilter: state.scheduledDateFilter,
			}
		case ActionTypes.LOGOUT:
			return tasksInit
		default:
			return state
	}
}

const draftTaskEditorInit = {
	showObjectSearch: false,
	currentObjectSearch: "",
	currentObjectSearchType: "",
	objectSearchResults: [],
	objectRequestEnabled: false,
}
const draftTaskEditorComponent = (state = draftTaskEditorInit, action) => {
	switch (action.type) {
		case ActionTypes.DRAFT_TASK_EDITOR_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SEARCH_TASK_OBJECT_SUCCESS:
			let normalize = normalizeBookingLine
			if (state.currentObjectSearchType !== "booking") {
				normalize = a => a
			}
			return { ...state, objectSearchResults: action.data.map(normalize) }
		case ActionTypes.LOGOUT:
			return draftTaskEditorInit
		default:
			return state
	}
}

const unemploymentBooksInit = {
	chosenWorker: { name: "" },
	searchedAffiliateWorker: { name: "" },
	showOnssErrorModal: false,
	onssErrors: "",
	squadsFilter: [],
}
const unemploymentBooksComponent = (state = unemploymentBooksInit, action) => {
	switch (action.type) {
		case ActionTypes.UNEMPLOYMENT_BOOKS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.FETCH_UNEMPLOYMENT_BOOKS_SUCCESS:
			return { ...state, unemployment_books: action.data }
		case ActionTypes.LOGOUT:
			return unemploymentBooksInit
		default:
			return state
	}
}

const dimonasInit = {
	chosenWorker: { name: "" },
	searchedAffiliateWorker: { name: "" },
	showOnssErrorModal: false,
	showNewDimonaModal: false,
	onssErrors: "",
}
const dimonasComponent = (state = dimonasInit, action) => {
	switch (action.type) {
		case ActionTypes.DIMONAS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return dimonasInit
		default:
			return state
	}
}

const newDimonaInit = {
	chosenWorker: { name: "" },
	commission: "322.01",
	dimonaWorkerType: "",
	dateIn: "",
	dateOut: "",
	contractOut: false,
	contractIn: false,
	afterContractCreation: undefined,
	onssRefNumber: "",
	showRequired: {},
	potentialCorrespondingDimonaIn: [],
}
const newDimonaModalComponent = (state = newDimonaInit, action) => {
	switch (action.type) {
		case ActionTypes.NEW_DIMONA_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SELECT_AFFILIATE:
			let dimonaWorkerType
			if (
				action.chosenAffiliate.dimonaSettings &&
				action.chosenAffiliate.dimonaSettings.dimonaWorkerType
			) {
				dimonaWorkerType = action.chosenAffiliate.dimonaSettings.dimonaWorkerType
			}
			return { ...state, dimonaWorkerType }
		case ActionTypes.FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_SUCCESS:
			return {
				...state,
				potentialCorrespondingDimonaIn: action.data.map(normalizeDimonaLine),
			}
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_NEW_DIMONA:
			return newDimonaInit
		default:
			return state
	}
}

const customerAbsenceModalInit = {
	absenceStart: moment()
		.add({ week: 1 })
		.set({ hours: 0, minutes: 0, seconds: 0 }),
	absenceEnd: moment()
		.add({ week: 1 })
		.set({ hours: 23, minutes: 55, seconds: 0 }),
	absenceNote: "",
	addToAllLinkedCustomers: false,
	showImpactedBookingsModal: false,
}
const customerAbsenceModalComponent = (state = customerAbsenceModalInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_ABSENCE_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_CUSTOMER_ABSENCE_MODAL_STATE:
		case ActionTypes.LOGOUT:
			return customerAbsenceModalInit
		default:
			return state
	}
}

const customerDetailsInit = {
	showDataExportExplanationModal: false,
	showCreateAbsenceModal: false,
}
const customerDetailsComponent = (state = customerDetailsInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_DETAILS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return customerDetailsInit
		default:
			return state
	}
}

const sodexoConflictModalInit = {
	startTime: moment(),
	endTime: moment(),
	worker: { name: "" },
	customer: { name: "" },
}
const sodexoConflictModalComponent = (state = sodexoConflictModalInit, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_CONFLICT_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return sodexoConflictModalInit
		default:
			return state
	}
}

const newTimesheetEventModalInit = {
	newEventModalDay: "",
	newEventSecSocTerm: "",
	newEventStart: moment()
		.hour(8)
		.minutes(0),
	newEventEnd: moment()
		.hour(12)
		.minutes(0),
	newEventDistance: "",
}
const newTimesheetEventModalComponent = (state = newTimesheetEventModalInit, action) => {
	switch (action.type) {
		case ActionTypes.NEW_TIMESHEET_EVENT_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return newTimesheetEventModalInit
		default:
			return state
	}
}

const timesheetExportModalInit = {
	selectedWeek: "all",
	customExportDate: undefined,
	workersToExport: [],
	sendExportToSecSocAutomatically: true,
	isSplittedPerSquad: false,
	showSplitConfirmationModal: false,
	showFilterConfirmationModal: false,
	socSecLogin: undefined,
	socSecPassword: undefined,
	filterBySquad: false,
	squadsSelected: {},
	timesheetsBySquad: {},
}
const timesheetExportModalComponent = (state = timesheetExportModalInit, action) => {
	switch (action.type) {
		case ActionTypes.TIMESHEET_EXPORT_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return timesheetExportModalInit
		default:
			return state
	}
}

const customersPaymentRemindersInit = {
	currentCustomerFilter: "",
}
const customersPaymentRemindersComponent = (state = customersPaymentRemindersInit, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMERS_PAYMENT_REMINDERS_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return customersPaymentRemindersInit
		default:
			return state
	}
}

const PaymentReminderPageInit = {}
const paymentReminderPageComponent = (state = PaymentReminderPageInit, action) => {
	switch (action.type) {
		case ActionTypes.PAYMENT_REMINDER_MODAL_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return PaymentReminderPageInit
		default:
			return state
	}
}

const workerMultiSelectInit = {
	stagedWorkers: [],
	filters: {},
	lastFilteringUsed: {},
	zipFilters: [],
	squadsFilters: [],
	activeAtDateFilter: moment(),
}
const workerMultiSelectComponent = (state = workerMultiSelectInit, action) => {
	switch (action.type) {
		case ActionTypes.WORKERSFILTERS_SUCCESS:
			let filterResponse = { ...action }
			delete filterResponse.type
			return {
				...state,
				filters: {
					zip: {
						label: "postal_codes",
						list: filterResponse.areas.list,
						queryName: "filter_zip_codes",
					},
					squads: {
						label: "search_squads",
						list: filterResponse.squads.list,
						queryName: "filter_squads",
					},
				},
			}
		case ActionTypes.WORKER_MULTI_SELECT_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.WORKER_MULTI_SELECT_RESET_STATE:
			return workerMultiSelectInit
		case ActionTypes.LOGOUT:
			return workerMultiSelectInit
		default:
			return state
	}
}

const bookingWishesInit = {
	rangeStart: undefined,
	rangeEnd: undefined,
	fetching: false,
	customersFilters: [],
	workersFilters: [],
	squadsFilters: [],
	squadsFiltersForSearch: [],
	allBadges: ALL_BADGES.map(e => ({ label: e, value: e })),
	badgesFilters: "",
	statusFilter: "PENDING",
	currentHighlights: {},
	scheduleHighlights: {},
	showAgenda: false,
	page: 1,
	pagination: {},
	tssResults: [],
	showRequired: { squadsFilters: true },
	bookingWishStagedForDismissal: undefined,
	minReplacementDuration: "",
}
const bookingWishesComponent = (state = bookingWishesInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_BOOKING_WISHES_REQUEST:
			return { ...state, fetching: true }
		case ActionTypes.FETCH_BOOKING_WISHES_FAILURE:
			return { ...state, fetching: false }
		case ActionTypes.FETCH_BOOKING_WISHES_SUCCESS:
			return { ...state, fetching: false, pagination: { ...action.meta.pagination } }
		case ActionTypes.BOOKING_WISHES_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return bookingWishesInit
		default:
			return state
	}
}

const searchSodexoWorkInit = {
	showModal: false,
	sodexoWork: {},
}
const searchSodexoWorkComponent = (state = searchSodexoWorkInit, action) => {
	switch (action.type) {
		case ActionTypes.SEARCH_SODEXO_WORK_STATE_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.SUBMIT_UNLINK_SODEXO_WORK_SUCCESS:
		case ActionTypes.FETCH_SODEXO_WORK_SUCCESS:
			return { ...state, sodexoWork: normalizeSodexoWork(action.data) }
		case ActionTypes.LOGOUT:
			return searchSodexoWorkInit
		default:
			return state
	}
}

export const redComponents = combineReducers({
	workerPage,
	workerAbsences,
	trainingsPage,
	singularTrainingsPage,
	groupTrainingsPage,
	timesheetsComponent,
	agenda,
	deleteBookingModal,
	updatePSVdeliveryGroupComponent,
	deleteOtherActivityModal,
	cancelBookingModal,
	bookingsComponent,
	newBookingComponent,
	workersComponent,
	communicationComponent,
	workerComponent,
	customersComponent,
	customerComponent,
	workerContractComponent,
	impactedBookingsModal,
	unemploymentDeclarationsComponent,
	bookingDetailsComponent,
	searchPsvComponent,
	newDirectBookingModal,
	directBookingModificationModal,
	workerProfileComponent,
	sodexoExportsComponent,
	settingsComponent,
	economicSuspensionsComponent,
	dashboardComponent,
	workerWeekComponent,
	sodexoConflictsComponent,
	sodexoPsvConflictsComponent,
	sodexoInsufficientComponent,
	sodexoValidateComponent,
	sodexoOpposedComponent,
	bookingFiltersComponent,
	pdfEditorComponent,
	settingsTemplatesComponent,
	workerTemplatesComponent,
	workshopComponent,
	workshopHourModalComponent,
	workerWorkshopOrdersComponent,
	customerWorkshopOrdersComponent,
	workshopOrdersFiltersComponent,
	availableOrderItemCreatorComponent,
	newWorkshopOrderComponent,
	workshopOrdersComponent,
	workshopCommandCenterComponent,
	workshopOrdersIssuesComponent,
	workshopDayOrderComponent,
	settingsDimona,
	workshopOrdersToRetrieveComponent,
	customerTemplatesComponent,
	pdfWorkerScheduleComponent,
	documentsComponent,
	dimonasFiltersComponent,
	newDimonaModalComponent,
	notificationCenterComponent,
	salariesIndexingFormComponent,
	PSVDeliveryComponent,
	newPaperDeliveryComponent,
	workshopOrderDetailsComponent,
	updateWorkshopOrderModalComponent,
	finishWorkshopOrderModalComponent,
	PSVScannerComponent,
	workshopSettingsComponent,
	unemploymentBooksComponent,
	tasksComponent,
	draftTaskEditorComponent,
	dimonasComponent,
	customerAbsenceModalComponent,
	customerDetailsComponent,
	sodexoConflictModalComponent,
	missingLaundryOrderModalComponent,
	newTimesheetEventModalComponent,
	timesheetExportModalComponent,
	customersPaymentRemindersComponent,
	paymentReminderPageComponent,
	newEconomicSuspensionsModalComponent,
	workerMultiSelectComponent,
	bookingWishesComponent,
	searchSodexoWorkComponent,
})
