import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import moment from "moment"

import * as actions from "../actions"

import { PageLayout, PootsyButton } from "./FunctionalDesign"
import { NavLink } from "./NavLink"
import arrowBack from "../static/images/arrowback-icon.png"
import GroupTrainings from "./GroupTrainings"
import SingularTrainings from "./SingularTrainings"
import { TrainingModal } from "./TrainingModal"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

class TrainingsPage extends Component {
	componentDidMount = () => {
		this.initTrainingsPage()
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let {
			trainingsPage: { workerIndex },
		} = this.props
		if (newProps.submitTrainingSuccess) {
			this.props.dispatch(actions.acknowledgeRequestSuccess(actions.CREATETRAINING_SUCCESS))
			this.handleChanges({ workerIndex: workerIndex + 1 })
			this.closeNewTrainingModal()

			this.submitTraining()
		}
	}
	initTrainingsPage = () => {
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/languages",
				init,
				"FETCHAVAILABLEWORKERLANGUAGES"
			)
		)
	}
	backInHistory = () => {
		this.props.history.goBack()
	}
	handleChanges = changes => {
		this.props.dispatch(actions.trainingsPageStateChange(changes))
	}
	openNewTrainingModal = () => {
		const categories = this.props.currAffiliate.secSocEvents.categories
		const training_category = categories.filter(category =>
			category.id.endsWith("_training")
		)[0]
		this.handleChanges({
			showNewTrainingModal: true,
			areButtonsCollapsed: false,
			selectedWorkers: [],
			trainingStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
			trainingEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
			activityCategory: training_category.id,
			trainingType: "",
		})
	}

	closeNewTrainingModal = () => {
		this.handleChanges({
			showNewTrainingModal: false,
			areButtonsCollapsed: false,
		})
	}
	submitTraining = () => {
		this.props.dispatch(actions.submitTraining())
	}

	render() {
		let { t } = this.context

		let {
			match,
			trainingsPage: {
				showNewTrainingModal,
				activityCode,
				activityCategory,
				trainingStart,
				trainingEnd,
				trainingNote,
				selectedWorkers,
			},
		} = this.props

		let pageHeaderLeft = (
			<div className="cleaner-details-page-header-left">
				<img
					alt="arrow back"
					className="arrow-back"
					src={arrowBack}
					onClick={this.backInHistory}
				/>
				<h1 className="page-title">{t("trainings")}</h1>
			</div>
		)
		let pageHeaderRight = (
			<div className="cleaner-details-header-buttons">
				<div className="cleaner-details-header-buttons">
					<PootsyButton
						text={t("add_new_training")}
						onClick={this.openNewTrainingModal}
					/>
				</div>
			</div>
		)
		let links = [
			{ order: 1, label: t("group_trainings"), link: "group_trainings" },
			{ order: 2, label: t("singular_trainings"), link: "singular_trainings" },
		]
		let subNavLinks = links
			.sort((a, b) => a.order - b.order)
			.map(entry => (
				<NavLink
					key={entry.link}
					className="cleaner-details-subnav-link"
					to={match.url + "/" + entry.link}
				>
					{entry.label}
				</NavLink>
			))

		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				headerRight={pageHeaderRight}
				subNavLinks={subNavLinks}
			>
				{this.props.impactedBookingsModal.showImpactedBookingsModal && (
					<ImpactedBookingsModal
						onSubmit={() => this.props.dispatch(this.submitTraining)}
						triggerActionExpected={actions.CREATETRAINING_FAILURE}
					/>
				)}
				{showNewTrainingModal && (
					<TrainingModal
						activityCode={activityCode}
						activityCategory={activityCategory}
						trainingStart={trainingStart}
						trainingEnd={trainingEnd}
						trainingNote={trainingNote}
						selectedWorkers={selectedWorkers}
						toggleModal={this.closeNewTrainingModal}
						onChange={this.handleChanges}
						submit={this.submitTraining}
						t={t}
					/>
				)}
				<Route path={match.path + "/group_trainings"} component={GroupTrainings} />
				<Route path={match.path + "/singular_trainings"} component={SingularTrainings} />
			</PageLayout>
		)
	}
}

TrainingsPage.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	submitTrainingSuccess: state.requestsSuccess[actions.CREATETRAINING_SUCCESS],
	currAffiliate: state.redData.currentAffiliate,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
	trainingsPage: state.redComponents.trainingsPage,
})
export default connect(mapStateToProps)(TrainingsPage)
